import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ContactUsForm from './ContactUsForm';
import SimpleGuide from './SimpleGuide';
import HelpMenu from './HelpMenu';
import NotFound from '../NotFound.jsx';
import { useSelector } from 'react-redux';

/**
 *  This Component provides the routing logic for the "Help" section of the customer portal.
 *  @module Help
 *  @see {@link module:SimpleGuide SimpleGuide}, </br> {@link module:ContactUsForm ContactUsForm}
 *
 */

function Help(props) {
  const company = useSelector((state) => state.companyInfo);

  let url;
  switch(company.company) {
    case 'WiLine':
      url = 'https://my.wiline.com/help/Customer_Portal_Help.htm';
      break;
    case 'Stephouse':
      url = 'https://my.stephouse.net/help/Customer_Portal_Help.htm'
      break;
    default:
      url = '';
  }

  return (
    <>
      {props.location.pathname === '/business/help' && <Redirect to='/business/help/quick-guide' />}
      {props.location.pathname === '/business/help/' && <Redirect to='/business/help/quick-guide' />}
      <Switch>
        <Route exact path="/business/help" component={HelpMenu}/>
        <Route path="/business/help/quick-guide" component={SimpleGuide}/>
        <Route path='/business/help/portal-manual' component={() => {
          window.location.replace(url);
            return null;
          }}/>
        <Route path="/business/help/contact-us" component={ContactUsForm}/>
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default Help;

