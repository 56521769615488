import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import Loader from "../Loader.jsx";
import { FadeIn } from "../animated.js";
import jwt from 'jsonwebtoken';
// Sass Styles
import style from "../styles/AccountSummary.module.scss";

/**
 *  This Component renders an overview of the users billing account details, as well as a button that links the user to a form where they can change their account details.  Upon initially rendering, it displays a loading spinner until data is returned from the API. It provides the user with the following details: </br></br> Billing account number, </br>Name of billing contact</br>Billing address</br>Billing contact email
 *  @module AccountDetails
 *  @param {Object} props - loading: boolean </br> data: Object </br> refresh: function
 */

function AccountDetails(props) {
  const { detailsData } = props.data ? props.data : { detailsData: "" };
  const token = localStorage.getItem('token');
  const decoded = jwt.decode(token);
  const readOnly = !!(decoded.user.readOnly);

  const FadeAccNo = FadeIn("div");
  const FadeName = FadeIn("div");
  const FadeAddr = FadeIn("div");
  const FadeEmail = FadeIn("div");
  const FadeButton = FadeIn("div");

  return (
    <div className={style.details}>
      {props.loading ? (
        <Loader height="150px" />
      ) : (
        <>
          <FadeAccNo className={style.detailContainer}>
            <div className={style.title}>Billing Number:</div>
            <div className={style.info}>
              {detailsData && detailsData.billingaccountno}
            </div>
          </FadeAccNo>
          <FadeName delay="80ms" className={style.detailContainer}>
            <div className={style.title}>Business Name:</div>
            <div className={style.info}>{detailsData.name}</div>
          </FadeName>
          <FadeName delay="80ms" className={style.detailContainer}>
            <div className={style.title}>Business Contact:</div>
            <div
              className={style.info}
            >{`${detailsData.firstname} ${detailsData.lastname}`}</div>
          </FadeName>
          <FadeAddr delay="160ms" className={style.detailContainer}>
            <div className={style.title}>Billing Address:</div>
            <div className={style.info}>
              {detailsData.address && detailsData.address}
            </div>
          </FadeAddr>
          <FadeEmail delay="240ms" className={style.detailContainer}>
            <div className={style.title}>Account Email:</div>
            <div className={style.info}>{detailsData.addressbook_email}</div>
          </FadeEmail>
          <FadeButton delay="320ms" className={style.buttonAlign}>
            <Button
              variant="contained"
              color="primary"
              href="/#/business/my-account/change-account-details/billing-address"
              disabled={readOnly}
            >
              Change Details
            </Button>
          </FadeButton>
        </>
      )}
    </div>
  );
}

AccountDetails.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};

export default AccountDetails;
