import * as notificationInfoType from "./notificationInfoType";

const START_STATE = {
  unreadCount: 0,
};

const notificationInfoReducer = (state = START_STATE, action) => {
  switch (action.type) {
    case notificationInfoType.NOTIFICATION_INFO_SET_COUNT_INFO:
      return {
        ...state,
        unreadCount: action.payload,
      };
    case notificationInfoType.NOTIFICATION_INFO_LOWER_COUNT_INFO:
      return {
        ...state,
        unreadCount: state.unreadCount - 1,
      };
    default:
      return state;
  }
};

export default notificationInfoReducer;
