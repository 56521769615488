import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Grid,
  Box,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCustomerInfoProperty } from "../../Redux/CustomerInfo/customerInfoActions";
import { catchAxios } from "../utils/utils";
import "./ContactInformation.scss";
import { axiosWrapper } from "../../utils/axiosWrapper";

function ContactInformation({ showError }) {
  const params = useParams();
  const haveStep = "step" in params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const accountInfo = useSelector((state) => state.accountInfo);
  const contactInformation = useSelector(
    (state) => state.customerInfo.contactInformation
  );

  const changeRouter = () => {
    if (!haveStep) {
      const newParams = new URLSearchParams(location.search);
      newParams.set("step", "Contact information");
      history.push({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    }
  };

  const isMounted = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setCustomerInfoProperty(name, value));
  };

  useEffect(() => {
    changeRouter();

    if (
      !isLoaded &&
      !contactInformation.firstName &&
      !contactInformation.lastName &&
      !contactInformation.email &&
      !contactInformation.customerAddress
    ) {
      const authToken = localStorage.getItem("token");
      const accountNo = accountInfo.activeBillingAccount;

      isMounted.current = true;
      setIsLoading(true);

      if (authToken) {
        const hostname = window.location.hostname;
        axiosWrapper(
          hostname,
          "POST",
          "/profile/details",
          {
            Authorization: `Bearer ${authToken}`,
          },
          { accountNo }
        )
          .then((res) => {
            if (isMounted.current) {
              const data = res.data.data;
              if (res.data.status) {
                dispatch(
                  setCustomerInfoProperty(
                    "firstName",
                    data.detailsData.firstname
                  )
                );
                dispatch(
                  setCustomerInfoProperty("lastName", data.detailsData.lastname)
                );
                dispatch(
                  setCustomerInfoProperty(
                    "email",
                    data.detailsData.addressbook_email
                  )
                );
                dispatch(
                  setCustomerInfoProperty(
                    "customerAddress",
                    data.detailsData.address
                  )
                );
                setIsLoaded(true);
              }
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            catchAxios(err);
          });
      }
    } else {
      setIsLoaded(true);
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountInfo.activeBillingAccount,
    dispatch,
    haveStep,
    location,
    history,
    isLoaded,
    contactInformation,
  ]);

  const hasError = (contactInformation) => {
    const { firstName, lastName, email, customerAddress } = contactInformation;
    return !firstName || !lastName || !email || !customerAddress;
  };

  const getHelperText = (field, value, showError) => {
    if (showError && !value) {
      return `${field} is required`;
    }
    return "";
  };

  return (
    <Container>
      {isLoading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {showError && hasError(contactInformation) && (
            <Typography
              variant="body2"
              className="error-text"
              color="error"
              align="center"
            >
              Please fill in all required fields!
            </Typography>
          )}
          <Grid container justifyContent="center" className="py-4">
            <Grid item md={6}>
              <Box
                borderRadius={4}
                p={4}
                bgcolor="background.paper"
                boxShadow={3}
              >
                <form autoComplete="off">
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      value={contactInformation.firstName}
                      onChange={handleChange}
                      variant="outlined"
                      error={showError && !contactInformation.firstName}
                      helperText={getHelperText(
                        "First",
                        contactInformation,
                        showError
                      )}
                    />
                  </Box>
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      value={contactInformation.lastName}
                      onChange={handleChange}
                      variant="outlined"
                      error={showError && !contactInformation.lastName}
                      helperText={getHelperText(
                        "Last name",
                        contactInformation,
                        showError
                      )}
                    />
                  </Box>
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      type="email"
                      value={contactInformation.email}
                      onChange={handleChange}
                      variant="outlined"
                      error={showError && !contactInformation.email}
                      helperText={getHelperText(
                        "Email",
                        contactInformation,
                        showError
                      )}
                    />
                  </Box>
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      id="customerAddress"
                      label="Address"
                      name="customerAddress"
                      value={contactInformation.customerAddress}
                      onChange={handleChange}
                      variant="outlined"
                      error={showError && !contactInformation.customerAddress}
                      helperText={getHelperText(
                        "Address",
                        contactInformation,
                        showError
                      )}
                    />
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
}

export default ContactInformation;
