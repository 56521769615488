import React from "react";
import { Grid } from "@material-ui/core";
import { FadeIn } from "../animated.js";
import InvoiceTable from "../EndlessTable.js";
import BarChart from "./BarChart.jsx";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import FancyDoughnut from "./FancyDoughnut/Doughnut.js";

const AnimatedGrid = FadeIn(Grid);

// proptypes
// data = an array of data from state
// title = string, title of the svg
// colors = array of colors
// getDetails = cb function
// headers = array of tabe headers
// rows = array of table values per row
// cellWidth = int
// hasMore = bool
// next = callback function
// loading = bool
// showTable = bool

const DataSet = (props) => {
  const inlineStyle = {
    gridItem: {
      maxWidth: "300px",
    },
  };

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <>
      <AnimatedGrid item md={6} sm={12} style={inlineStyle.gridItem}>
        {props.activeChart === "dn" && (
          <FancyDoughnut
            data={props.data}
            title={props.title}
            colors={props.colors}
            getDetails={props.getDetails}
          />
        )}
        {props.activeChart === "bar" && (
          <BarChart
            data={props.data}
            title={props.title}
            colors={props.colors}
            getDetails={props.getDetails}
          />
        )}
      </AnimatedGrid>
      {props.spacer && (
        <Grid item md={8} style={{ display: "hidden", margin: "auto" }}></Grid>
      )}
      {props.showTable && (
        <Dialog
          open={props.modalOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle style={{ textAlign: "center" }}>
            {props.title}
          </DialogTitle>
          <DialogContent
            style={{ padding: 0, width: "1000px", maxWidth: "90vw" }}
          >
            <InvoiceTable
              headers={props.headers}
              rows={props.rows}
              minCellWidth={props.cellWidth}
              tableHeight="300px"
              hasMore={props.hasMore}
              next={props.next}
              loading={props.loading}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DataSet;
