import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import "../styles/alertDismissible.css";

const AlertDismissible = React.forwardRef(
  ({ children, show, variant, onClose, duration, persistOnHover }, ref) => {
    const [timer, setTimer] = useState(null);
    const [start, setStart] = useState(null);
    const [remaining, setRemaining] = useState(duration);

    useEffect(() => {
      if (duration) {
        resumeTimer();
      }

      // clean up
      return () => {
        timer && clearTimeout(timer);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hide = () => {
      onClose && onClose();
    };

    const resumeTimer = () => {
      timer && clearTimeout(timer);

      setStart(new Date());
      const tempTimer = setTimeout(hide, remaining);
      setTimer(tempTimer);
    };

    const pauseTimer = () => {
      if (persistOnHover) {
        timer && clearTimeout(timer);

        const newRemaining = remaining - new Date() - start;
        setRemaining(newRemaining);
      }
    };

    return (
      <div className="alert-dismissible-container">
        <Alert
          show={show}
          variant={variant}
          dismissible
          onClose={onClose}
          className="mx-auto"
          onMouseEnter={pauseTimer}
          onMouseLeave={resumeTimer}
        >
          {children}
        </Alert>
      </div>
    );
  }
);

export default AlertDismissible;
