import React, { useEffect, useMemo, useState } from "react";
import { getPayMethods } from "../utils/paymentUtils.js";
import delayUnmounting from "../DelayUnmounting.js";
import { catchAxios } from "../utils/utils.js";
import { withRouter } from "react-router-dom";
// Form stages components
import PaymentDetails from "./PaymentDetails";
import PaymentReview from "./PaymentReview";
import PaymentSuccess from "./PaymentSuccess";
import AccountSummary from "../myAccount/AccountSummary";
import LoaderPage from "../LoaderPage";
import { axiosWrapper } from "../../utils/axiosWrapper.js";
import { openGlobalModal } from "../../Redux/Global/globalActions.js";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import { setBalance } from "../../Redux/AccountInfo/accountInfoActions.js";

const DelayedDetails = delayUnmounting(PaymentDetails);
const DelayedReview = delayUnmounting(PaymentReview);

/**
 * This is the parent component that for {@link module:PaymentDetails PaymentDetails}, {@link module:PaymentReview PaymentReview} and {@link module:PaymentSuccess PaymentSuccess}.  It handles all of the logic provided by the forms contained within PaymentDetails and PaymentReview. </br>
 * After a payment is completed, it will redirect the user to the {@link module:AccountSummary AccountSummary} page.
 * @module MakeAPayment
 *
 * @param {function} nextStep - Takes a string as an argument.  If the the string is 'confirm', it increments step; otherwise it calls prevStep()
 * @param {function} prevStep - Decrements the step
 * @param {function} paymentValues - Takes an object (values) as an argument and supplies their values to state.
 * @param {function} confirmPayment - Calls the server make a payment using values stored in state.
 * @see {@link module:PaymentDetails PaymentDetails}, </br> {@link module:PaymentReview PaymentReview}, </br> {@link module:PaymentSuccess PaymentSuccess}, </br>{@link module:AccountSummary AccountSummary}
 */
const MakeAPayment = () => {
  const [componentKey, setComponentKey] = useState(0);
  const [step, setStep] = useState(1);
  const [amountDue, setAmountDue] = useState("");
  const [amountPayable, setAmountPayable] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [payment, setPayment] = useState({});
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [detailsMounted, setDetailsMounted] = useState(false);
  const [reviewMounted, setReviewMounted] = useState(false);
  const [addPaymentStatus, setAddPaymentStatus] = useState("");
  const [loaderEmail, setLoaderEmail] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const dispatch = useDispatch();
  const company = useSelector((state) => state.companyInfo);
  const location = useLocation();

  const dataFunction = async () => {
    // Sets redirect props in state if they exist
    // Ony if user has been redirected from adding a payment method
    if (location && location.state && location.state.status) {
      setAddPaymentStatus(location.state.status);
    }
    setDetailsMounted(!detailsMounted);
    const token = localStorage.getItem("token");
    if (!token) return;
    // get payment methods
    setLoading(true);
    const hostname = window.location.hostname;
    await getPayMethods(hostname)
      .then((res) => {
        setPaymentMethods(res.data.data || []);
      })
      .catch((err) => {
        catchAxios(err);
      });

    // get current balance
    await axiosWrapper(hostname, "POST", "/profile/current-balance", {
      Authorization: `Bearer ${token}`,
    })
      .then((res) => {
        const data = res.data.data;
        dispatch(setBalance(data));
        setAmountDue(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        catchAxios(err);
      });
  };

  useEffect(() => {
    dataFunction();
  }, []);

  // Confirm Payment Request
  const makePayment = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const hostname = window.location.hostname;
    return axiosWrapper(
      hostname,
      "POST",
      "/payments/make-payment",
      {
        Authorization: `Bearer ${token}`,
      },
      {
        // There is no need to pass a billingAccountNo here as it can be picked from the server session.
        payId: selectedPaymentMethod.pay_account_id,
        amount: amountPayable,
      },
      true
    );
  };

  const toggleOnReset = async () => {
    setIsReset(false);
    await dataFunction();
    // Ensure the component remounts
    setTimeout(() => {
      setDetailsMounted(true);
    }, 100); // Delay slightly to trigger remount
    window.location.reload();
  };

  useEffect(() => {
    if (isReset) toggleOnReset();
  }, [isReset]);

  const reset = () => {
    setComponentKey(componentKey + 1);
    setStep(1);
    setAmountDue("");
    setAmountPayable("");
    setPaymentMethods([]);
    setSelectedPaymentMethod("");
    setPayment({});
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    setDetailsMounted(false);
    setReviewMounted(false);
    setAddPaymentStatus("");
    setLoaderEmail(false);
    setIsReset(true);
  };

  const reportErrorByEmail = async () => {
    setLoaderEmail(true);
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const hostname = window.location.hostname;
      await axiosWrapper(hostname, "POST", "/payments/report-payment-error", {
        Authorization: `Bearer ${authToken}`,
      })
        .then((data) => {
          if (data.data.msg) {
            dispatch(openGlobalModal({ title: data.data.msg, content: "" }));
          }
          setLoaderEmail(false);
        })
        .catch((err) => {
          setLoaderEmail(false);
          catchAxios(err);
        });
    }
  };

  // Next form screen
  const nextStep = (type) => {
    setDetailsMounted(false);
    setTimeout(() => {
      if (type === "confirm") {
        // Clear previous messages
        setSuccessMessage("");
        setErrorMessage("");
        makePayment()
          .then((res) => {
            setLoading(false);
            setStep(step + 1);
            setPayment(res.data.payment);
            if (res.data.status) setSuccessMessage(res.data.msg);
            else setErrorMessage(res.data.msg);
          })
          .catch((err) => {
            setLoading(false);
            catchAxios(err);
          });
      } else {
        setStep(step + 1);
        setReviewMounted(true);
      }
    }, 399); // set timer on function to same duration as transition animation - 1. This prevents trying to perform a state update on an unmounted component
  };

  // Previous form Screen
  const prevStep = () => {
    setReviewMounted(false);
    setTimeout(() => {
      setStep(step - 1);
      setDetailsMounted(true);
    }, 399); // set timer on function to same duration as transition animation - 1.  This prevents trying to perform a state update on an unmounted component
  };

  // Get values from form on submit
  const paymentValues = (values) => {
    setAmountPayable(values.amountPayable);
    setSelectedPaymentMethod(values.selectedPaymentMethod);
    nextStep();
  };

  const balanceData = useMemo(
    () => ({
      amountDue,
      amountPayable,
      paymentMethods,
      selectedPaymentMethod,
    }),
    [amountDue, amountPayable, paymentMethods, selectedPaymentMethod]
  );

  switch (step) {
    case 1:
      return (
        <>
          <LoaderPage />
          <DelayedDetails
            key={componentKey}
            delayTime={401}
            ismounted={detailsMounted}
            nextStep={nextStep}
            submitPayment={paymentValues}
            data={balanceData}
            loading={loading}
            addPaymentStatus={addPaymentStatus} // From router state, if customer added payment method
          />
        </>
      );
    case 2:
      return (
        <DelayedReview
          delayTime={401}
          ismounted={reviewMounted}
          prevStep={prevStep}
          nextStep={nextStep}
          confirmPayment={makePayment}
          values={balanceData}
          error={errorMessage}
          loading={loading}
          addPaymentStatus={addPaymentStatus} // From router state, if customer added payment method
        />
      );
    case 3:
      return (
        <PaymentSuccess
          loaderEmail={loaderEmail}
          errorMessage={errorMessage}
          successMessage={successMessage}
          payment={payment}
          reset={reset}
          reportErrorByEmail={reportErrorByEmail}
          billingEmail={company.billingEmail}
        />
      );
    default:
      return <AccountSummary />;
  }
};

export default withRouter(MakeAPayment);
