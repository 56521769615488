import React, { useEffect, useState, useMemo } from "react";
import OrderFunction from "../../Functions/Order";
import AlertDismissible from "../utils/alert";
// import CompleteForm from "./CompleteForm";
import { FaSpinner } from "react-icons/fa";
import Cards from "react-credit-cards";
import ReactGlobe from "react-globe";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "react-credit-cards/es/styles-compiled.css";
import { Grid } from "@material-ui/core";

// SASS Styles
import "./../styles/OrderNow.css";
import "./../styles/self-service.css";
// import style from '../styles/OrderNow.module.scss';

import markerRender from "./MarkerRender";
import OrderSuccessModal from "./OrderSuccessModal";

function OrderStatus({
  orderLoading,
  submitted,
  responseStatus,
  responseMessage,
}) {
  if (orderLoading) {
    return <FaSpinner className="order-now-loader" />;
  }
  if (submitted && responseMessage.length > 0) {
    return (
      <span className={responseStatus ? "text-success" : "text-danger"}>
        {responseMessage}
      </span>
    );
  }
  return "Place Order";
}

function OrderNow(props) {
  const { address } = props.match.params;
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const [showCompleteForm, setShowCompleteForm] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    phone_number: "",
    package_name: "",
    package_price: "",
    service_address: address ? address.replaceAll("%2C", ", ") : "",
    payment_method: "ach", // You can set a default value if needed
    card_name: "",
    card_number: "",
    exp_date: "",
    cvc: "",
    // bank_name: '',
    // routing_number: '',
    // account_number: '',
    // account_type: 'checking', // You can set a default value if needed
    // complete_data: false,
  });

  const [focus, setFocus] = useState("");

  const initial = useMemo(() => {
    return [42.6526, -73.7562];
  }, []); // add dependencies to useMemo where appropriate

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  //Popup Modal
  const [showModal, setShowModal] = useState(false);

  //email and phone validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex =
    /^\+?(\d{1,4})?[ .-]?\(?(\d{1,})\)?[ .-]?(\d{1,})[ .-]?(\d{1,})[ .-]?(\d{1,})$/;

  //Globe Options
  const options = {
    markerRender,
    enableCameraAutoRotate: false,
    focusDistanceRadiusScale: 3,
    globeGlowPower: 4,
  };

  const markers = [
    {
      id: 1,
      city: "Sacramento",
      color: "blue",
      coordinates: [38.5758, -121.4788],
      value: 50,
    },
    {
      id: 2,
      city: "Austin",
      color: "blue",
      coordinates: [30.25, -97.75],
      value: 50,
    },
    {
      id: 3,
      city: "Albany",
      color: "blue",
      coordinates: [42.6526, -73.7562],
      value: 50,
    },
    {
      id: 4,
      city: "Tallahassee",
      color: "blue",
      coordinates: [30.4383, -84.2807],
      value: 50,
    },
    {
      id: 5,
      city: "Springfield",
      color: "blue",
      coordinates: [39.7837, -89.6501],
      value: 50,
    },
    {
      id: 6,
      city: "Phoenix",
      color: "blue",
      coordinates: [33.4484, -112.074],
      value: 50,
    },
    {
      id: 7,
      city: "Denver",
      color: "blue",
      coordinates: [39.7392, -104.9903],
      value: 50,
    },
    {
      id: 8,
      city: "Atlanta",
      color: "blue",
      coordinates: [33.749, -84.388],
      value: 50,
    },
    {
      id: 9,
      city: "Honolulu",
      color: "blue",
      coordinates: [21.3069, -157.8583],
      value: 50,
    },
    {
      id: 10,
      city: "Montgomery",
      color: "blue",
      coordinates: [32.3792, -86.3077],
      value: 50,
    },
    {
      id: 11,
      city: "Indianapolis",
      color: "blue",
      coordinates: [39.7684, -86.1581],
      value: 50,
    },
    {
      id: 12,
      city: "Boise",
      color: "blue",
      coordinates: [43.615, -116.2023],
      value: 50,
    },
    {
      id: 13,
      city: "Des Moines",
      color: "blue",
      coordinates: [41.591, -93.6037],
      value: 50,
    },
    {
      id: 14,
      city: "Topeka",
      color: "blue",
      coordinates: [39.0482, -95.6777],
      value: 50,
    },
    {
      id: 15,
      city: "Annapolis",
      color: "blue",
      coordinates: [38.9784, -76.4922],
      value: 50,
    },
    {
      id: 16,
      city: "Boston",
      color: "blue",
      coordinates: [42.3601, -71.0589],
      value: 50,
    },
    {
      id: 17,
      city: "Lansing",
      color: "blue",
      coordinates: [42.7325, -84.5555],
      value: 50,
    },
    {
      id: 18,
      city: "Saint Paul",
      color: "blue",
      coordinates: [44.9537, -93.09],
      value: 50,
    },
    {
      id: 19,
      city: "Jackson",
      color: "blue",
      coordinates: [32.2988, -90.1848],
      value: 50,
    },
    {
      id: 20,
      city: "Helena",
      color: "blue",
      coordinates: [46.5891, -112.0391],
      value: 50,
    },
    {
      id: 21,
      city: "Raleigh",
      color: "blue",
      coordinates: [35.7796, -78.6382],
      value: 50,
    },
    {
      id: 22,
      city: "Bismarck",
      color: "blue",
      coordinates: [46.8083, -100.7837],
      value: 50,
    },
    {
      id: 23,
      city: "Columbus",
      color: "blue",
      coordinates: [39.9612, -82.9988],
      value: 50,
    },
    {
      id: 24,
      city: "Oklahoma City",
      color: "blue",
      coordinates: [35.4676, -97.5164],
      value: 50,
    },
    {
      id: 25,
      city: "Salem",
      color: "blue",
      coordinates: [44.9429, -123.0351],
      value: 50,
    },
    {
      id: 26,
      city: "Harrisburg",
      color: "blue",
      coordinates: [40.2732, -76.8867],
      value: 50,
    },
    {
      id: 27,
      city: "Providence",
      color: "blue",
      coordinates: [41.8231, -71.4188],
      value: 50,
    },
    {
      id: 28,
      city: "Columbia",
      color: "blue",
      coordinates: [34.0007, -81.0348],
      value: 50,
    },
    {
      id: 29,
      city: "Pierre",
      color: "blue",
      coordinates: [44.3683, -100.351],
      value: 50,
    },
    {
      id: 30,
      city: "Nashville",
      color: "blue",
      coordinates: [36.1627, -86.7816],
      value: 50,
    },
    {
      id: 31,
      city: "Austin",
      color: "blue",
      coordinates: [30.25, -97.75],
      value: 50,
    },
    {
      id: 32,
      city: "Salt Lake City",
      color: "blue",
      coordinates: [40.7608, -111.891],
      value: 50,
    },
    {
      id: 33,
      city: "Montpelier",
      color: "blue",
      coordinates: [44.2601, -72.5754],
      value: 50,
    },
    {
      id: 34,
      city: "Richmond",
      color: "blue",
      coordinates: [37.5407, -77.436],
      value: 50,
    },
    {
      id: 35,
      city: "Olympia",
      color: "blue",
      coordinates: [47.0379, -122.9007],
      value: 50,
    },
    {
      id: 36,
      city: "Charleston",
      color: "blue",
      coordinates: [32.7846, -79.9409],
      value: 50,
    },
    {
      id: 37,
      city: "Madison",
      color: "blue",
      coordinates: [43.0731, -89.4012],
      value: 50,
    },
    {
      id: 38,
      city: "Cheyenne",
      color: "blue",
      coordinates: [41.1399, -104.8202],
      value: 50,
    },
    {
      id: 39,
      city: "Baton Rouge",
      color: "blue",
      coordinates: [30.4583, -91.1403],
      value: 50,
    },
    {
      id: 40,
      city: "Trenton",
      color: "blue",
      coordinates: [40.2206, -74.7597],
      value: 50,
    },
    {
      id: 41,
      city: "Santa Fe",
      color: "blue",
      coordinates: [35.687, -105.9378],
      value: 50,
    },
    {
      id: 42,
      city: "Albany",
      color: "blue",
      coordinates: [42.6526, -73.7562],
      value: 50,
    },
    {
      id: 43,
      city: "Dover",
      color: "blue",
      coordinates: [39.1582, -75.5244],
      value: 50,
    },
    {
      id: 44,
      city: "Juneau",
      color: "blue",
      coordinates: [58.3019, -134.4197],
      value: 50,
    },
    {
      id: 45,
      city: "Annapolis",
      color: "blue",
      coordinates: [38.9784, -76.4922],
      value: 50,
    },
  ];

  const handleCLoseModal = () => {
    setShowModal(false);
  };

  const handleAlert = (alertType, alertMessage) => {
    setAlertVariant(alertType);
    setAlertMessage(alertMessage);
    setShowAlert(true);
  };

  const handlePackageChange = (name, price) => {
    setFormData({ ...formData, package_name: name, package_price: price });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setFormData({ ...formData, email: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    setFormData({ ...formData, phone_number: event.target.value });
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "card_number" || name === "cvc") {
      const numericValue = value.replace(/\D/g, "");
      if (name === "cvc") {
        value = numericValue.slice(0, 4);
        setFormData({ ...formData, [name]: value });
      } else if (name === "card_number") {
        value = numericValue.slice(0, 16);
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === "exp_date") {
      value = value.replace(/\D/g, "");

      // Format the input as MM/YY
      if (value.length > 2) {
        value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
      }
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // const handleShowCompleted = (submit) => {
  //   if (submit) {
  //     setShowCompleteForm((prevShowCompleteForm) => !prevShowCompleteForm);
  //   }
  // };

  useEffect(() => {
    if (email.length === 0) {
      setIsValidEmail(false);
    } else {
      setIsValidEmail(emailRegex.test(email));
    }
  }, [email, emailRegex]);

  useEffect(() => {
    if (phone.length === 0) {
      setIsValidPhone(false);
    } else {
      setIsValidPhone(phoneRegex.test(phone));
    }
  }, [phone, phoneRegex]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      placeOrder(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    placeOrder(true);
  };

  const isContactInfoValid = (email, isValidEmail, phone, isValidPhone) => {
    return (
      (isValidEmail && email.length > 0) || (isValidPhone && phone.length > 0)
    );
  };

  const placeOrder = (submit) => {
    if (isContactInfoValid(email, isValidEmail, phone, isValidPhone)) {
      if (submit) {
        setOrderLoading(true);
        formData.submitted = true;
      }
      OrderFunction.placeOrderSS(formData)
        .then((response) => {
          if (response?.status) {
            if (response?.data?.message) {
              setResponseStatus(true);
              setResponseMessage(response?.data?.message);
              if (response?.data?.message && submit) {
                setShowModal(true);
              }
            }
          } else {
            handleAlert("warning", "Error Placing Order");
            setResponseMessage("Error Placing Order");
          }
        })
        .catch((error) => {
          handleAlert("danger", error.toString());
          setResponseMessage("Error Placing Order");
        })
        .finally(() => {
          if (submit) {
            setOrderLoading(false);
          }
        });
    }
  };

  return (
    <>
      <div
        // className={style.particlesjs}
        id="particles-js"
      >
        <>
          <div>
            <nav className="order-now-nav flex-row bg-none">
              <div className="flex-row left"></div>

              <div className="flex-row middle">
                <span className="m-a" style={{ padding: "0.5rem 3rem" }}>
                  <a
                    href="https://wiline.com/order-now"
                    style={{ cursor: "pointer" }}
                  >
                    New Order
                  </a>
                </span>
                <a className="nav-logo" href="/">
                  {/* <img src={topImage} alt='logo for nav bar'/> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Layer_1"
                    x="0"
                    y="0"
                    width="172.3"
                    height="68.7"
                    viewBox="0 0 172.3 68.7"
                    enableBackground="new 0 0 172.3 68.7"
                    fill="white"
                  >
                    <path
                      className="st0"
                      d="M33.9 51.6l-7.3-26.7H34c0 0 3.7 16.2 4.2 19.3h0.1c0.5-3.4 4.6-19.3 4.6-19.3h7.4c0 0 3.7 15.7 4.1 19.2h0.1c0.5-3.4 4.3-19.2 4.3-19.2h7.1l-8 26.7h-7.5c0 0-3.3-16-4.1-19.5h-0.1c-0.8 3.5-4.7 19.5-4.7 19.5H33.9"
                    />
                    <rect
                      x="66.2"
                      y="29.2"
                      className="st0"
                      width="6.9"
                      height="22.3"
                    />
                    <polygon
                      className="st0"
                      points="76.1 24.9 83.1 24.9 83.1 45.7 95.3 45.7 95.3 51.6 76.1 51.6 "
                    />
                    <rect
                      x="96.3"
                      y="29.2"
                      className="st0"
                      width="6.9"
                      height="22.3"
                    />
                    <path
                      className="st0"
                      d="M106.1 36.4c0-2.8-0.1-5.1-0.2-7.1h6l0.3 3.1h0.1c0.9-1.5 3.2-3.6 6.9-3.6 4.6 0 8 3.1 8 9.6v13.2h-6.9V39.2c0-2.9-1-4.8-3.5-4.8 -1.9 0-3.1 1.3-3.6 2.6 -0.2 0.5-0.2 1.1-0.2 1.7v12.9h-7V36.4"
                    />
                    <path
                      className="st0"
                      d="M134.8 42.6c0.2 2.9 3.1 4.3 6.3 4.3 2.4 0 4.3-0.3 6.2-1l0.9 4.7c-2.3 1-5.1 1.4-8.1 1.4 -7.6 0-11.9-4.4-11.9-11.4 0-5.7 3.6-11.9 11.3-11.9 7.2 0 10 5.6 10 11.2 0 1.2-0.1 2.2-0.2 2.8L134.8 42.6M142.9 37.8c0-1.6-0.7-4.5-3.9-4.5 -3 0-4.2 2.7-4.3 4.5H142.9z"
                    />
                    <path
                      className="st0"
                      d="M9.8 32.3C12.1 16.2 48.6 8 91.4 14c13 1.8 25.1 4.8 35.6 8.4 -12-4.8-26.6-8.7-42.7-10.9C40.8 5.4 3.7 13.6 1.4 29.8c-1.8 13 19.3 27.2 50.2 35.5C25.4 56.9 8.2 44.1 9.8 32.3"
                    />
                    <path
                      className="st0"
                      d="M125.5 65.6c22.1-1.4 37.7-7.9 39.6-18 2.9-16.1-29.8-35.5-73-43.3 -8.6-1.5-16.9-2.5-24.8-2.9 10 0.1 20.9 1.1 32.2 3.1 42.5 7.6 74.6 26.8 71.7 42.8C169.3 58.4 150.8 65.1 125.5 65.6"
                    />
                  </svg>
                </a>
                <span style={{ padding: "0.5rem 3rem" }}>
                  <a style={{ cursor: "pointer" }} href="/">
                    My Account
                  </a>
                </span>
              </div>
              <div className="flex-row right"></div>
            </nav>
            <form className="form-order-now" onSubmit={handleSubmit}>
              <Grid container spacing={2} className="pb-4 pr-4">
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <ReactGlobe
                    height="60vh"
                    globeBackgroundTexture=""
                    globeCloudsTexture={null}
                    markers={markers}
                    initialCameraDistanceRadiusScale={3}
                    initialCoordinates={initial}
                    options={options}
                    width="100%"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ paddingRight: 20 }}
                >
                  <h2
                    style={{
                      fontSize: "2rem",
                      marginTop: 0,
                      marginBottom: ".5rem",
                      fontWeight: 500,
                      lineHeight: 1.2,
                    }}
                    className="order-title"
                  >
                    Order WiLine
                  </h2>
                  <p className="service-location">
                    Service Location: {address?.replaceAll("%2C", ", ")}
                  </p>
                  <p>We provide world class service at the touch of a button</p>
                  <br />
                  <p className="service-location">
                    Choose your preferred package:
                  </p>
                  <div className="choose-package">
                    <div
                      className={`card-order ${
                        formData?.package_name === "50 Mbps"
                          ? "card-order-hover"
                          : ""
                      }`}
                      onClick={() => handlePackageChange("50 Mbps", 50.0)}
                    >
                      <p className="order-now-bigger-text">50/50 Mbps</p>
                      <p className="order-now-smaller-text">$50</p>
                    </div>

                    <div
                      className={`card-order ${
                        formData?.package_name === "100 Mbps"
                          ? "card-order-hover"
                          : ""
                      }`}
                      onClick={() => handlePackageChange("100 Mbps", 100.0)}
                    >
                      <p className="order-now-bigger-text">100/100 Mbps</p>
                      <p className="order-now-smaller-text">$100</p>
                    </div>

                    <div
                      className={`card-order ${
                        formData?.package_name === "150 Mbps"
                          ? "card-order-hover"
                          : ""
                      }`}
                      onClick={() => handlePackageChange("150 Mbps", 150.0)}
                    >
                      <p className="order-now-bigger-text">150/150 Mbps</p>
                      <p className="order-now-smaller-text">$150</p>
                    </div>
                  </div>

                  <p className="service-location">Contact Information:</p>
                  <div className="contact-info">
                    <div className="input-group">
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="first name"
                        required
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="last name"
                        required
                        onChange={handleInputChange}
                      />
                    </div>

                    <input
                      type="text"
                      id="company_name"
                      name="company_name"
                      placeholder="company name"
                      onChange={handleInputChange}
                    />

                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="email"
                      onChange={handleEmailChange}
                      required
                    />
                    {!isValidEmail && email.length > 0 && (
                      <span className="error-text">Invalid-Email</span>
                    )}

                    <input
                      type="tel"
                      id="phone"
                      name="phone_number"
                      placeholder="phone number"
                      onChange={handlePhoneChange}
                      required
                    />
                    {!isValidPhone && phone.length > 0 && (
                      <span className="error-text">Invalid Phone Number</span>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="pb-4 pr-4">
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ height: "inherit", margin: "auto" }}
                >
                  {formData?.payment_method === "creditCard" &&
                    dimensions.width < 950 && (
                      <div className="interactive-card">
                        <Cards
                          number={formData?.card_number}
                          name={formData?.card_name}
                          expiry={formData?.exp_date}
                          cvc={formData?.cvc}
                          focused={focus || ""}
                        />
                      </div>
                    )}
                  {formData?.payment_method === "creditCard" &&
                    dimensions.width >= 950 && (
                      <div className="interactive-card">
                        <Cards
                          number={formData?.card_number}
                          name={formData?.card_name}
                          expiry={formData?.exp_date}
                          cvc={formData?.cvc}
                          focused={focus || ""}
                        />
                      </div>
                    )}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="pr-4">
                  <p className="service-location" style={{ marginTop: "25px" }}>
                    Choose your payment method:
                  </p>
                  <div className="radio-group pb-2">
                    <label>
                      <input
                        className="radio-input p-1"
                        type="radio"
                        name="payment_method"
                        value="ach"
                        checked={formData?.payment_method === "ach"}
                        onChange={handleInputChange}
                      />
                      <span className="pl-2">Automated Clearing House</span>
                    </label>
                    <label>
                      <input
                        className="radio-input"
                        type="radio"
                        name="payment_method"
                        value="creditCard"
                        checked={formData?.payment_method === "creditCard"}
                        onChange={handleInputChange}
                      />
                      <span className="pl-2">Credit Card</span>
                    </label>
                  </div>

                  {formData?.payment_method === "creditCard" && (
                    <div className="custom-row">
                      <div id="creditCardFields" style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          id="card_name"
                          name="card_name"
                          placeholder="Card Holder Name"
                          value={formData?.card_name || ""}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          required
                        />

                        <input
                          type="text"
                          id="card_number"
                          name="card_number"
                          pattern="[0-9]{13,19}"
                          inputMode="numeric"
                          placeholder="Card Number"
                          value={formData?.card_number || ""}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          required
                        />

                        <input
                          type="text"
                          id="exp_date"
                          name="exp_date"
                          placeholder="MM/YY"
                          value={formData?.exp_date || ""}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          required
                        />

                        <input
                          type="text"
                          id="cvc"
                          name="cvc"
                          placeholder="cvc"
                          pattern="[0-9]{3,4}"
                          inputMode="numeric"
                          value={formData?.cvc || ""}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          required
                        />
                      </div>
                    </div>
                  )}
                  {formData?.payment_method === "ach" && (
                    <div id="achFields" style={{ marginTop: "20x" }}>
                      <input
                        type="text"
                        id="bank_name"
                        name="bank_name"
                        placeholder="bank name"
                        onChange={handleInputChange}
                        required
                      />

                      <input
                        type="text"
                        id="routing_number"
                        name="routing_number"
                        placeholder="routing number"
                        onChange={handleInputChange}
                        required
                      />

                      <input
                        type="text"
                        id="account_number"
                        name="account_number"
                        placeholder="account number"
                        onChange={handleInputChange}
                        required
                      />

                      <select
                        id="account_type"
                        name="account_type"
                        onChange={handleInputChange}
                      >
                        <option value="checking">Checking</option>
                        <option value="saving">Saving</option>
                      </select>
                    </div>
                  )}

                  <div className="button-wrapper" style={{ paddingBottom: 10 }}>
                    <button
                      type="submit"
                      className={
                        submitted &&
                        (orderLoading || responseMessage.length > 0)
                          ? "order-button-disabled"
                          : "order-button"
                      }
                      disabled={
                        submitted &&
                        (orderLoading || responseMessage.length > 0)
                      }
                    >
                      <OrderStatus
                        orderLoading={orderLoading}
                        submitted={submitted}
                        responseStatus={responseStatus}
                        responseMessage={responseMessage}
                      />
                    </button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
          <OrderSuccessModal open={showModal} onClose={handleCLoseModal} />
          {showAlert && (
            <AlertDismissible
              show={showAlert}
              variant={alertVariant}
              onClose={() => setShowAlert(false)}
              duration={3000}
              persistOnHover={true}
            >
              {alertMessage}
            </AlertDismissible>
          )}
        </>
      </div>
    </>
  );
}

export default OrderNow;
