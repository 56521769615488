import React, { useEffect, useState } from "react";
import { generateKey } from "./utils/utils";
import style from "./styles/Aside.module.scss";
import { Badge } from "antd";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import History from "./History.js";
import NavLogo from "./NavLogo.jsx";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import BackButton from "./BackButton.js";
import { clearServerSession, decodeToken } from "./utils/utils";
import Loading from "./Loading";

// Material-UI
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Collapse,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { siteType } from "../App";
import { useDispatch } from "react-redux";
import { resetState } from "../Redux/action.js";
import SessionManager from "./SessionManager.jsx";
import {
  FaBars,
  FaCreditCard,
  FaFileAlt,
  FaQuestionCircle,
  //   FaRegCreditCard,
  FaSignOutAlt,
  FaUserCircle,
  FaUsers,
  FaUserShield,
} from "react-icons/fa";
import { FaTicket } from "react-icons/fa6";
import { axiosWrapper } from "../utils/axiosWrapper.js";
import { setCompanyByBusinessUnit } from "../Redux/Company/companyActions.js";
import { setGlobalLoader } from "../Redux/Global/globalActions.js";
import { setNotificationCountInfo } from "../Redux/NotificationInfo/notificationInfoActions.js";
import { logoutUser, setActiveBillingAccount } from "../Redux/AccountInfo/accountInfoActions.js";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    color: theme.palette.common.white,
    background: theme.menuPaper.background,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  history: {
    display: "flex",
    justifyContent: "space-between",
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

function NestedDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const accountInfo = useSelector((state) => state.accountInfo);
  const notificationInfo = useSelector((state) => state.notificationInfo);
  const token = decodeToken(localStorage.getItem("token"));
  const multiAccount = !!(token && token.user && token.user.multiAccount); // simplifies a true / false ternary.
  const isAdmin = !!(token && token.user && token.user.isAdmin);
  const readOnly = !!(token && token.user && token.user.readOnly);

  //  HOOKS
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [sectionSelected, setSectionSelected] = React.useState(false);
  const dispatch = useDispatch();

  // handles mobile navigation toggle
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Drawer Submenu Open
  const handleClick = async () => {
    setOpen(!open);
    setMobileOpen(!mobileOpen);
  };

  // Modal operation used to confirm logout
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const logOut = async () => {
    dispatch(setGlobalLoader(true));
    dispatch(logoutUser());
    dispatch(setActiveBillingAccount('0'));
    dispatch(setCompanyByBusinessUnit(siteType()));
    await clearServerSession();
    dispatch(resetState());
    localStorage.removeItem("lastMovement");
    localStorage.clear();
    dispatch(setGlobalLoader(false));
    window.location.hash = "/login";
    toggleModal();
  };

  // Used for checking router location path against item link, for nested items to keep menu open
  const includedPath = (location) => {
    let routerLocation = props.history.location.pathname;
    return !!routerLocation.includes(location);
  };

  // Used for checking router location path against item link, for nested items
  const samePath = (location) => {
    let routerLocation = props.history.location.pathname;
    return routerLocation === location;
  };

  let navigation = []; // Only include certain navigation components

  const accountReadOnlyNavigation = [
    {
      title: "My Tickets",
      link: "/business/my-tickets",
      icon: <FaTicket />,
      submenu: [],
    },
    {
      title: "Help",
      link: "/business/help",
      icon: <FaQuestionCircle />,
      submenu: [
        { title: "Quick Guide", link: "/business/help/quick-guide" },
        { title: "Contact Us", link: "/business/help/contact-us" },
      ],
    },
  ];

  // Regular account navigation for when an account has been selected
  const accountNavigation = (hasVoice) => {
    return [
      {
        title: "My Account",
        link: "/business/my-account",
        icon: <FaUserCircle />,
        submenu: [
          {
            title: "Account Details",
            link: "/business/my-account/account-details",
          },
          {
            title: "Change Password",
            link: "/business/my-account/password-reset",
          },
          //{title: 'Change Details', link: '/business/my-account/change-account-details/billing-address'}
        ],
      },
      {
        title: "Documents",
        link: "/business/documents",
        icon: <FaFileAlt />,
        submenu: accountInfo.voice
            ? [
                {
                  title: "Call Detail Record",
                  link: "/business/documents/call-details",
                },
                {
                  title: "My Invoices",
                  link: "/business/documents/my-invoices",
                },
                {
                  title: "Payment History",
                  link: "/business/documents/payment-history",
                },
                {
                  title: "Notifications",
                  link: "/business/documents/notifications",
                },
              ]
            : [
                {
                  title: "My Invoices",
                  link: "/business/documents/my-invoices",
                },
                {
                  title: "Payment History",
                  link: "/business/documents/payment-history",
                },
                {
                  title: "Notifications",
                  link: "/business/documents/notifications",
                },
              ],
      },
      {
        title: "Payments",
        link: "/business/payments",
        icon: <FaCreditCard />,
        submenu: [
          { title: "Make a Payment", link: "/business/payments/make-payment" },
          {
            title: "Add Payment Method",
            link: "/business/payments/add-new-card",
          },
          {
            title: "Payment Methods",
            link: "/business/payments/payment-methods",
          },
          {
            title: "Payment History",
            link: "/business/payments/payment-history",
          },
        ],
      },
      //   {
      //     title: "Buy now/Upgrade",
      //     link: "/business/buy-now",
      //     icon: <FaRegCreditCard />,
      //     submenu: [
      //       { title: "Buy now", link: "/business/buy-now" },
      //       { title: "Upgrade", link: "/business/upgrade" },
      //     ],
      //   },
      {
        title: "Users",
        link: "/business/users",
        icon: <FaUsers />,
        submenu: [],
      },
    ];
  };

  // Admin navigation only
  const adminLinks = [
    {
      title: "Admin Tools",
      link: "/admin",
      icon: <FaUserShield />,
      submenu: [
        { title: "Statistics", link: "/admin/stats" },
        { title: "Live Users", link: "/admin/live-users" },
        { title: "Switch Account", link: "/business" },
      ],
    },
  ];

  const [, setUnreadNotificationsCountLoading] = useState(true);
  const [unreadNotificationsCountError, setUnreadNotificationsCountError] =
    useState(false);
  const [, setUnreadNotificationsCount] = useState([]);

  const [, setHasVoiceLoading] = useState(true);
  const [, setHasVoiceError] = useState(false);
  const [hasVoice, setHasVoice] = useState(false);

  useEffect(() => {
    const loadNotificationsCount = async () => {
      try {
        if (accountInfo.activeBillingAccount) {
          const authToken = localStorage.getItem("token");

          const hostname = window.location.hostname;

          const response = await axiosWrapper(
            hostname,
            "GET",
            `/notifications/my/unread/${accountInfo.activeBillingAccount}`,
            {
              Authorization: `Bearer ${authToken}`,
            }
          );
          const { data } = response;
          setUnreadNotificationsCount(data.data);
          dispatch(setNotificationCountInfo(data.data));
          // dispatch(setNotificationCountInfo(data.data));
        }
      } catch (err) {
        setUnreadNotificationsCountError(true);
        // catchAxios(err);
      } finally {
        setUnreadNotificationsCountLoading(false);
      }
    };

    const hostname = window.location.hostname;

    const loadData = async () => {
      try {
        const authToken = localStorage.getItem("token");
        const response = await axiosWrapper(
          hostname,
          "GET",
          "/features/available/voice",
          {
            Authorization: `Bearer ${authToken}`,
          }
        );
        const { data } = response;
        setHasVoice(data.data);
      } catch (err) {
        setHasVoiceError(true);
        // catchAxios(err);
      } finally {
        setHasVoiceLoading(false);
      }
    };
    if (!token) {
      logOut();
    }
    loadData();
    loadNotificationsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo.activeBillingAccount]);

  const accNavig = accountNavigation(hasVoice);

  if (readOnly) {
    navigation = [...navigation, ...accountReadOnlyNavigation];
  } else if (accountInfo.activeBillingAccount || !isAdmin) {
    navigation = [...navigation, ...accNavig, ...accountReadOnlyNavigation];
  }

  //if a user is an admin, append the admin menu to the end of the navigation array.
  if (isAdmin) {
    if (accountInfo.activeBillingAccount)
      navigation = [...accNavig, ...accountReadOnlyNavigation, ...adminLinks];
    else navigation = [...accountReadOnlyNavigation.slice(1), ...adminLinks];
  }

  // Only render switch account link if user has multiple accounts
  if (multiAccount) {
    // Add switch account link into navigation
    const switchLink = { title: "Switch Account", link: "/business" };
    accNavig[0].submenu.splice(2, 0, switchLink);
  }

  const drawer = (
    <div className={style.container}>
      <div className={classes.toolbar}>
        <NavLogo />
      </div>
      <List component="div">
        {navigation.map((e) => (
          <div key={e.title}>
            <Link to={e.link} onClick={handleClick}>
              <ListItem
                button
                className={includedPath(e.link) ? `${classes.active}` : ""}
                onClick={() => setSectionSelected(!sectionSelected)}
                component="div"
              >
                <ListItemText primary={e.title} />
                <div className={style.iconContainer}>{e.icon}</div>
              </ListItem>
            </Link>
            <Collapse in={includedPath(e.link)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {e.submenu.map((e) => {
                  return (
                    <Link
                      to={{
                        pathname: e.link,
                        state: { from: props.location.pathname },
                      }}
                      key={generateKey(e.title)}
                      onClick={handleClick}
                    >
                      <ListItem
                        component="div"
                        button
                        className={`${classes.nested} ${
                          samePath(e.link) && style.activeSublink
                        }`}
                      >
                        <ListItemText primary={e.title} />
                      </ListItem>
                    </Link>
                  );
                })}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
      <List component="div">
        {[{ title: "Logout", link: "/", icon: <FaSignOutAlt /> }].map((e) => (
          <div
            onClick={toggleModal}
            key={generateKey(e.title)}
            className={style.list_item}
          >
            <ListItem button component="div">
              <ListItemText primary={e.title} />
              <div className={style.iconContainer}>{e.icon}</div>
            </ListItem>
          </div>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={style.history}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <FaBars className={style.toggle} />
          </IconButton>
          <Typography variant="h4" noWrap>
            <History />
          </Typography>
          <div className={style.rightContainer}>
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                gap: "8px",
              }}
              to={{
                pathname: "/business/documents/notifications",
              }}
            >
              {!unreadNotificationsCountError && notificationInfo.unreadCount > 0 ? (
                <div className={style.count}>
                  <Tooltip title="Notifications">
                    <Badge
                      count={notificationInfo.unreadCount}
                      offset={[0, 5]}
                      size="default"
                    >
                      <MailOutlineIcon style={{ fontSize: 32 }} />
                    </Badge>
                  </Tooltip>
                </div>
              ) : null}
            </Link>
            <BackButton history={props.history} />
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="js">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="js">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <Dialog
        open={modalOpen}
        onClose={toggleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to logout?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to logout from the application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleModal}
            variant="contained"
            style={{ minWidth: "120px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={logOut}
            variant="contained"
            color="primary"
            style={{ minWidth: "120px" }}
            disabled={loading}
            autoFocus
          >
            <Loading
              loading={loading}
              loadingText="LOADING"
              submitText="LOGOUT"
            />
          </Button>
        </DialogActions>
      </Dialog>
      <SessionManager />
    </div>
  );
}

// Let drawer access router location
const DrawerWithRouter = withRouter(NestedDrawer);
export default DrawerWithRouter;
