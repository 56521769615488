import React, { useEffect, useState } from "react";
import { Container, Grid, Box, TextField, Typography } from "@material-ui/core";
import Cards from "react-credit-cards";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setBillingInfoProperty } from "../../Redux/CustomerInfo/customerInfoActions";
import "react-credit-cards/es/styles-compiled.css";
import "./BillingInformation.scss";

function BillingInformation({ showError }) {
  const params = useParams();
  const haveStep = "step" in params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { cardHolderName, cardNumber, expDate, cvc } = useSelector(
    (state) => state.customerInfo.billingInformation
  );

  const changeRouter = () => {
    if (!haveStep) {
      const newParams = new URLSearchParams(location.search);
      newParams.set("step", "Billing information");
      history.push({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    }
  };

  useEffect(() => {
    changeRouter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "cardNumber" || name === "cvc") {
      const numericValue = value.replace(/\D/g, "");
      if (name === "cvc") {
        value = numericValue.slice(0, 4);
      } else if (name === "cardNumber") {
        value = numericValue.slice(0, 16);
      }
    } else if (name === "expDate") {
      value = value.replace(/\D/g, "");

      // Format the input as MM/YY
      if (value.length > 2) {
        value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
      }
    }
    dispatch(setBillingInfoProperty(name, value));
  };

  const [focus, setFocus] = useState("");

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  return (
    <Container>
      {showError &&
        (!cardHolderName ||
          !cardNumber ||
          !expDate ||
          !cvc) && (
          <Typography
            variant="body2"
            className="error-text"
            color="error"
            align="center"
          >
            Please fill in all required fields!
          </Typography>
        )}
      <Grid container justifyContent="center" className="py-4">
        <Grid item md={4}>
          <Box className="interactive-card" mb={4}>
            <Cards
              number={cardNumber}
              name={cardHolderName}
              expiry={expDate}
              cvc={cvc}
              focused={focus}
            />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box boxShadow={3} borderRadius={4} p={4} bgcolor="background.paper">
            <form noValidate autoComplete="off">
              <Box mb={3}>
                <TextField
                  fullWidth
                  id="cardHolderName"
                  label="Card Holder Name"
                  name="cardHolderName"
                  value={cardHolderName}
                  onChange={handleChange}
                  onFocus={handleInputFocus}
                  variant="outlined"
                  error={showError && !cardHolderName}
                  helperText={
                    showError && !cardHolderName
                      ? "Card holder name is required"
                      : ""
                  }
                />
              </Box>
              <Box mb={3}>
                <TextField
                  fullWidth
                  id="cardNumber"
                  label="Card Number"
                  name="cardNumber"
                  value={cardNumber}
                  onChange={handleChange}
                  onFocus={handleInputFocus}
                  variant="outlined"
                  error={showError && !cardNumber}
                  helperText={
                    showError && !cardNumber
                      ? "Card number is required"
                      : ""
                  }
                />
              </Box>
              <Box mb={3}>
                <TextField
                  fullWidth
                  id="expDate"
                  label="Expiration Date"
                  name="expDate"
                  value={expDate}
                  onChange={handleChange}
                  onFocus={handleInputFocus}
                  placeholder="MM/YY"
                  variant="outlined"
                  error={showError && !expDate}
                  helperText={
                    showError && !expDate
                      ? "Expiration date is required"
                      : ""
                  }
                />
              </Box>
              <Box mb={3}>
                <TextField
                  fullWidth
                  id="cvc"
                  label="CVC"
                  name="cvc"
                  value={cvc}
                  onChange={handleChange}
                  onFocus={handleInputFocus}
                  placeholder="Enter CVC"
                  inputProps={{ pattern: "[0-9]{3,4}", inputMode: "numeric" }}
                  variant="outlined"
                  error={showError && !cvc}
                  helperText={
                    showError && !cvc
                      ? "CVC is required"
                      : ""
                  }
                  required
                />
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default BillingInformation;
