import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { resetState } from "../Redux/action.js";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { catchAxios, clearServerSession } from "./utils/utils.js";
import { siteType } from "../App.js";
import { axiosWrapper } from "../utils/axiosWrapper.js";
import { setCompanyByBusinessUnit } from "../Redux/Company/companyActions.js";
import { setGlobalLoader } from "../Redux/Global/globalActions.js";
import { logoutUser, setActiveBillingAccount } from "../Redux/AccountInfo/accountInfoActions.js";

// Session timeout
const sessionTimeout = 1800000;
// Trigger popup
const triggerWarningAt = 180000;

const pagesNoSession = [
  "login",
  "register",
  "forgot-password",
  "user-registration",
  "verify-registration",
  "help",
];

const SessionManager = () => {
  const [remaining, setRemaining] = useState(sessionTimeout);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const logout = async () => {
    dispatch(setGlobalLoader(true));
    dispatch(logoutUser());
    dispatch(setActiveBillingAccount('0'));
    dispatch(setCompanyByBusinessUnit(siteType()));
    clearServerSession();
    dispatch(setGlobalLoader(false));
    dispatch(resetState());
    localStorage.removeItem("lastMovement");
    localStorage.clear();
    window.location.hash = "/login";
  };

  const checkSessionTimeout = async () => {
    for (const page of pagesNoSession) {
      if (window.location.href.includes(page)) return;
    }
    const now = Date.now();
    const lastMovement = localStorage.getItem("lastMovement");
    if (!lastMovement) {
      await logout();
      return;
    }
    // Calculate remaining time
    const newR = parseInt(lastMovement, 10) + sessionTimeout - now;
    setRemaining(newR);
    // Open warning when remaining time reaches trigger time
    if (!dialogOpen && newR <= triggerWarningAt) setDialogOpen(true);
    // Log out if less than a second remaining
    if (newR < 1000) {
      await logout();
    }
  };

  // Session timer
  useEffect(() => {
    for (const page of pagesNoSession) {
      if (window.location.href.includes(page)) return;
    }
    setInterval(checkSessionTimeout, 1000);
    if (localStorage.getItem("lastMovement") !== null) {
      handleContinue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * This function is called first before setting session
   * It cancels expired sessions after re-opening the window
   */
  const preCheckToken = async () => {
    for (const page of pagesNoSession) {
      if (window.location.href.includes(page)) return;
    }
    const lastMovement = localStorage.getItem("lastMovement");
    if (!lastMovement) {
      await logout();
      return;
    }
    const now = Date.now();
    if (now - parseInt(lastMovement, 10) >= sessionTimeout) {
      await logout();
    } else {
      await handleContinue();
    }
  };

  window.addEventListener("beforeunload", async (ev) => {
    await preCheckToken();
    return null;
  });

  const handleLogout = async () => {
    setDialogOpen(false);
    await logout();
  };

  const handleContinue = async () => {
    const authToken = localStorage.getItem("token");
    localStorage.setItem("lastMovement", Date.now().toFixed(0));
    try {
      const hostname = window.location.hostname;
      await axiosWrapper(
        hostname,
        "POST",
        `/auth/extend-session`,
        {
          Authorization: `Bearer ${authToken}`,
        },
        undefined
      );
    } catch (err) {
      catchAxios(err);
    }
    setDialogOpen(false);
  };

  if (remaining > triggerWarningAt || remaining < 0) return null;

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="comment-dialog-title"
      aria-describedby="comment-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>Session Timeout</DialogTitle>
      <DialogContent>
        <div>
          The current session is about to expire in{" "}
          {Math.floor(remaining / 1000).toFixed(0)} seconds.
        </div>
        <div>Are you sure you want to continue?</div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleLogout} className="mr-2">
          Logout
        </Button>
        <Button variant="contained" color="primary" onClick={handleContinue}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionManager;
