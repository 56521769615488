import React from 'react';
import style from './styles/Header.module.scss';
import ErrorBoundary from "./ErrorBoundary";
import { useSelector } from 'react-redux';

function Header() {
    const company = useSelector((state) => state.companyInfo);

    return (
        <ErrorBoundary>
            <div className={style.logo}>
                <a href={company.portalUrl} title={company.name}>
                    <img src={company.logo} alt={company.name}/>
                </a>
            </div>
        </ErrorBoundary>
    )
}

export default Header;
