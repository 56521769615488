import React from "react";
import { Redirect } from "react-router-dom";
import { catchAxios, clearServerSession } from "./utils/utils";
import SocketIO from "./SocketIO";
import { axiosWrapper } from "../utils/axiosWrapper";

function ReloadToken() {
  const authToken = localStorage.getItem("token");
  if (authToken) {
    const hostname = window.location.hostname;

    return axiosWrapper(hostname, "POST", "/auth/refresh", {
      Authorization: `Bearer ${authToken}`,
    })
      .then((response) => {
        // Check this
        if (response.data.status && response.data.authToken) {
          localStorage.setItem("token", response.data.authToken);
          SocketIO("emit", "userAction", {
            current_url: window.location.href,
          });
          return response.data;
        } else {
          clearServerSession();
          return <Redirect to="/login" />;
        }
      })
      .catch((err) => {
        catchAxios(err);
      });
  }
  return new Promise((r) => r);
}

export default ReloadToken;
