import React from "react";
import NumberCounter from "../NumberCounter";
import { FaSpinner } from "react-icons/fa";

const AmountDueSummary = (props) => {
  const { amountDue = 0.0, loading = false } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center', columnGap: '0.75rem', marginBottom: '0.5rem' }}>
      <div style={{ fontSize: '1.75rem', color: '#074481' }}>Amount Due:</div>
      <div style={{ fontSize: '1.75rem', color: '#074481' }}>
        {loading ?
          <FaSpinner className="fa-spin" />
          : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.25rem' }}>
              <span>$</span>
              <NumberCounter value={amountDue.toFixed(2)} />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default AmountDueSummary;
