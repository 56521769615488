import * as customerInfoType from "./customerInfoType";

export const setCustomerInfoProperty = (key, value) => ({
    type: customerInfoType.CUSTOMER_INFO_SET_PROPERTY,
    key,
    value
});

export const setBillingInfoProperty = (key, value) => ({
    type: customerInfoType.CUSTOMER_INFO_SET_BILLING_INFO,
    key,
    value
});

export const removeServiceAddress = (index) => ({
    type: customerInfoType.CUSTOMER_INFO_REMOVE_SERVICE_ADDRESS,
    payload: index,
});

export const addServiceAddress = (address) => ({
    type: customerInfoType.CUSTOMER_INFO_ADD_SERVICE_ADDRESS,
    payload: address,
});

export const setSelectedService = (key, value) => ({
    type: customerInfoType.CUSTOMER_INFO_SET_SELECTED_PLAN,
    key,
    value
});

export const setSelectedLocation = (key, value) => ({
    type: customerInfoType.CUSTOMER_INFO_SET_SELECTED_LOCATION,
    key,
    value
});

export const setUpgradableService = (key, value) => ({
    type: customerInfoType.CUSTOMER_INFO_SET_UPGRADABLE_SERVICE,
    key,
    value
});

export const setCustomRequest = (request) => ({
    type: customerInfoType.CUSTOMER_INFO_SET_CUSTOM_REQUEST,
    payload: request,
});