// Font Awesome pro config
import {library} from '@fortawesome/fontawesome-svg-core' // Import fa library
import {
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
    faChrome,
    faFirefox,
    faEdge,
    faInternetExplorer,
    faOpera,
    faSafari,
    faWindows,
    faLinux,
    faApple
} from '@fortawesome/free-brands-svg-icons'

// Import FontAwesome Individual Icons used in the app.
library.add(faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
    faChrome,
    faFirefox,
    faEdge,
    faInternetExplorer,
    faOpera,
    faSafari,
    faWindows,
    faLinux,
    faApple
);
