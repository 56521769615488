import React, { useState } from "react";
import { Container, Grid, Button, Box } from "@material-ui/core";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  AimOutlined,
  ApiOutlined,
  IdcardOutlined,
  CreditCardOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  decrementStep,
  incrementStep,
  setStepData,
} from "../../Redux/StepTracker/stepTrackerActions";
import AvailableServices from "./AvailableServices";
import UpgradeTo from "./UpgradeTo";
import ContactInformation from "./ContactInformation";
import CustomRequest from "./CustomRequest";
import { makeStyles } from "@material-ui/core/styles";
import "./Upgrade.scss";
import UpgradeOverview from "./UpgradeOverview";
import OrderFunction from "../../Functions/Order";

const useStyles = makeStyles((theme) => ({
  activeStepIconContainer: {
    backgroundColor: "orange !important",
    color: "#fff",
  },
  inactiveStepIconContainer: {
    backgroundColor: "#074481",
    color: "#fff",
  },
  futureStepIconContainer: {
    backgroundColor: "#bbdefb !important",
    color: "#fff",
  },
}));

function Upgrade() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const upgradeActiveStep = useSelector(
    (state) => state.step.upgradeActiveStep
  );
  const upgradableService = useSelector(
    (state) => state.customerInfo.upgradableService
  );
  const selectedLocation = useSelector(
    (state) => state.customerInfo.selectedLocation
  );
  const customRequest = useSelector(
    (state) => state.customerInfo.customRequest
  );
  const contactInformation = useSelector(
    (state) => state.customerInfo.contactInformation
  );
  // const billingInformation = useSelector(
  //   (state) => state.customerInfo.billingInformation
  // );

  const [showError, setShowError] = useState(false);
  const [loadingUpgradeSubmit, setLoadingUpgradeSubmit] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");

  const clearErrors = () => {
    setShowError(false);
  };

  const handleNext = async () => {
    const isValid = await validateCurrentStep();
    if (
      upgradeActiveStep === 1 &&
      upgradableService.serviceName !== "Custom value" &&
      isValid
    ) {
      dispatch(incrementStep("upgradeActiveStep"));
    }
    if (isValid) {
      dispatch(incrementStep("upgradeActiveStep"));
      clearErrors();
    }
  };

  const handleBack = () => {
    if (
      upgradeActiveStep === 3 &&
      upgradableService.serviceName !== "Custom value"
    ) {
      dispatch(decrementStep("upgradeActiveStep"));
    }
    dispatch(decrementStep("upgradeActiveStep"));
  };

  const handleStepClick = async (step) => {
    if (upgradeActiveStep > step) {
      dispatch(setStepData("upgradeActiveStep", step));
    } else {
      const isCurrentStepValid = await validateCurrentStep();
      if (isCurrentStepValid) {
        dispatch(setStepData("upgradeActiveStep", step));
      }
    }
  };

  const validateCurrentStep = () => {
    switch (upgradeActiveStep) {
      case 0:
        if (!selectedLocation.serviceId) {
          setShowError(true);
          return Promise.resolve(false);
        }
        break;
      case 1:
        if (!upgradableService.serviceId) {
          setShowError(true);
          return Promise.resolve(false);
        }
        break;
      case 2:
        if (!customRequest) {
          setShowError(true);
          return Promise.resolve(false);
        }
        break;
      case 3:
        if (
          !contactInformation.firstName ||
          !contactInformation.lastName ||
          !contactInformation.email ||
          !contactInformation.customerAddress
        ) {
          setShowError(true);
          return Promise.resolve(false);
        }
        break;
      // case 4:
      //   if (
      //     !billingInformation.cardHolderName ||
      //     !billingInformation.cardNumber ||
      //     !billingInformation.expDate ||
      //     !billingInformation.cvc
      //   ) {
      //     setShowError(true);
      //     return Promise.resolve(false);
      //   }
      //   break;
      default:
        break;
    }

    setShowError(false);
    return Promise.resolve(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    submitUpgrade();
  };

  let formData = {
    first_name: "",
    last_name: "",
    email: "",
    package_name: "",
    package_price: "",
    service_address: "",
    billing_address: "",
    current_MB: "",
    current_price: "",
    status: "",
    contractno: "",
    custom_description: "",
  };

  const processFormData = () => {
    formData.first_name = contactInformation?.firstName;
    formData.last_name = contactInformation?.lastName;
    formData.email = contactInformation?.email;
    formData.package_name = upgradableService?.serviceName;
    formData.package_price = upgradableService?.servicePrice;
    formData.service_address = selectedLocation?.serviceName;
    formData.current_MB = selectedLocation?.serviceMB;
    formData.current_price = selectedLocation?.servicePrice;
    formData.contractno = selectedLocation?.serviceId;
    formData.custom_description = customRequest;

    return formData;
  };

  const submitUpgrade = () => {
    setLoadingUpgradeSubmit(true);
    let formattedFormData = processFormData();
    OrderFunction.saveUpgrade(formattedFormData)
      .then((response) => {
        if (response.status) {
          setShowError(false);
          setRequestMessage(response.data.message);
        } else {
          setShowError(true);
          setRequestMessage(response.data.message);
        }
      })
      .catch((error) => {
        setShowError(true);
        setRequestMessage(
          "Unable to create a upgrade request, please try again later."
        );
      })
      .finally(() => {
        setLoadingUpgradeSubmit(false);
      });
  };

  const getStepIcon = (index) => {
    const icons = {
      0: <AimOutlined className="stepperIcons" />,
      1: <ApiOutlined className="stepperIcons" />,
      2: <IdcardOutlined className="stepperIcons" />,
      3: <CreditCardOutlined className="stepperIcons" />,
      4: <ContainerOutlined className="stepperIcons" />,
      // 5: <ContainerOutlined className="stepperIcons" />,
    };
    return icons[index];
  };

  const showCustomRequestStep =
    upgradableService.serviceName === "Custom value";

  return (
    <Container className="upgrade-container">
      <Box boxShadow={3} mb={5} p={3} bgcolor="white" className="shadow-box">
        <Grid container alignItems="center" className="stepper-container">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={upgradeActiveStep === 0}
              onClick={handleBack}
              startIcon={<ArrowLeftOutlined className="backIcon-button" />}
              className="step-button back-button"
            />
          </Grid>
          <Grid item xs>
            <Stepper
              activeStep={upgradeActiveStep}
              alternativeLabel
              className="stepper"
            >
              {[
                "Service location",
                "Service",
                showCustomRequestStep ? "Custom request" : null,
                "Contact Information",
                // "Billing Information",
                "Preview",
              ].map(
                (label, index) =>
                  label && (
                    <Step
                      key={label}
                      onClick={() => handleStepClick(index)}
                      completed={upgradeActiveStep > index}
                    >
                      <StepLabel
                        StepIconProps={{
                          icon: getStepIcon(index),
                          completed: upgradeActiveStep > index,
                        }}
                        classes={{
                          iconContainer:
                            upgradeActiveStep === index
                              ? classes.activeStepIconContainer
                              : upgradeActiveStep > index
                              ? classes.inactiveStepIconContainer
                              : classes.futureStepIconContainer,
                        }}
                        disabled={upgradeActiveStep < index}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  )
              )}
            </Stepper>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={upgradeActiveStep === 5}
              endIcon={<ArrowRightOutlined className="nextIcon-button" />}
              className="step-button next-button"
            />
          </Grid>
        </Grid>
      </Box>
      {upgradeActiveStep === 0 && <AvailableServices showError={showError} />}
      {upgradeActiveStep === 1 && <UpgradeTo showError={showError} />}
      {upgradeActiveStep === 2 && showCustomRequestStep && (
        <CustomRequest showError={showError} />
      )}
      {upgradeActiveStep === 3 && <ContactInformation showError={showError} />}
      {/* {upgradeActiveStep === 4 && <BillingInformation showError={showError} />} */}
      {upgradeActiveStep === 4 && (
        <UpgradeOverview
          onSubmit={handleSubmit}
          loading={loadingUpgradeSubmit}
          message={requestMessage}
          error={showError}
        />
      )}
    </Container>
  );
}

export default Upgrade;
