import React, { useMemo } from "react";
import TicketAttachmentImage from "./TicketAttachmentImage";
import "./TicketHistoryChat.scss";

/**
 * Chatbox for image attachments
 * @param {{
 *  attachments: {id: string, contentType: string, ticket: string}[],
 *  fullImageHandler: (_: string) => void,
 * }} props 
 */
const TicketCommentAttachments = (props) => {
  const { attachments, fullImageHandler } = props;

  const filteredAttachments = useMemo(() =>
    attachments.filter((a) => a.contentType.startsWith('image')),
  [attachments]);

  if (!filteredAttachments.length) return null;

  return (
    <div>
      <span className="wiline-hr"></span>
      <div className="chat_footer">
        {
          filteredAttachments.map((a) => (
            <div key={a.id} className="image_box">
              <TicketAttachmentImage ticket={a.ticket} id={a.id} contentType={a.contentType} fullImageHandler={fullImageHandler} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default TicketCommentAttachments;
