import * as stepTrackerType from './stepTrackerType';

const START_STATE = {
  buyNowActiveStep: 0,
  upgradeActiveStep: 0,
};

const stepTrackerReducer = (state = START_STATE, action) => {
  switch (action.type) {
    case stepTrackerType.STEP_TRACKER_SET_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case stepTrackerType.STEP_TRACKER_INCREMENT:
      return {
        ...state,
        [action.key]: state[action.key] + 1,
      };
    case stepTrackerType.STEP_TRACKER_DECREMENT:
      return {
        ...state,
        [action.key]: state[action.key] - 1,
      };
    default:
      return state;
  }
};

export default stepTrackerReducer;