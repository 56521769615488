import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import LoginForm from './LoginForm';
import style from './styles/LoginForm.module.scss';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
    Button,
    Checkbox,
    Grid,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import stephouse from '../assets/img/stephouse.svg';
import wiline from '../assets/img/Wiline-Logo-Blue-300x128px.png';
import Slide from '@material-ui/core/Slide';
import {siteType} from "../App";
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyByBusinessUnit } from '../Redux/Company/companyActions';
import { setGlobalLoader } from '../Redux/Global/globalActions';

// adds a slide up transition for the dialog box
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
        bg: {
            backgroundColor: theme.palette.primary.main
        },
        title: {
            color: theme.palette.primary.main,
            textAlign: 'center'
        },
        paperFullScreen: {
            height: '100vh'
        }
    })
);

function Login() {
    const classes = useStyles();
    const company = useSelector((state) => state.companyInfo);
    const accountInfo = useSelector((state) => state.accountInfo);
    const dispatch = useDispatch();

    const now = new Date();  // get current date for comparing to modal expiration. Do not show the modal after 30 days.
    const modalExpiration = new Date(2019, 10, 15); // set the date for expiration here
    const theme = useTheme();
    // toggles the dialog box in fullscreen mode for small devices
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [open, setOpen] = React.useState(false);  // handles dialog box state
    const [state, setState] = useState({ //handle state for checkbox
        checked: false
    })
;
    const handleChange = name => event => {
        setState({...state, [name]: event.target.checked})
    };

    // handles the opening of the modal on the first instance the user loads the page
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) localStorage.removeItem('lastMovement');
        dispatch(setCompanyByBusinessUnit(siteType()));

        if (now < modalExpiration) {
            if (!localStorage.getItem('firstViewModal')) { // Store the timestamp of when the modal is first shown
                localStorage.setItem('firstViewModal', now)
            }
            //  if the modal was first viewed more than 7 days ago, expire the modal for that user
            const timeDiff = now.getTime() - new Date(localStorage.getItem('firstViewModal')).getTime();
            const dayDiff = timeDiff / (1000 * 3600 * 24);
            const expiredPerUser = dayDiff > 7 ? true : false;
            if (!localStorage.getItem('hideModal') && !expiredPerUser) {
                handleOpen();
            }
        }
        dispatch(setGlobalLoader(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //empty array is required.

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (state.checked || now > modalExpiration) {
            localStorage.setItem('hideModal', true);
        }
        setOpen(false);
    };

    return (
        <div className={`${style.mainContent} ${classes.bg}`}>
            <Header/>
            <div>
                <LoginForm />
            </div>
            <Footer/>

            {/* Welcome Dialog to show only once to the user */}
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
                maxWidth="md"
                aria-labelledby="dialog-title"
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogTitle id="dialog-title" onClose={handleClose} className={classes.title}>
                    {`Welcome to the Customer Portal`}
                </DialogTitle>
                <DialogContent>
                    {accountInfo.businessUnit === 'WNI' ?
                        <img src={wiline} alt="WiLine logo"
                             style={{maxWidth: "60%", display: 'block', margin: '0vh auto 2vh auto'}}/> :
                        accountInfo.businessUnit === 'SNI' ?
                            <img src={stephouse} alt="Stephouse Logo"
                                 style={{maxWidth: "50%", display: 'block', margin: '0vh auto 2vh auto'}}/> :
                            null
                    }
                    <Typography gutterBottom variant="h5">
                        This new portal brings many improvements to user experience as well as added security.<br/>
                        You can use your old login details to access your account on the new portal just as before.
                    </Typography>
                    <Typography gutterBottom variant="h5" style={{margin: '2vh auto'}}>
                        If you require help with the portal, a
                        <Link to="/help"
                              style={company.id === 0 ? {color: '#074481'} : {color: '#29abe2'}}> quick
                            guide</Link> is available that provides a high-level
                        description of the most common tasks you can do in the billing portal.
                        For further help please contact our portal helpline
                        on: {company.portalHelpLine}.
                    </Typography>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <Checkbox
                                checked={state.checked}
                                onChange={handleChange('checked')}
                                color="primary"
                                value="checkedA"
                                inputProps={{
                                    'aria-label': 'do not show message again checkbox'
                                }}
                            />
                        </Grid>
                        <Grid item >
                            <Typography>
                                Do not show this message again
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Dismiss
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Login;
