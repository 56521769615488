import React from 'react';
import NotFound from '../NotFound.jsx';
import {Switch, Route} from 'react-router-dom';
import Summary from './Summary.jsx';
import style from "../styles/MainLayout.module.scss";
import TicketDetails from './TicketDetails.jsx';
import NestedDrawer from "../NestedDrawer.js";

function MyTickets() {
    return (
        <div className={style.layout}>
            <NestedDrawer />
            <div className={style.gridFix}>
                <div>
                    <Switch>
                        <Route exact path="/business/my-tickets" component={Summary} />
                        <Route exact path="/business/my-tickets/:ticketno" component={TicketDetails}/>
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default MyTickets;
