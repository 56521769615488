import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import NotFound from '../NotFound.jsx'
import Stats from './Stats.js';
import LiveUsers from './LiveUsers.jsx';
import NestedDrawer from '../NestedDrawer';
import style from '../styles/MainLayout.module.scss';

class AdminTools extends React.Component {
    render() {
        return (
            <>
                <div className={style.layout}>
                    <NestedDrawer/>
                    <div className={style.gridFix}>
                        <div>
                            <Switch>
                                <Route exact path="/admin" component={() => <Redirect to='/admin/stats'/>}/>
                                <Route path="/admin/stats" component={Stats}/>
                                <Route path="/admin/live-users" component={LiveUsers}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

AdminTools.propTypes = {
    location: PropTypes.object.isRequired
};

export default AdminTools;