import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import style from './styles/Common.module.scss';
import ErrorBoundary from "./ErrorBoundary";
import { useSelector } from 'react-redux';

const Loader = (props) => {
    const company = useSelector((state) => state.companyInfo);
    const themePrimary = (company.color) ? company.color : 'black';

    return (
        <ErrorBoundary>
            <div className={style.loader_container} style={{height: props.height}}>
                <div
                    className={style.loader}
                    style={{
                        color: themePrimary,
                        height: props.height,
                        backgroundColor: props.bg //used to overwrite white background color if needed
                    }}>
                    <CircularProgress />
                </div>
            </div>
        </ErrorBoundary>
    )
};

export default Loader;

Loader.propTypes = {
    height: PropTypes.string,
    bg: PropTypes.string,
};
