import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

/**
 * Theme for using when you are on the development localhost server
 * @commnd   npm run server
 */
export const DefaultTheme = createTheme({
  palette: {
    primary: {
      // dark: '#29ABE2',
      main: "#0071bc",
      contrastText: "#FFFFFF",
    },
    secondary: {
      contrastText: "#FFFFFF",
      main: "#F05918",
    },
    error: {
      main: "#9C2B23",
    },
    text: {
      primary: "#111",
      // secondary: '#6FB8E8',
      hint: "#FFF959",
      navActiveText: "#353535",
    },
  },
  menuPaper: {
    background:
      "linear-gradient(to bottom, #00538A 0%, #0071BC 50%, #00538A 100%)",
  },
  typography: {
    h1: {
      fontSize: "1.5rem",
      [breakpoints.up("md")]: {
        fontSize: "2.4rem",
      },
    },
    h2: {
      fontSize: "1.4rem",
      [breakpoints.up("md")]: {
        fontSize: "2rem",
      },
    },
    h3: {
      fontSize: "1.3rem",
      [breakpoints.up("md")]: {
        fontSize: "1.8rem",
      },
    },
    h4: {
      fontSize: "1.1rem",
      [breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
    },
    h5: {
      fontSize: "1rem",
      [breakpoints.up("md")]: {
        fontSize: "1.2rem",
      },
    },
    body1: {
      fontSize: "0.8rem",
      [breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
  },
});

/**
 * Theme for Wiline
 */
export const WiLineTheme = createTheme({
  palette: {
    primary: {
      main: "#074481",
      light: "#0063C5",
      contrastText: "white",
    },
    secondary: {
      contrastText: "#474747",
      main: "#fd7e14d9",
    },
    error: {
      main: "#9C2B23",
    },
    text: {
      primary: "#111",
      // secondary: '#6FB8E8',
      hint: "#fd7e14d9",
      navActiveText: "white",
    },
  },
  menuPaper: {
    background: "#074481",
  }
  ,
  typography: {
    h1: {
      fontSize: "1.5rem",
      [breakpoints.up("md")]: {
        fontSize: "2.4rem",
      },
    },
    h2: {
      fontSize: "1.4rem",
      [breakpoints.up("md")]: {
        fontSize: "2rem",
      },
    },
    h3: {
      fontSize: "1.3rem",
      [breakpoints.up("md")]: {
        fontSize: "1.8rem",
      },
    },
    h4: {
      fontSize: "1.1rem",
      [breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
    },
    h5: {
      fontSize: "1rem",
      [breakpoints.up("md")]: {
        fontSize: "1.2rem",
      },
    },
    body1: {
      fontSize: "0.8rem",
      [breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
  },
});

/**
 * Theme for Stephouse
 */
export const StephouseTheme = createTheme({
  palette: {
    primary: {
      // dark: '#29ABE2',
      main: "#0071bc",
      contrastText: "#FFFFFF",
    },
    secondary: {
      contrastText: "#FFFFFF",
      main: "#F05918",
    },
    error: {
      main: "#9C2B23",
    },
    text: {
      primary: "#111",
      // secondary: '#6FB8E8',
      hint: "#FFF959",
      navActiveText: "#353535",
    },
  },
  menuPaper: {
    background:
      "linear-gradient(to bottom, #00538A 0%, #0071BC 50%, #00538A 100%)",
  },
  typography: {
    h1: {
      fontSize: "1.5rem",
      [breakpoints.up("md")]: {
        fontSize: "2.4rem",
      },
    },
    h2: {
      fontSize: "1.4rem",
      [breakpoints.up("md")]: {
        fontSize: "2rem",
      },
    },
    h3: {
      fontSize: "1.3rem",
      [breakpoints.up("md")]: {
        fontSize: "1.8rem",
      },
    },
    h4: {
      fontSize: "1.1rem",
      [breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
    },
    h5: {
      fontSize: "1rem",
      [breakpoints.up("md")]: {
        fontSize: "1.2rem",
      },
    },
    body1: {
      fontSize: "0.8rem",
      [breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
  },
});
