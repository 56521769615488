import React from 'react'
// Material Ui components
import {Button, Paper, makeStyles} from '@material-ui/core';
// Sass Styles
import style from '../styles/CurrentBalance.module.scss'
import PropTypes from "prop-types";
import jwt from 'jsonwebtoken';
import { FaSpinner } from 'react-icons/fa';
import NumberCounter from '../NumberCounter';

/**
 *  This Component renders the the current Balance for a user's billing account.  It also provides a button which redirects the user to the "make a payment" page.
 *  @module CurrentBalance
 *  @param {Object} props - loading: Boolean </br> balance: Int
 *  @see {@link module:MakeAPayment MakeAPayment}
 */

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        background: `linear-gradient(to bottom, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 50%, ${theme.palette.primary.main} 100%)`
    },
    title: {
        color: theme.palette.common.white,
        textAlign: 'center',
        fontSize: '18px',
        paddingBottom: '0.5em'
    },
    balance: {
        color: theme.palette.common.white,
        paddingBottom: '12px',
        fontSize: '3em',
        margin: '0 auto',
        fontWeight: 600
    },
    balance_content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

function CurrentBalance(props) {
    const token = localStorage.getItem('token');
    const decoded = jwt.decode(token);
    const readOnly = !!(decoded.user.readOnly);
    const {balance, loading} = props;
    const classes = useStyles();
    // Loading spinner
    const content = loading
        ? <FaSpinner className='fa-spin'/>
    : (
        <div className={classes.balance_content}>
            <span>$</span>
            <NumberCounter value={balance} />
        </div>
    )

    return (
        <Paper className={`${classes.paper} ${style.container}`}>
            <div className={classes.title}>CURRENT BALANCE</div>
            <div className={classes.balance}>
                {content}
            </div>
            <Button color="secondary" variant="contained" href="/#/business/payments/make-payment" disabled={readOnly}>
                Make a Payment
            </Button>
        </Paper>
    )
}

CurrentBalance.propTypes = {
    balance: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
             ]),
    loading: PropTypes.bool.isRequired,
};

export default CurrentBalance
