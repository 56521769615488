import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Grid,
  Typography,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import { FaBuilding } from "react-icons/fa";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DebounceSelect from "./DebounceSelect";
import axios from "axios";
import {
  addServiceAddress,
  removeServiceAddress,
} from "../../Redux/CustomerInfo/customerInfoActions";
import "./ServiceLocation.scss";

const SNI_DOMAINS = process.env.REACT_APP_STEPHOUSE_HOSTNAME_ALLOW;

const WNI_HOST = process.env.REACT_APP_API_HOST;
const SNI_HOST = process.env.REACT_APP_API_SNI_HOST;

function ServiceLocation({ showError }) {
  const params = useParams();
  const haveStep = "step" in params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const serviceAddress = useSelector(
    (state) => state.customerInfo.serviceAddress
  );

  const [isAddressSelected, setIsAddressSelected] = useState(false);

  const changeRouter = () => {
    if (!haveStep) {
      const newParams = new URLSearchParams(location.search);
      newParams.set("step", "Service location");
      history.push({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    }
  };

  useEffect(() => {
    changeRouter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsAddressSelected(serviceAddress.length > 0);
  }, [serviceAddress]);

  const handleSelect = (value, option) => {
    const [address1, city, state, zip] = option.label
      .split(", ")
      .map((item) => item.trim());
    const selectedAddress = { address1, city, state, zip };
    dispatch(addServiceAddress(selectedAddress));
  };

  const handleRemove = (index) => {
    dispatch(removeServiceAddress(index));
  };

  async function fetchAddressList(address) {
    try {
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;
      const urlHost = SNI_DOMAINS.includes(hostname) ? SNI_HOST : WNI_HOST;
      const response = await axios.post(
        `${urlHost}/search/search-address`,
        { searchTerm: address },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const data = response.data;

      if (data) {
        const addressList = data.data.map((buildings) => {
          const address = buildings.address
            ? buildings.address
            : buildings.address1;
          return {
            label: `${address}, ${buildings.city}, ${buildings.state}, ${buildings.zip}`,
            value: `${address}, ${buildings.city}, ${buildings.state}, ${buildings.zip}`,
          };
        });
        return addressList;
      } else {
        throw new Error(data.msg || "Error: No addresses found");
      }
    } catch (error) {
      console.error("Error fetching address data:", error.message);
      throw error;
    }
  }

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item md={8} xs={12}>
          <div>
            <DebounceSelect
              mode="multiple"
              placeholder="Start typing address..."
              fetchOptions={fetchAddressList}
              onSelect={handleSelect}
              style={{
                width: "100%",
                marginBottom: "20px",
              }}
              className={showError ? "error" : ""}
            />
          </div>
          <div>
            {!isAddressSelected && showError && (
              <Typography variant="body2" color="error" className="error-text">
                Please select service address!
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={10} className="mt-5">
        {serviceAddress.map((item, index) => (
          <Grid
            item
            xs={6}
            md={3}
            lg={3}
            key={index}
            className="mb-3 py-4 px-2"
          >
            <Card className="cards">
              <div className="position-relative card-content">
                <div className="location-header">
                  <Typography variant="h9" className="card-heading">
                    {item.state}, {item.zip}
                  </Typography>
                  <Tooltip title="Remove">
                    <IoClose
                      className="cursor-pointer"
                      size={20}
                      onClick={() => handleRemove(index)}
                    />
                  </Tooltip>
                </div>
                <div className="text-center pt-1 pb-7 card-body">
                  <div className="card-item">
                    <Typography
                      variant="subtitle2"
                      component="label"
                      className="item-label"
                    >
                      <b>Address</b>
                    </Typography>
                    <Typography
                      variant="body4"
                      component="span"
                      className="item-value normal-weight"
                    >
                      {item.address1}
                    </Typography>
                  </div>
                  <div className="card-item">
                    <Typography
                      variant="subtitle2"
                      component="label"
                      className="item-label"
                    >
                      <b>City</b>
                    </Typography>
                    <Typography
                      variant="body4"
                      component="span"
                      className="item-value normal-weight"
                    >
                      {item.city}
                    </Typography>
                  </div>
                </div>
                <div className="icon-container">
                  <FaBuilding className="wiline-blue" size={28} />
                </div>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ServiceLocation;
