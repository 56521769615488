import React from 'react';
import SectionTitle from "../SectionTitle.jsx";
// Material Ui Components
import {withStyles} from "@material-ui/core/styles";
import {Grid, Paper} from '@material-ui/core';
import ChangeBillingAddressForm from "./ChangeBillingAddressForm";
import {enterComponent} from '../animated.js'
// SASS Styles
import style from '../styles/MainLayout.module.scss';
import {Switch, Route} from 'react-router-dom';
import NotFound from '../NotFound.jsx';
import LoaderPage from "../LoaderPage";

/**
 *  This Component renders the {@link module:ChangeBillingAddressForm ChangeBillingAddressForm} component.
 *  @module ChangeAccountDetails
 */
const AnimateGrid = enterComponent(Grid);

const styles = theme => ({
    container: {
        marginTop: '4vh'
    },
    button: {
        [theme.breakpoints.up('xs')]: {
            minWidth: '190px',
            margin: '8px',
        },
    },
    active: {
        backgroundColor: `#FFAA00`,
        '&:hover': {
            backgroundColor: '#f7a500',
        }
    },
    error: {
        color: '#B50000'
    },
    paper: {
        minHeight: 'calc(100vh-64px)',
        padding: '4vh 2vw',
        margin: '20px auto 80px auto',
        maxWidth: '960px'
    },
});

function ChangeAccountDetails({classes}) {
    return (
        <>
            <LoaderPage/>
            <React.Fragment>
                <SectionTitle variant="h1" style={style.welcome} title="Change Account Details"/>
                <div style={{height: '20px'}}/>
                <SectionTitle variant="h3" title="Billing Information"/>
                <Grid container justifyContent="center">
                    <AnimateGrid item xs={12} lg={10} style={{padding: '2vh'}}>
                        <Paper className={`${style.shadow} ${classes.paper}`}>
                            <Switch>
                                <Route exact path={'/business/my-account/change-account-details/billing-address'}
                                       component={ChangeBillingAddressForm}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </Paper>
                    </AnimateGrid>
                </Grid>
            </React.Fragment>
        </>
    )
};

export default withStyles(styles)(ChangeAccountDetails)

