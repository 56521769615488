import React, { useMemo, useState } from "react";
import "./TicketHistoryChat.scss";

/**
 * @param {string} line 
 */
const printContentLine = (line) => {
  let quoteLevel = 0;
  let str = line;
  const shadeFactor = 0.9;
  while (str.startsWith('>')) {
    quoteLevel++;
    str = str.substring(1).trimStart();
  }
  const textColor = `rgba(0, 0, 0, ${Math.pow(shadeFactor, quoteLevel * 1.0).toFixed(2)})`;
  /**
   * @type {JSX.Element[]}
   */
  const elems = [];
  if (str.length) {
    for (let i = 1; i <= quoteLevel; i++) {
      elems.push(
        <div
          key={`q-${i}`}
          className="quote_block"
          style={{ backgroundColor: `rgba(0, 0, 0, ${Math.pow(shadeFactor - 0.1, i * 1.0).toFixed(2)})` }}
        />
      );
    }
    elems.push(<div key="content" style={{ color: textColor, maxWidth: `calc(100% - ${(quoteLevel + 1) * 10}px)` }}>{str}</div>);
  }
  return elems;
}

/**
 * @param {{ content: string[], id: string }} props 
 */
const TicketHistoryContent = (props) => {
  const { content, id } = props;
  const hasQuotedMessages = content.filter((v) => v.startsWith('> ')).length > 0;
  const [showQuoted, setShowQuoted] = useState(false);
  const nonQuotedContent = useMemo(() => content.filter((v) => !v.startsWith('> ')), [content]);
  const quotedContent = useMemo(() => content.filter((v) => v.startsWith('> ')), [content])

  return (
    <React.Fragment key={id}>
      {nonQuotedContent.map((c, idx) => <div key={`${idx}-${c}`} className="line">{printContentLine(c)}</div>)}
      {hasQuotedMessages && (
        <div key="quote-toggle" className="quote_toggle" onClick={() => setShowQuoted(!showQuoted)}>
          {showQuoted ? 'Hide quoted messages' : 'Show quoted messages'}
        </div>
      )}
      {showQuoted && quotedContent.map((c, idx) => <div key={`${idx}-${c}`} className="line">{printContentLine(c)}</div>)}
    </React.Fragment>
  );
};

export default TicketHistoryContent;
