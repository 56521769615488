import React, { Component } from 'react';
import {Redirect, Route, withRouter} from 'react-router-dom';
import jwt from 'jsonwebtoken';

/**
 * This route will not be available for read-only users
 */
class ProtectedRoute extends Component {
    render () {
        let {component: Component, fallback, ...rest} = this.props;
        const token = localStorage.getItem('token');
        const decoded = jwt.decode(token);
        const readOnly = !!(decoded.user.readOnly);

        return (
            <Route {...rest} render={props => (
                readOnly ? (
                    <Redirect
                        to={{
                            pathname: fallback,
                            state: { from: props.location },
                        }}
                    />
                ) :
                (<Component {...props} />)
            )}/>
        )
    }
}

export default withRouter(ProtectedRoute);