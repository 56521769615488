import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const FancyDoughnut = ({ data, title, colors, getDetails }) => {
  // If there's only one entry with "No Data", display text instead of a pie chart
  const isNoData =
    data.length === 1 &&
    (data[0].label === "no data" || data[0].label === "No Data in Time Range");

  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: data.map((item) => colors[item.label] || "#ccc"),
        hoverBackgroundColor: data.map((item) => colors[item.label] || "#aaa"),
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          padding: 20,
        },
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const label = chartData.labels[tooltipItem.dataIndex];
            const value = chartData.datasets[0].data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      datalabels: {
        color: "#fff",
        font: {
          weight: "bold",
          size: 14,
        },
        formatter: (value, context) => {
          return value > 0 ? value : ""; //If 0 dont show
        },
      },
    },

    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        if (getDetails) {
          getDetails(
            chartData.labels[index],
            chartData.datasets[0].data[index]
          );
        }
      }
    },
  };

  return (
    <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
      <h2>{title}</h2>
      {isNoData ? (
        <p style={{ marginTop: "50px", fontSize: "16px" }}>{data[0].label}</p>
      ) : (
        <Doughnut data={chartData} options={options} />
      )}
    </div>
  );
};

export default FancyDoughnut;
