import React from "react";
import { axiosWrapper } from "../utils/axiosWrapper";
import axios from "axios";

const SELF_SERVICE_HOST = process.env.REACT_APP_API_SELF_SERVICE_HOST;

class OrderClass extends React.Component {
  async placeOrder(formData) {
    //Convert Expiry date
    if (formData.exp_date) {
      const [month, year] = formData.exp_date.split("/");
      formData.exp_date = `${month}/${"20" + year}`; // Append "20" to the year
    }
    const authToken = localStorage.getItem("token");
    const hostname = window.location.hostname;

    const result = await axiosWrapper(
      hostname,
      "POST",
      "/order/save-order",
      {
        Authorization: `Bearer ${authToken}`,
      },
      formData
    )
      .then((response) => {
        if (response.status === 200) {
          return {
            status: true,
            data: response.data,
            statusCode: response.status,
          };
        } else {
          return {
            status: false,
            data: response.data,
            statusCode: response.status,
          };
        }
      })
      .catch((err) => {
        let statusCode = 500;
        let statusMsg = "CONNECTION REFUSED";
        if (err.response) {
          statusMsg = err.response.data.error;
          statusCode = err.response.status;
        }
        return {
          status: false,
          message: statusMsg,
          statusCode: statusCode,
          data: err.response.data,
        };
      });
    return result;
  }

  async placeOrderSS(formData) {
    const areAllFieldsFilled = (data) => {
      const fields = [
        "first_name",
        "last_name",
        "company_name",
        "email",
        "phone_number",
        "package_name",
        "package_price",
        "service_address",
      ];
      for (let field of fields) {
        if (!data[field] || String(data[field]).trim() === "") {
          return false;
        }
      }
      return true;
    };

    // const areAllFieldsFilled = () => {
    //   const isCreditCard = formData.payment_method === "creditCard";
    //   const isACH = formData.payment_method === "ach";
    //   return Object.entries(formData).every(([key, value]) => {
    //     if (isCreditCard) {
    //       // Allow empty fields for credit card payments

    //       return (
    //         key !== "focus" &&
    //         key !== "complete_data" &&
    //         key !== "bank_name" &&
    //         key !== "routing_number" &&
    //         key !== "account_number" &&
    //         key !== "account_type" &&
    //         value !== undefined &&
    //         value !== null &&
    //         value !== ""
    //       );
    //     } else if (isACH) {
    //       // Allow empty fields for ACH payments
    //       return (
    //         key !== "card_number" &&
    //         key !== "exp_date" &&
    //         key !== "cvc" &&
    //         value !== undefined &&
    //         value !== null &&
    //         value !== ""
    //       );
    //     } else {
    //       // Default behavior for other payment types
    //       return value !== undefined && value !== null && value !== "";
    //     }
    //   });
    // };

    if (areAllFieldsFilled(formData)) {
      formData.complete_data = true;
    } else {
      formData.complete_data = false;
    }

    const result = await axios({
      method: "POST",
      url: `${SELF_SERVICE_HOST}/save-order?api=self-service`,
      data: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          return {
            status: true,
            data: response.data,
            statusCode: response.status,
          };
        } else {
          return {
            status: false,
            data: response.data,
            statusCode: response.status,
          };
        }
      })
      .catch((err) => {
        let statusCode = 500;
        let statusMsg = "CONNECTION REFUSED";
        if (err.response) {
          statusMsg = err.response.data.error;
          statusCode = err.response.status;
        }
        return {
          status: false,
          message: statusMsg,
          statusCode: statusCode,
          data: err.response.data,
        };
      });
    return result;
  }

  async saveUpgrade(formData) {
    const authToken = localStorage.getItem("token");
    const hostname = window.location.hostname;

    const result = await axiosWrapper(
      hostname,
      "POST",
      "/order/save-upgrade-request",
      {
        Authorization: `Bearer ${authToken}`,
      },
      formData
    )
      .then((response) => {
        if (response.status === 200) {
          return {
            status: true,
            data: response.data,
            statusCode: response.status,
          };
        } else {
          return {
            status: false,
            data: response.data,
            statusCode: response.status,
          };
        }
      })
      .catch((err) => {
        let statusCode = 500;
        let statusMsg = "CONNECTION REFUSED";
        if (err.response) {
          statusMsg = err.response.data.error;
          statusCode = err.response.status;
        }
        return {
          status: false,
          message: statusMsg,
          statusCode: statusCode,
          data: err.response.data,
        };
      });
    return result;
  }

  async getCurrentServices() {
    const authToken = localStorage.getItem("token");
    const hostname = window.location.hostname;

    const result = await axiosWrapper(
      hostname,
      "GET",
      "/order/get-current-services",
      {
        Authorization: `Bearer ${authToken}`,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return {
            status: true,
            data: response.data,
            statusCode: response.status,
          };
        } else {
          return {
            status: false,
            data: response.data,
            statusCode: response.status,
          };
        }
      })
      .catch((err) => {
        let statusCode = 500;
        let statusMsg = "CONNECTION REFUSED";
        if (err.response) {
          statusMsg = err.response.data.error;
          statusCode = err.response.status;
        }
        return {
          status: false,
          message: statusMsg,
          statusCode: statusCode,
          data: err.response.data,
        };
      });
    return result;
  }
}
const OrderFunction = new OrderClass();
export default OrderFunction;
