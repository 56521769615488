import React, { useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./UpgradeOverview.scss";
import { FaCog } from "react-icons/fa";

function UpgradeOverview({ onSubmit, loading, message, error }) {
  // const billingInformation = useSelector(
  //   (state) => state.customerInfo.billingInformation
  // );
  const contactInformation = useSelector(
    (state) => state.customerInfo.contactInformation
  );
  const serviceAddress = useSelector(
    (state) => state.customerInfo.selectedLocation
  );
  const upgradableService = useSelector(
    (state) => state.customerInfo.upgradableService
  );
  const params = useParams();
  const haveStep = "step" in params;
  const history = useHistory();
  const location = useLocation();

  const changeRouter = () => {
    if (!haveStep) {
      const newParams = new URLSearchParams(location.search);
      newParams.set("step", "Preview");
      history.push({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    }
  };
  useEffect(() => {
    changeRouter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="pb-4">
      <Grid container spacing={4} className="mt-4">
        <Grid item md={6}>
          <Paper className="form-container">
            <Typography variant="h4" gutterBottom>
              Contact Information
            </Typography>
            <TextField
              label="First name"
              value={contactInformation.firstName}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />
            <TextField
              label="Last name"
              value={contactInformation.lastName}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />
            <TextField
              label="Email"
              value={contactInformation.email}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />
            <TextField
              label="Contact address"
              value={contactInformation.customerAddress}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper className="form-container">
            <Typography variant="h4" gutterBottom>
              Requested Service
            </Typography>
            <TextField
              label="Service address"
              value={serviceAddress.serviceName}
              multiline
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />
            <TextField
              label="Requested upgrade"
              value={upgradableService.serviceName}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />

            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              className="back-button"
              disabled={loading || (message.length > 0 && !error)}
            >
              {loading ? (
                <FaCog />
              ) : message.length > 0 && !error ? (
                "Request Submitted!"
              ) : (
                "Submit Upgrade Request"
              )}
            </Button>
            {message.length > 0 && (
              <Typography
                color={error ? "error" : "primary"}
                align="center"
                className="response-message"
              >
                {message}
              </Typography>
            )}
          </Paper>
        </Grid>

        {/* <Grid item md={6}>
          <Paper className="form-container">
            <Typography variant="h4" gutterBottom>
              Billing Information
            </Typography>
            <TextField
              label="Card holder name"
              value={billingInformation.cardHolderName}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />
            <TextField
              label="Card number"
              value={billingInformation.cardNumber}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />
            <TextField
              label="Expiration date"
              value={billingInformation.expDate}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />
            <TextField
              label="CVC"
              value={billingInformation.cvc}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
              className="bg-white"
            />
          </Paper>
        </Grid> */}
      </Grid>
      <Grid container spacing={4} className="mt-4"></Grid>
    </Container>
  );
}

export default UpgradeOverview;
