import React, { useEffect, useState, useRef } from "react";
import AccountDetails from "./AccountDetails.js";
import CurrentBalance from "./CurrentBalance.js";
import SectionTitle from "../SectionTitle.jsx";
import InvoiceDataContainer from "../documents/InvoiceDataContainer.js";
import InvoiceTable from "../documents/InvoiceTable.jsx";
import { enterComponent } from "../animated.js";

// Material UI
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import ErrorBoundary from "../ErrorBoundary";
import {
  catchAxios,
  getSessionStorage,
  handleServerStatus,
} from "../utils/utils";
// import CloseIcon from "@material-ui/icons/Close";
import LoaderPage from "../LoaderPage";

// Survey JS
// import * as Survey from "survey-react";
import "survey-react/survey.css";
import { axiosWrapper } from "../../utils/axiosWrapper.js";
import { useDispatch, useSelector } from "react-redux";
import { setBalance, setCustomer, setVoice } from "../../Redux/AccountInfo/accountInfoActions.js";

/**
 *  This Component renders the landing page a user sees after logging.  If the user is an Admin, or has multiple accounts, the user will land on this page after selecting an account.  The user is able to to see an overview of their account, including some account details, their current balance, and their latest invoices.
 *  @module AccountSummary
 *  @see {@link module:AccountDetails AccountDetails}, </br> {@link module:Currentbalance CurrentBalance}, </br> {@link module:InvoiceDataContainer InvoiceDataContainer}
 */

const AnimateGrid = enterComponent(Grid);

const useStyles = makeStyles((theme) => ({
  myAccountWelcome: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    padding: "4vh 3vw",
    fontSize: "2rem",
    fontWeight: 300,
    textAlign: "left",
    boxShadow: "inset 0px -3px 3px rgba(0,0,0,0.22)",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
  surveyQuestionMark: {
    color: theme.palette.common.white,
  },
  surveyButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogPaper: {
    minWidth: "900px",
    maxWidth: "100%",
    minHeight: "50vh",
  },
}));

// Placeholder for account data
// Prevents child component from breaking
const emptyData = {
  detailsData: {},
  techContactData: {},
  siteContactData: {},
  balance: 0,
};

// const DialogTitle = (props) => {
//   const { children, onClose } = props;
//   const classes = useStyles();

//   return (
//     <MuiDialogTitle disableTypography className={classes.root}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// };

function AccountSummary() {
  const classes = useStyles();
  const [data, setData] = useState(emptyData);
  const [loading, setLoading] = useState(true);
  // const [status, setStatus] = useState("");
  // const [surveyActive, setSurveyActive] = useState(false);
  // const [dialogOpen, setDialogOpen] = useState(false);
  const company = useSelector((state) => state.companyInfo);
  const accountInfo = useSelector((state) => state.accountInfo);
  let _isMounted = useRef(false);
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;

      const loadData = () => {
        const authToken = localStorage.getItem("token");
        const hostname = window.location.hostname;

        return axiosWrapper(
          hostname,
          "POST",
          "/profile/details",
          {
            Authorization: `Bearer ${authToken}`,
          },
          {
            accountNo: accountInfo.activeBillingAccount,
          }
        );
      };
      // if ( /* TODO user is not admin, get their billing account number and set it to redux*/ )
      setLoading(true); // Only set state if component is mounted
      loadData()
        .then(async (res) => {
          setLoading(false);
          // Handle server response based on res.data.status
          if (res && res.data && res.data.status) {
            const data = res.data.data;
            if (data.detailsData) {
              dispatch(setVoice(data.detailsData.hasVoice));
              dispatch(setCustomer({ detailsData: data.detailsData, techContactData: data.techContactData, siteContactData: data.siteContactData }));
              dispatch(setBalance(data.balance));
            }
            setData({ ...data });
          } else if (res && res.data && !res.data.status) {
            handleServerStatus(res.data.msg);
          }
        })
        .catch((err) => {
          setLoading(false);
          catchAxios(err);
        });

      // Cleanup function that runs when component unmounts
      return () => {
        _isMounted.current = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo.activeBillingAccount, company]);

  // const model = new Survey.Model(Data); // this loads the json structure for the survey

  // Send survey data to the database
  // const setSurveyData = async (data, options) => {
  //   const authToken = localStorage.getItem('token');

  //   return axios({
  //     method: "post",
  //     url: API_HOST + "/users/survey",
  //     headers: {
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //     data: {
  //       survey: data,
  //     },
  //     withCredentials: true,
  //   })
  //     .then((res) => {
  //       const data = res.data;
  //       setStatus(data.msg);
  //       setDialogOpen(true);
  //       setSurveyActive(false);
  //     })
  //     .catch((err) => {
  //       catchAxios(err);
  //     });
  // };

  // run on survey complete
  // const onComplete = (survey, options) => {
  //   setDialogOpen(false);
  //   return setSurveyData(survey.data, options);
  // };

  // const handleOpen = () => {
  //   setDialogOpen(true);
  //   setSurveyActive(true);
  // };

  // const handleClose = () => {
  //   setDialogOpen(false);
  //   setTimeout(() => {
  //     setSurveyActive(false);
  //     setStatus("");
  //   }, 200);
  // };

  return (
    <div>
      <LoaderPage />
      <Typography variant="h4" className={classes.myAccountWelcome}>
        Hi{" "}
        <span style={{ fontWeight: 500 }}>
          {getSessionStorage("session.name")}
        </span>
        , welcome to your {company.company} billing portal.
      </Typography>
      <SectionTitle variant="h1" title="Your Account" />
      <Grid
        container
        spacing={1}
        style={
          breakpoint ? { maxWidth: "100%" } : { maxWidth: "calc(100% - 5%)" }
        }
      >
        <AnimateGrid item xs={12} sm={12} md={7}>
          <ErrorBoundary>
            <AccountDetails loading={loading} data={data} refresh={useEffect} />
          </ErrorBoundary>
        </AnimateGrid>
        <AnimateGrid item xs={12} sm={12} md={5} lg={5}>
          <ErrorBoundary>
            <CurrentBalance
              loading={loading}
              balance={parseFloat(data.balance).toFixed(2)}
            />
          </ErrorBoundary>
        </AnimateGrid>
        <AnimateGrid item xs={12} sm={12} md={12} lg={12}>
          <ErrorBoundary>
            {loading ? (
              <InvoiceTable loading={true} data={[]} />
            ) : (
              <InvoiceDataContainer
                loading={loading}
                limit={5}
                tableTitle="YOUR LATEST 5 INVOICES"
              />
            )}
          </ErrorBoundary>
        </AnimateGrid>
      </Grid>
    </div>
  );
}

export default AccountSummary;
