import React, { useEffect, useMemo, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { catchAxios, decodeToken } from "../utils/utils.js";
import Loader from "../Loader.jsx";
import Loading from "../Loading.jsx";
import { FadeIn, enterComponent } from "../animated.js";
//Material Ui Components
import {
  Grid,
  Paper,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { withTheme } from "@material-ui/core";
import SectionTitle from "../SectionTitle.jsx";

/**
 *  This Component should only render if the user logged in has Admin status, or if the user has multiple billing accounts. It will render a search bar where users can filter results.  The search filter will filter both the Organization Name as well as the Account number. A user can select the account they wish to log in with and will be taken to the portal landing page after selecting an account.
 *  @module MultipleAccounts
 */
// SASS Styles
import LoaderPage from "../LoaderPage.jsx";
import style from "../styles/MainLayout.module.scss";
import { FaSpinner } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyByBusinessUnit } from "../../Redux/Company/companyActions.js";
import { setActiveBillingAccount } from "../../Redux/AccountInfo/accountInfoActions.js";

const SNI_DOMAINS = process.env.REACT_APP_STEPHOUSE_HOSTNAME_ALLOW;

const WNI_HOST = process.env.REACT_APP_API_HOST;
const SNI_HOST = process.env.REACT_APP_API_SNI_HOST;

const FadeTableRow = FadeIn("div");
const AnimatePaper = enterComponent(Paper);

const MultipleAccounts = (props) => {
  const token = decodeToken(localStorage.getItem("token"));
  const isAdmin = token && token.user && token.user.isAdmin ? true : false;
  const classes = {
    titleRow: {
      backgroundColor: props.theme.palette.primary.main,
      color: props.theme.palette.common.white,
      padding: "8px 0",
      position: "sticky",
      top: 0,
    },
  };
  const [loading, setLoading] = useState(false);
  const [billingAccountNr, setBillingAccountNr] = useState(0);
  const [filteredField, setFilteredField] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [businessUnit, setBusinessUnit] = useState('');
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
  const [labelWidth, setLabelWidth] = useState(0);
  const [errMsg, setErrMsg] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    items: [],
    currentPage: 0,
    hasMore: false,
  });
  const scrollMessage = useMemo(() => loading ? 'Retrieving Accounts...' : 'No more accounts matching your criteria.', [loading]);
  const businessUnitRef = useRef(null);
  const timeIntervalFilter = useRef(null); // Store Interval ID
  const accountInfo = useSelector((state) => state.accountInfo);
  const companyInfo = useSelector((state) => state.companyInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    const recaptcha = document.querySelectorAll(".grecaptcha-badge");
    if (recaptcha[0]) {
      recaptcha[0].classList.add("displayNone");
    }
  });

  useEffect(() => {
    setLabelWidth(businessUnitRef.current ? businessUnitRef.current.offsetWidth : 0);
    timeIntervalFilter.current = setTimeout(async () => {
      await getBusinessAccountItems(false);
    }, 100);
  }, [businessUnit]);

  useEffect(() => {
    timeIntervalFilter.current = setTimeout(async () => {
      await getBusinessAccountItems(false);
    }, 800);
  }, [filteredField]);

  useEffect(() => {
    const token = decodeToken(localStorage.getItem("token"));
    const isAdmin = token && token.user && token.user.isAdmin ? true : false;

    /**
     * Check if an activeBillingAccount number already exists in redux
     * An account number in redux means the customer clicked on switch account link
     * Allows back button navigation without side effects
     */
    if (!accountInfo.activeBillingAccount) {
      // reset the billing account in redux.
      dispatch(setActiveBillingAccount('0'));
    }

    // disable submit until a user selects a org to proceed.
    setIsSubmitting(billingAccountNr === 0);

    // Initialize business unit value for dropdown if user is admin and businessUnit exists
    if (isAdmin && accountInfo.businessUnit) {
      setBusinessUnit(accountInfo.businessUnit);
    } else {
      getBusinessAccountItems(); // Fallback if no businessUnit
    }
  }, []);

  const stopTimeIntervalFilter = () => {
    if (timeIntervalFilter.current) {
      clearTimeout(timeIntervalFilter.current);
      clearInterval(timeIntervalFilter.current);
      timeIntervalFilter.current = null;
    }
  };

  const getBusinessAccountItems = async (includePreviousData = true) => {
    const authToken = localStorage.getItem("token");
    const limitItems = 30;

    if (authToken && !loading) {
      setLoading(true);
      const hostname = window.location.hostname;
      const urlHost = SNI_DOMAINS.includes(hostname) ? SNI_HOST : WNI_HOST;
      axios.post(
        `${urlHost}/profile/business`,
        {
          offset: pagination.currentPage,
          limit: limitItems,
          filterQuery: filteredField,
          businessUnit: businessUnit,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          withCredentials: true,
        }
      ).then((res) => {
        if (res.data.status) {
          if (res.data.data.length === 1 && res.data.total === 1) {
            window.location.href = '/#/business/my-account';
            return;
          }
          const totalItems = includePreviousData ? pagination.items.concat(
            Array.from(res.data.data)
          ) : Array.from(res.data.data);
          setPagination((prevState) => ({
            ...prevState,
            total: res.data.total,
            items: totalItems,
            hasMore: res.data.total > totalItems.length,
            currentPage: limitItems + prevState.currentPage,
          }));
        } else {
          setErrMsg(`Something went wrong ${
            res.data.data ? ": " + res.data.data.msg : ""
          }. Please log in and try again.`);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        catchAxios(err);
      });
    }
  };

  const handleChange = (event) => {
    // if the event is from the select element filter based on business unit (the ALL option must return an empty string to the server)
    // if the event comes from a radio button set the business unit for the selected org in the local state.
    // alternatively if the event is from the textfield filter the list of results.
    if (event.target.name === "billingAccountNr") {
      setBillingAccountNr(event.target.value);
      setIsSubmitting(false);
    } else if (event.target.name === "filteredField") {
      stopTimeIntervalFilter();
      setIsSubmitting(true);
      setLoading(false);
      setBillingAccountNr(0);
      setPagination((prevState) => ({
        ...prevState,
        items: [],
        currentPage: 0,
      }));
      setFilteredField(event.target.value);
    } else if (event.target.name === "businessUnit") {
      stopTimeIntervalFilter();
      setBillingAccountNr(0);
      setIsSubmitting(true);
      setLoading(false);
      setPagination((prevState) => ({
        ...prevState,
        items: [],
        currentPage: 0,
      }));
      setBusinessUnit(event.target.value);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setIsSubmitting(true);
    const authToken = localStorage.getItem("token");
    const token = decodeToken(authToken);
    const multiAccount =
      token && token.user && token.user.multiAccount ? true : false;

    // Account number can only ever change for a multi account user
    if (multiAccount) {
      // Set business unit before setting active billing account number
      dispatch(setCompanyByBusinessUnit(selectedBusinessUnit));
      const hostname = window.location.hostname;
      const urlHost = SNI_DOMAINS.includes(hostname) ? SNI_HOST : WNI_HOST;
      axios
        .post(
          `${urlHost}/auth/switch-account`,
          { accountNo: billingAccountNr },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            withCredentials: true,
          }
        )
        .then(async (res) => {
          setLoading(false);
          setIsSubmitting(false);
          if (res.data.status) {
            dispatch(setActiveBillingAccount(res.data.data.billingaccountno));
            setRedirect(true);
          } else {
            setErrMsg(res.data.msg);
          }
        })
        .catch((err) => {
          catchAxios(err);
        });
    } else {
      setIsSubmitting(false);
      setLoading(false);
      setRedirect(false);
    }
  };

  const clearErrMsg = () => {
    setErrMsg('');
  };

  if (redirect) return <Redirect to="/business/my-account" />;

  return (
    <>
      <LoaderPage />
      <SectionTitle
        variant="h1"
        title="Select Billing Account"
        className={style.welcome}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={0}
        style={{ marginTop: "1.5vh" }}
      >
        {errMsg === "" ? (
          <AnimatePaper square={true} className={style.tableSize}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={0}
              style={{ margin: "1vh auto" }}
            >
              <Grid item xs={11} md={8} className={style.searchContainer}>
                {/* Filter Results */}
                <TextField
                  id="filter"
                  label="Filter Results"
                  value={filteredField}
                  name="filteredField"
                  type="filter"
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  className={`${style.searchField} ${
                    isAdmin ? style.admin : style.multi
                  }`}
                  fullWidth
                />
                {isAdmin && (
                  <FormControl
                    variant="outlined"
                    className={style.selectField}
                  >
                    <InputLabel
                      ref={businessUnitRef}
                      htmlFor="business-unit"
                    >
                      B. Unit
                    </InputLabel>
                    <Select
                      value={businessUnit}
                      name="businessUnit"
                      onChange={handleChange}
                      className={style.selectField}
                      input={
                        <OutlinedInput
                          labelWidth={labelWidth}
                          name="B. Unit"
                          id="business-unit"
                        />
                      }
                    >
                      <MenuItem value="WNI">WiLine</MenuItem>
                      <MenuItem value="SNI">Stephouse</MenuItem>
                      <MenuItem value="ALL">All</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <fieldset>
                  <form
                    onSubmit={handleSubmit}
                    style={{ margin: "0 auto" }}
                  >
                    <div className={style.formContainer}>
                      <div
                        cellPadding="2"
                        cellSpacing="2"
                        className={`${style.table} ${style.multipleAccountsTable}`}
                        style={{ overflowX: "hidden" }}
                        id="multiAccount"
                      >
                        <div style={classes.titleRow}>
                          <Typography
                            variant="body1"
                            className={`${style.tableCell} ${style.tableBillNo} ${style.centerText}`}
                          >
                            <b>Account</b>
                          </Typography>
                          <Typography
                            variant="body1"
                            align={!isAdmin ? "center" : "inherit"}
                            className={`${style.tableCell} ${
                              style.tableOrgName
                            } ${!isAdmin && style.centerText}`}
                          >
                            <b>Organization Name</b>
                          </Typography>
                          {/* only show company type if user is an admin */}
                          {isAdmin && (
                            <Typography
                              variant="body1"
                              className={`${style.tableCell} ${style.subsidiary}`}
                            >
                              <b>Subsidiary</b>
                            </Typography>
                          )}
                        </div>
                        <InfiniteScroll
                          style={{ overflowY: "overlay" }}
                          dataLength={pagination.items.length}
                          next={getBusinessAccountItems}
                          hasMore={pagination.hasMore}
                          loader={<Loader height="300px" />}
                          endMessage={
                            <Typography
                              variant="h5"
                              color="primary"
                              style={{ padding: "8px" }}
                            >
                              {scrollMessage}{" "}
                              {loading && (
                                <FaSpinner
                                  style={{
                                    color: companyInfo.color,
                                  }}
                                  className="fa-spin"
                                />
                              )}
                            </Typography>
                          }
                          height={(window.innerHeight * 42) / 100}
                        >
                          {pagination.items.length > 0 &&
                            pagination.items.map((org, i) => (
                              <FadeTableRow
                                className={`${style.tableRow}
                                  ${
                                    billingAccountNr === org.billingaccountno.toString()
                                      ? style.active
                                      : null
                                  }`}
                                key={`${org.orgid}-${i}`}
                                delay={`${parseInt(i) * 0.5}ms`}
                                onClick={() => {
                                  setBillingAccountNr(org.billingaccountno);
                                  setSelectedBusinessUnit(org.business_unit);
                                  setIsSubmitting(false);
                                }}
                              >
                                <div className={`${style.tableBillNo}`}>
                                  <RadioGroup aria-label="Account Nr.">
                                    <FormControlLabel
                                      value={org.billingaccountno}
                                      control={
                                        <Radio
                                          checked={billingAccountNr === org.billingaccountno.toString()}
                                          onChange={handleChange}
                                          color="primary"
                                          value={org.billingaccountno}
                                          name="billingAccountNr"
                                          aria-label={org.org_name}
                                          label={org.billingaccountno}
                                        />
                                      }
                                      label={org.billingaccountno}
                                    />
                                  </RadioGroup>
                                </div>
                                <Typography
                                  variant="body1"
                                  className={`${style.tableOrgName}`}
                                >
                                  {org.org_name}
                                </Typography>
                                {/* only show if user is an admin */}
                                {isAdmin && (
                                  <Typography
                                    variant="body1"
                                    className={`${style.subsidiary}`}
                                  >
                                    {org.business_unit}
                                  </Typography>
                                )}
                              </FadeTableRow>
                            ))}
                        </InfiniteScroll>
                        {/*</tbody>*/}
                      </div>
                    </div>
                    <div className={style.buttonContainer}>
                      <Button
                        variant="contained"
                        style={{ width: "100%" }}
                        disabled={isSubmitting || billingAccountNr === 0}
                        onClick={() => {
                          setBillingAccountNr(0);
                          setIsSubmitting(true);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || billingAccountNr === 0}
                        onClick={handleSubmit}
                        style={{ width: "100%" }}
                      >
                        <Loading
                          submitText="submit"
                          loadingText="loading"
                          loading={loading}
                        />
                      </Button>
                    </div>
                  </form>
                </fieldset>
              </Grid>
            </Grid>
          </AnimatePaper>
        ) : (
          <AnimatePaper square={true} className={style.tableSize}>
            <Typography
              variant="h5"
              color="primary"
              style={{ padding: "8px" }}
            >
              {errMsg}
              <Button
                variant="outline-secondary"
                className={style.button}
                onClick={clearErrMsg}
                style={{
                  float: "right",
                  border: "1px solid #bbb",
                  padding: "0",
                }}
              >
                Close
              </Button>
            </Typography>
          </AnimatePaper>
        )}
      </Grid>
    </>
  );
};

export default withTheme(MultipleAccounts);
