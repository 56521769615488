import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { setSelectedLocation } from "../../Redux/CustomerInfo/customerInfoActions";
import "./AvailableServices.scss";
import OrderFunction from "../../Functions/Order";
import { FaSpinner } from "react-icons/fa";

function AvailableServices({ showError }) {
  const params = useParams();
  const haveStep = "step" in params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loadingServices, setLoadingServices] = useState(true);
  const [usedServices, setUsedServices] = useState([]);

  const selectedLocation = useSelector(
    (state) => state.customerInfo.selectedLocation
  );

  const changeRouter = () => {
    if (!haveStep) {
      const newParams = new URLSearchParams(location.search);
      newParams.set("step", "Available service");
      history.push({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    }
  };
  useEffect(() => {
    changeRouter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServices = () => {
    setLoadingServices(true);
    OrderFunction.getCurrentServices()
      .then((response) => {
        if (response.status) {
          setUsedServices(response.data.data);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingServices(false);
      });
  };

  const handleCardClick = (service) => {
    dispatch(setSelectedLocation("serviceId", service.id));
    dispatch(setSelectedLocation("serviceName", service.address));
    dispatch(setSelectedLocation("serviceMB", service.service));
    dispatch(setSelectedLocation("servicePrice", service.price));
    dispatch(setSelectedLocation("serviceSpeed", service.speed));
  };

  return (
    <Container className="container">
      {showError && !selectedLocation.serviceId && (
        <Typography
          variant="body2"
          className="error-text"
          color="error"
          align="center"
        >
          Please select a service location!
        </Typography>
      )}
      {!loadingServices && Object.keys(usedServices).length < 1 && (
        <Typography
          variant="body2"
          className="error-text"
          color="error"
          align="center"
        >
          Currently no services linked to this account.
        </Typography>
      )}
      <Grid container spacing={4} justifyContent="center">
        {loadingServices ? (
          <>
            <FaSpinner className="loader" />
          </>
        ) : (
          <>
            {usedServices.map((service) => (
              <Grid item key={service.id} xs={12} sm={6} md={4} lg={3}>
                <Card
                  className={`card ${
                    selectedLocation.serviceId === service.id ? "selected" : ""
                  } shadow`}
                  onClick={() => handleCardClick(service)}
                >
                  <CardContent className="text-center">
                    <Typography variant="h5" component="h3" gutterBottom>
                      {service.city}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {service.address}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {service.service}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {service.price}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      color={
                        selectedLocation.serviceId === service.id
                          ? "secondary"
                          : "primary"
                      }
                      onClick={() => handleCardClick(service)}
                    >
                      {selectedLocation.serviceId === service.id
                        ? "Selected"
                        : "Select"}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Container>
  );
}

export default AvailableServices;
