import React, {Component} from 'react';
import SectionTitle from '../SectionTitle.jsx';
import style from '../styles/AccountSummary.module.scss';
import {withStyles, Paper} from '@material-ui/core'
import SocketIO from "../SocketIO";
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoaderPage from "../LoaderPage";
import { FaApple, FaChrome, FaDesktop, FaEdge, FaFirefox, FaInternetExplorer, FaLinux, FaOpera, FaSafari, FaUserShield, FaWindows } from 'react-icons/fa';
import { IoBrowsers } from 'react-icons/io5';

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

const styles = theme => ({
    root: {
        width: '80%',
        display: 'block',
        margin: '0 auto',
    },
    paper: {
        root: {
            padding: '0 10px'
        }
    },
    titleRow: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '8px 0',
        position: 'sticky',
        top: 0,
        overflowX: 'scroll'
    },
    mySession: {
        backgroundColor: '#DEE9FF80',
    },
    tableCell: {
        padding: '8px',
        textAlign: 'left'
    },
    centerCell: {
        textAlign: 'center',
    },
    table: {
        width: '100%'
    },
    oddRow: {
        backgroundColor: '#F9F9F9'
    },
    container: {
        width: document.documentElement.clientWidth > 959 ? '75vw' : '92vw',
        margin: '5vh auto',
        overflowX: 'auto',
    },
    adminIcon: {
        margin: '0 5px',
        color: theme.palette.primary.main
    }
});

class LiveUsers extends Component {
    constructor(props) {
        super(props);

        this.socketIo = false;
        this.socketListening = false;

        this.state = {
            usersLoggedIn: [],
            expanded: false
        }
    }

    getSocketIo() {
        return this.socketIo = SocketIO('unique_connection');
    }

    getUsersLogged() {
        this.getSocketIo();
        if (typeof this.socketIo === 'object' && this.socketIo) {
            this.socketIo.emit('getUsersLogged', (data) => {
                const usersLoggedIn = [];
                if (typeof data !== 'undefined') {
                    for (let id in data) {
                        let temp = [];
                        for (let socketId in data[id]) {
                            temp.push(data[id][socketId]);
                        }
                        usersLoggedIn.push({
                            ...temp[0],
                            children: temp
                        });
                    }
                }
                this.setState(() => ({
                    usersLoggedIn: usersLoggedIn
                }));
            });
        }
    }

    componentDidMount() {
        const setIntervalSocket = setInterval(() => {
            this.getSocketIo();
            if (typeof this.socketIo === 'object' && this.socketIo && !this.socketListening) {
                this.getUsersLogged();
                this.socketIo.on('loadUserLogged', () => {
                    this.socketListening = true;
                    this.getUsersLogged();
                });
                this.socketIo.emit('userAction', {current_url: window.location.href});
                clearInterval(setIntervalSocket);
            }
        }, 500);
    }

    sendForceUserLogout(socktId) {
        SocketIO('emit', 'adminDisconnectUser', socktId);
    }

    // Gets the icon for the user browser
    static getBrowserIcon(browser) {
        switch (browser.name.toLowerCase()) {
            case 'chrome':
                return <FaChrome className={this.props.classes} />;
            case 'firefox':
                return <FaFirefox className={this.props.classes} />;
            case 'internet-explorer':
                return <FaInternetExplorer className={this.props.classes} />;
            case 'edge':
                return <FaEdge className={this.props.classes} />;
            case 'safari':
                return <FaSafari className={this.props.classes} />;
            case 'opera':
                return <FaOpera className={this.props.classes} />;
            default:
                return <IoBrowsers className={this.props.classes} />;
        }
    }

    // gets the icon for the Operating System
    static getOsIcon(os) {
        switch (os.name.toLowerCase()) {
            //  TODO: This needs to be checked for the OS names, I am not sure if the names used are these. Windows and Mac Os are working.
            case ('linux'):
                return <FaLinux className={this.props.classes} />;
            case ('windows'):
                return <FaWindows className={this.props.classes} />;
            case('mac os'):
                return <FaApple className={this.props.classes} />;
            default:
                return <FaDesktop className={this.props.classes} />;
        }
    }

    render() {
        const {classes} = this.props;

        const handleChange = panel => (event, isExpanded) => {
            this.setState({expanded: isExpanded ? panel : false});
        };

        return (
            <>
                <LoaderPage/>
                <SectionTitle variant="h1" title="Live Users" style={style.welcome}>Live Users</SectionTitle>
                <div className={classes.root}
                     style={{width: '80%', maxWidth: '1200px', margin: '20px auto 0 auto', display: 'block'}}>
                    <Paper className={classes.root}>
                        <Typography component="h2" align="center">
                            Users Logged In
                        </Typography>
                        {this.state.usersLoggedIn.map((user, i) => {
                            if (!user) {
                                return null
                            }

                            return (
                                <ExpansionPanel key={`${i}-user`} expanded={this.state.expanded === `panel${i}`}
                                                onChange={handleChange(`panel${i}`)}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                        <FaUserShield className={classes.adminIcon} style={{marginTop: '4px', marginRight: '10px'}}/>
                                        <Typography className={classes.heading}>{user.name} - (<span
                                            style={{'fontSize': '0.9em', 'textTransform': 'italic'}}>{user.email}</span>)</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <table cellSpacing="0" className={classes.table}>
                                            <thead>
                                            <tr className={classes.titleRow}>
                                                <th className={`${classes.tableCell} ${classes.centerCell}`}
                                                    style={{minWidth: '50px'}}>Platform
                                                </th>
                                                <th className={`${classes.tableCell} ${classes.centerCell}`}
                                                    style={{minWidth: '280px'}}>Current Location
                                                </th>
                                                <th className={`${classes.tableCell} ${classes.centerCell}`}
                                                    style={{minWidth: '60px'}}>B. Unit
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {user.children.map((data, j) => (
                                                <tr key={`${j}-user-child`}
                                                    className={(data.socket_id === SocketIO().id) && classes.mySession}>
                                                    <td className={classes.tableCell}>
                                                        <div>
                                                            {LiveUsers.getBrowserIcon(data.browser)}
                                                            {LiveUsers.getOsIcon(data.os)}
                                                        </div>
                                                    </td>
                                                    <td className={classes.tableCell}>{data.current_url}</td>
                                                    <td className={classes.tableCell}
                                                        style={{textAlign: 'center'}}>
                                                        {(data.user_business_unit === 'ALL' ? 'Admin' : (user.user_business_unit === 'SNI' ? 'Stephouse' : (data.user_business_unit === 'WNI' ? 'WiLine' : null)))}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>

                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        })}
                    </Paper>
                </div>
            </>
        )
    }
}

export default withStyles(styles)(LiveUsers);
