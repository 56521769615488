import React, { useEffect, useState } from "react";
import { catchAxios } from "../utils/utils";
import { axiosWrapper } from "../../utils/axiosWrapper";

const UsersWrapper = (props) => {
  const { children } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const loadData = async () => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;

      const response = await axiosWrapper(hostname, "GET", "/user-management", {
        Authorization: `Bearer ${authToken}`,
      });
      const { data } = response;
      setData(data.data);
    } catch (err) {
      setError("An error occured while processing your request");
      catchAxios(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {string} email
   */
  const handleAddUser = async (email) => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;

      const response = await axiosWrapper(
        hostname,
        "POST",
        "/user-management/new-user",
        {
          Authorization: `Bearer ${authToken}`,
        },
        { email }
      );
      setSuccess(response.data.msg);
      await loadData();
    } catch (err) {
      setError("An error occured while processing your request");
      setLoading(false);
    }
  };

  /**
   * @param {string} email
   * @param {string} uid
   */
  const handleApproveUser = async (email, uid) => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;

      const response = await axiosWrapper(
        hostname,
        "PUT",
        "/user-management/approve-user/" + uid,
        {
          Authorization: `Bearer ${authToken}`,
        },
        { email }
      );
      setSuccess(response.data.msg);
      await loadData();
    } catch (err) {
      setError("An error occured while processing your request");
      setLoading(false);
    }
  };

  /**
   * @param {string} email
   */
  const handleDeleteUser = async (email) => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;

      const response = await axiosWrapper(
        hostname,
        "POST",
        "/user-management/delete-user",
        {
          Authorization: `Bearer ${authToken}`,
        },
        { email }
      );
      setSuccess(response.data.msg);
      await loadData();
    } catch (err) {
      setError("An error occured while processing your request");
      setLoading(false);
    }
  };

  /**
   * @param {string} email
   * @param {string} uid
   */
  const handleDenyUser = async (email, uid) => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;

      const response = await axiosWrapper(
        hostname,
        "PUT",
        "/user-management/deny-user/" + uid,
        {
          Authorization: `Bearer ${authToken}`,
        },
        { email }
      );
      setSuccess(response.data.msg);
      await loadData();
    } catch (err) {
      setError("An error occured while processing your request");
      setLoading(false);
    }
  };

  const handleClearError = () => {
    setError("");
  };

  const handleClearSuccess = () => {
    setSuccess("");
  };

  return (
    <div>
      {children({
        data,
        loading,
        error,
        success,
        handleAddUser,
        handleDeleteUser,
        handleApproveUser,
        handleDenyUser,
        handleClearError,
        handleClearSuccess,
      })}
    </div>
  );
};

export default UsersWrapper;
