import React, { useEffect, useState } from "react";
import { catchAxios } from "../utils/utils";
import { axiosWrapper } from "../../utils/axiosWrapper";

const FetchPaymentFeeExempt = (props) => {
  const { children } = props;

  const [data, setData] = useState({ feeExempt: false });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const authToken = localStorage.getItem("token");
        const hostname = window.location.hostname;
        const response = await axiosWrapper(
          hostname,
          "GET",
          "/payments/fee-exempt",
          {
            Authorization: `Bearer ${authToken}`,
          },
          undefined,
          true
        );
        const { data } = response;
        setData(data);
      } catch (err) {
        setError(err);
        catchAxios(err);
      } finally {
        setLoading(false);
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children({
    feeExempt: data.feeExempt,
    feeExemptLoading: loading,
    feeExemptError: error,
  });
};

export default FetchPaymentFeeExempt;
