import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import styles from "./AddUserConfirmModal.module.scss";

/**
 * @param {{
 *  email: string,
 *  open: boolean,
 *  loading: boolean,
 *  onClose: () => void,
 *  onSubmit: () => Promise<void>
 * }} props
 * @returns
 */
function ApproveUserConfirmModal(props) {
  const { email, open, loading, onClose, onSubmit } = props;

  const closeModal = () => {
    onClose();
  };

  const submitModal = async () => {
    await onSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="comment-dialog-title"
      aria-describedby="comment-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      className={styles.modal}
    >
      <DialogTitle id="alert-dialog-title" className={styles.title}>
        Confirm approving new user
      </DialogTitle>
      <DialogContent>
        <div>
          Approve email will be sent to <b>{email}</b>. Are you sure?
        </div>
        <div>
          The following user will only be able to view the <b>Help</b> and{" "}
          <b>My Tickets</b> sections and will have access only to their tickets.
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={closeModal} variant="contained">
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={submitModal}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ApproveUserConfirmModal;
