import { SET_COMPANY_BY_BUSINESS_UNIT } from "./companyType";

/**
 * @param { 'WNI' | 'SNI' | 'ALL' | 'wni' | 'sni' | null } value 
 * @returns 
 */
export const setCompanyByBusinessUnit = (value) => ({
  type: SET_COMPANY_BY_BUSINESS_UNIT,
  payload: value,
});
