import { DialogTitle, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";

/**
 * @param {{
 *  open: boolean,
 *  action: string,
 *  onClose: () => void
 * }} props 
 * @returns 
 */
function SuccessAlert (props) {
  const { open, action, onClose } = props;

  const closeModal = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="comment-dialog-title"
      aria-describedby="comment-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Success</DialogTitle>
      <DialogContent>
        {action === 'add' && <div>Registration request has been sent successfully.</div>}
        {action === 'approve' && <div>Registration request has been approved successfully.</div>}
        {action === 'deny' && <div>Registration request has been denied successfully.</div>}
        {action === 'delete' && <div>User has been deleted successfully.</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SuccessAlert;
