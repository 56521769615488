import React, { Component } from "react";
import { Grid, Paper } from "@material-ui/core";
import { FadeIn } from "../animated.js";
import style from "../styles/AccountSummary.module.scss";
import DateRangeForm from "./DateRangeForm.jsx";
import SectionTitle from "../SectionTitle.jsx";
import BarChart from "./BarChart.jsx";
import DataSet from "./DataSet.jsx";
import * as apiMethods from "./utils/apiMethods.js";
import { formatDateForStats } from "../utils/utils";
import LoaderPage from "../LoaderPage";

const AnimatedGrid = FadeIn(Grid);
const AnimatedPaper = FadeIn(Paper);

const SNI_DOMAINS = process.env.REACT_APP_STEPHOUSE_HOSTNAME_ALLOW;

const WNI_HOST = process.env.REACT_APP_API_HOST;
const SNI_HOST = process.env.REACT_APP_API_SNI_HOST;

export const data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

/**
 * @param {Object}
 * @module Stats
 */
class Stats extends Component {
  state = {
    errorMessage: "",
    valid: true,
    activeChart: "dn",
    submitting: false,
    dateFrom: "",
    dateTo: "",
    logins: [],
    unique: [{ label: "no data", value: 1 }],
    payments: [{ label: "no data", value: 1 }],
    // state management for payment drill down and high level details
    paymentsHighLevel: [{ label: "no data", value: 1 }],
    paymentsDetails: [],
    paymentDrillDown: [],
    // state management for login details EndlessScroll table
    loginDetails: [],
    loginDetailsLimit: 20,
    loginDetailsOffset: 0,
    loginDetailsHasMore: true,
    loginDetailsLoader: false,
    loginDetailsBU: "",
    // state management for unique login data EndlessScroll table
    uniqueUsersData: [],
    uniqueUsersLimit: 20,
    uniqueUsersOffset: 0,
    uniqueUsersHasMore: true,
    uniqueUsersLoader: false,
    uniqueUsersBU: "",
    // state management for registration data
    registrationData: [{ label: "no data", value: 1 }],
    registrationsDetails: [],
    registrationsLimit: 20,
    registrationsOffset: 0,
    registrationsHasMore: true,
    registrationsLoader: false,
    registrationsBU: "",
  };

  setChart = (type) => {
    this.setState({ activeChart: type });
  };

  getData = (dateFrom, dateTo) => {
    if (!dateFrom || !dateTo) {
      return;
    }
    const hostname = window.location.hostname;
    const urlHost = SNI_DOMAINS.includes(hostname) ? SNI_HOST : WNI_HOST;
    this.setState({ submitting: true });
    let unique = apiMethods.getInitialData(
      dateFrom,
      dateTo,
      `${urlHost}/stats/logins`
    );
    let logins = apiMethods.getInitialData(
      dateFrom,
      dateTo,
      `${urlHost}/stats/unique-login`
    );
    let payments = apiMethods.getInitialData(
      dateFrom,
      dateTo,
      `${urlHost}/stats/payments`
    );
    let registrations = apiMethods.getInitialData(
      dateFrom,
      dateTo,
      `${urlHost}/stats/registration`
    );

    Promise.all([unique, logins, payments, registrations]).then((values) => {
      this.handleLogins(values[0]);
      this.handleUnique(values[1]);
      this.handlePayments(values[2]);
      this.handleRegistrations(values[3]);

      this.setState({
        submitting: false,
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
    });
  };

  // Submit form
  submit = async (dateFrom, dateTo) => {
    // Format date values to YYYY-MM-DD
    const dateFromFormated = formatDateForStats(dateFrom);
    const dateToFormated = formatDateForStats(dateTo);

    this.getData(dateFromFormated, dateToFormated);
    this.setState({
      registrationsBU: "",
      uniqueUsersBU: "",
      loginDetailsBU: "",
    });
  };

  // API call methods //
  handleLogins = async (response) => {
    if (response && response.data && response.data.status) {
      this.setState({
        logins: response.data.data,
      });
    } else if (response.data.msg) {
      this.setState({
        errorMessage: response.msg,
      });
    }
  };

  handleRegistrations = async (response) => {
    if (
      response &&
      response.data &&
      response.data.status &&
      response.data.data.length >= 1
    ) {
      this.setState({
        registrationData: response.data.data,
      });
    } else if (!response.data.status) {
      this.setState({
        errorMessage: response.data.msg,
      });
    } else {
      this.setState({ registrationData: [{ label: "no data", value: 1 }] });
    }
  };

  getLoginDetails = async (businessUnit) => {
    // Reset if you are now asking for a different business unit
    if (businessUnit !== this.state.loginDetailsBU) {
      await this.setState({
        // Reset for new drill down
        loginDetails: [],
        loginDetailsOffset: 0,
      });
    }

    let { dateFrom, dateTo, loginDetailsLimit, loginDetailsOffset } =
      this.state;
    this.setState({ submitting: true });
    const hostname = window.location.hostname;
    const urlHost = SNI_DOMAINS.includes(hostname) ? SNI_HOST : WNI_HOST;
    const response = await apiMethods.getDetails(
      dateFrom,
      dateTo,
      loginDetailsLimit,
      loginDetailsOffset,
      businessUnit,
      `${urlHost}/stats/login-details`
    );
    this.setState({ submitting: false });

    this.setState({
      loginDetailLoader: true,
      // Set current business unit for drill down
      loginDetailsBU: businessUnit,
    });

    if (response && response.data && response.data.status) {
      this.setState({
        loginDetails: [...this.state.loginDetails, ...response.data.data],
        loginDetailsHasMore: response.data.data.length >= loginDetailsLimit,
        loginDetailsOffset: loginDetailsOffset + this.state.loginDetailsLimit,
        loginDetailsLoader: false,
      });
    } else if (response.data.msg) {
      this.setState({
        errorMessage: response.msg,
        loginDetailsHasMore: false,
      });
    }
  };

  getRegistrationsDetails = async (businessUnit) => {
    // Reset if you are now asking for a different business unit
    if (businessUnit !== this.state.registrationsBU) {
      await this.setState({
        // Reset for new drill down
        registrationsDetails: [],
        registrationsOffset: 0,
      });
    }
    let { dateFrom, dateTo, registrationsLimit, registrationsOffset } =
      this.state;
    this.setState({ submitting: true });
    const hostname = window.location.hostname;
    const urlHost = SNI_DOMAINS.includes(hostname) ? SNI_HOST : WNI_HOST;
    const response = await apiMethods.getDetails(
      dateFrom,
      dateTo,
      registrationsLimit,
      registrationsOffset,
      businessUnit,
      `${urlHost}/stats/registrations-details`
    );
    this.setState({ submitting: false });

    this.setState({
      registrationsLoader: true,
      // Set current business unit for drill down
      registrationsBU: businessUnit,
    });
    if (response && response.data && response.data.status) {
      this.setState({
        registrationsDetails: [
          ...this.state.registrationsDetails,
          ...response.data.data,
        ],
        registrationsHasMore: response.data.data.length >= registrationsLimit,
        registrationsOffset:
          registrationsOffset + this.state.registrationsLimit,
        registrationsLoader: false,
      });
    } else {
      this.setState({
        errorMessage: response.msg,
        registrationsHasMore: false,
        registrationsLoader: false,
      });
    }
  };

  handleUnique = async (response) => {
    if (response && response.data && response.data.status) {
      this.setState({
        unique: response.data.data,
      });
    } else {
      this.setState({
        errorMessage: response.data.message,
      });
    }
  };

  getUniqueLogDetails = async (businessUnit) => {
    // Reset for new business unit type
    if (businessUnit !== this.state.uniqueUsersBU) {
      await this.setState({
        // Reset for new drill down
        uniqueUsersData: [],
        uniqueUsersOffset: 0,
      });
    }
    let { dateFrom, dateTo, uniqueUsersLimit, uniqueUsersOffset } = this.state;
    this.setState({ submitting: true });
    this.setState({ loginDetailLoader: true });
    const hostname = window.location.hostname;
    const urlHost = SNI_DOMAINS.includes(hostname) ? SNI_HOST : WNI_HOST;
    const response = await apiMethods.getDetails(
      dateFrom,
      dateTo,
      uniqueUsersLimit,
      uniqueUsersOffset,
      businessUnit,
      `${urlHost}/stats/unique-login-details`
    );
    this.setState({ submitting: false });

    this.setState({
      uniqueUsersLoader: true,
      loginDetailLoader: false,
      // Set current business unit for drill down
      uniqueUsersBU: businessUnit,
    });
    if (response.data && response.data.status) {
      this.setState({
        uniqueUsersData: [...this.state.uniqueUsersData, ...response.data.data],
        uniqueUsersHasMore: response.data.data.length >= uniqueUsersLimit,
        uniqueUsersOffset: uniqueUsersOffset + this.state.uniqueUsersLimit,
        uniqueUsersLoader: false,
      });
    } else if (response.data.msg) {
      this.setState({
        errorMessage: response.msg,
        uniqueUsersHasMore: false,
      });
    }
  };

  handlePayments = async (response) => {
    // display an empty by if no data in time range
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data[0].label === "No Data in Time Range"
    ) {
      const emptyData = [{ label: "no data", value: 1 }];
      this.setState({ paymentsHighLevel: emptyData });
    } else {
      // make a data array to show the high level payment success or failure
      const paymentsHighLevel = [
        {
          label: "SUCCESS",
          value: 0,
        },
        {
          label: "FAILURE",
          value: 0,
        },
      ];
      if (response && response.data && response.data.status) {
        response.data.data.forEach((item) => {
          if (item.label.includes("SUCCESS")) {
            paymentsHighLevel[0].value += parseInt(item.value);
          } else if (item.label.includes("FAILURE")) {
            paymentsHighLevel[1].value += parseInt(item.value);
          }
        });
        this.setState({
          payments: response.data.data,
          paymentsHighLevel: paymentsHighLevel,
        });
      }
    }
  };

  togglePaymentDrillDown = async (str) => {
    const details = this.paymentDetails(
      this.state.payments,
      this.state.paymentsHighLevel,
      str
    );

    await this.setState({
      paymentsHighLevel: details,
      paymentsDetails: details,
    });
  };

  paymentDetails = (payments, paymentsHighLevel, str) => {
    // gets data object for drill down doughnut chart
    str = str.includes("SUCCESS") ? "SUCCESS" : "FAILURE";
    if (!this.state.paymentDrillDown.includes(str)) {
      this.setState({
        paymentDrillDown: [...this.state.paymentDrillDown, str],
      });
      const details = payments.filter((item) => {
        return item.label.includes(str);
      });
      const highLevel = paymentsHighLevel.filter((item) => {
        return item.label !== str;
      });
      return [...details, ...highLevel];
    } else {
      return this.state.paymentsDetails;
    }
  };

  async componentDidMount() {
    // Initial dates, does not come from form
    const dateFrom = formatDateForStats(
      new Date(new Date().valueOf() - 86350989)
    ); // Yesterday
    const dateTo = formatDateForStats(new Date());
    this.getData(dateFrom, dateTo);
  }

  render() {
    const doughnutColors = {
      ALL: "#239B56",
      SNI: "#FFAA00",
      WNI: "#074481",
      null: "#969696",
    }; // Data label must match color key
    const paymentColors = {
      "FAILURE SNI": "red",
      "SUCCESS SNI": "#239B56",
      "FAILURE WNI": "red",
      "SUCCESS WNI": "#074481",
      SUCCESS: "green",
      FAILURE: "red",
      "SUCCESS null": "#969696",
      "FAILURE null": "#4a4a4a",
    };

    const inlineStyle = {
      gridItem: {
        maxWidth: "432px",
      },
      container: {
        padding: "5vh",
      },
    };

    return (
      <>
        <LoaderPage />
        <SectionTitle
          variant="h1"
          title="Usage Statistics"
          style={style.welcome}
        />
        <div style={inlineStyle.container}>
          <DateRangeForm
            submit={this.submit}
            submitting={this.state.submitting}
            valid={this.state.valid}
            setChart={this.setChart}
            activeChart={this.state.activeChart}
          />
          <AnimatedPaper style={{ marginTop: "2vh" }}>
            <Grid
              container
              justifyContent="space-evenly"
              align="center"
              spacing={4}
            >
              {/* TOTAL LOGINS DATA  */}

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="pr-2">
                <DataSet
                  activeChart={this.state.activeChart}
                  data={this.state.logins}
                  title="USER LOGINS"
                  colors={doughnutColors}
                  getDetails={this.getLoginDetails}
                  headers={["Email", "Time of Login", "B.Unit"]}
                  rows={this.state.loginDetails.map((d, i) => {
                    return [d.user_name, d.login_time, d.business_unit];
                  })}
                  cellWidth={150}
                  hasMore={this.state.loginDetailsHasMore}
                  next={() => this.getLoginDetails(this.state.loginDetailsBU)}
                  loading={this.state.loginDetailsLoader}
                  showTable={this.state.loginDetailsBU}
                  spacer={false}
                  modalOpen={!!this.state.loginDetailsBU}
                  closeModal={() => this.setState({ loginDetailsBU: "" })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="pr-2">
                <DataSet
                  activeChart={this.state.activeChart}
                  data={this.state.unique}
                  title="UNIQUE LOGINS"
                  colors={doughnutColors}
                  getDetails={this.getUniqueLogDetails}
                  headers={["User Email", "# of Logins", "B.Unit"]}
                  rows={this.state.uniqueUsersData.map((d, i) => {
                    return [d.user_name, d.login_count, d.business_unit];
                  })}
                  cellWidth={200}
                  hasMore={this.state.uniqueUsersHasMore}
                  next={() =>
                    this.getUniqueLogDetails(this.state.uniqueUsersBU)
                  }
                  loading={this.state.uniqueUsersLoader}
                  showTable={this.state.uniqueUsersBU}
                  spacer={false}
                  modalOpen={!!this.state.uniqueUsersBU}
                  closeModal={() => this.setState({ uniqueUsersBU: "" })}
                />
              </Grid>
              {/* registration charts */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="pr-2">
                <DataSet
                  activeChart={this.state.activeChart}
                  data={this.state.registrationData}
                  title="NEW REGISTRATIONS"
                  colors={doughnutColors}
                  getDetails={this.getRegistrationsDetails}
                  headers={["User Email", "Registration Date", "B.Unit"]}
                  rows={this.state.registrationsDetails.map((d, i) => {
                    return [d.user_name, d.log_time, d.business_unit];
                  })}
                  cellWidth={200}
                  hasMore={this.state.registrationsHasMore}
                  next={() =>
                    this.getRegistrationsDetails(this.state.registrationsBU)
                  }
                  loading={this.state.registrationsLoader}
                  showTable={this.state.registrationsBU}
                  spacer={false}
                  modalOpen={!!this.state.registrationsBU}
                  closeModal={() => this.setState({ registrationsBU: "" })}
                />
              </Grid>
              {/* PAYMENTS DATA  */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="pr-2">
                {this.state.activeChart === "dn" && (
                  <DataSet
                    activeChart={this.state.activeChart}
                    data={this.state.paymentsHighLevel}
                    cellWidth={200}
                    title={"PAYMENTS"}
                    spacer={false}
                    colors={paymentColors}
                    getDetails={this.togglePaymentDrillDown}
                  />
                )}
              </Grid>
              <AnimatedGrid item md={6} sm={12} style={inlineStyle.gridItem}>
                {this.state.activeChart === "bar" && (
                  <BarChart
                    data={this.state.paymentsHighLevel}
                    title={"PAYMENTS"}
                    colors={paymentColors}
                    getDetails={this.togglePaymentDrillDown}
                  />
                )}
              </AnimatedGrid>
              {/* END PAYMENTS DATA  */}
            </Grid>
          </AnimatedPaper>
        </div>
      </>
    );
  }
}

export default Stats;
