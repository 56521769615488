import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Container, TextField, Grid, Box, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import './CustomRequest.scss';
import { setCustomRequest } from '../../Redux/CustomerInfo/customerInfoActions';

function CustomRequest({showError}) {
  const customRequest = useSelector((state) => state.customerInfo.customRequest);
  const params = useParams();
  const haveStep = 'step' in params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const changeRouter = () => {
    if (!haveStep) {
      const newParams = new URLSearchParams(location.search);
      newParams.set("step", "Custom Request");
      history.push({ pathname: location.pathname, search: newParams.toString() });
    }
  };

  useEffect(() => {    
    changeRouter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    dispatch(setCustomRequest(event.target.value));
  };
 

  return (
    <Container>
      {showError && !customRequest && (
        <Typography variant="body2" className="error-text" color="error" align="center">
          Please enter description!
        </Typography>
      )}
      <Grid container justifyContent="center" className="py-4">
        <Grid item md={6}>
          <form>
            <Box borderRadius={4} p={4} bgcolor="background.paper" boxShadow={3}>
              <TextField
                fullWidth
                id="upgradeDescription"
                label="Upgrade Description"
                multiline
                rows={5}
                variant="outlined"
                value={customRequest}     
                onChange={handleChange}    
                error={showError && !customRequest}
                helperText={showError && !customRequest ? "Description is required" : ""}       
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={3} className="gap-2">              
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CustomRequest;
