import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import stepTrackerReducer from './StepTracker/stepTrackerReducer';
import customerInfoReducer from './CustomerInfo/customerInfoReducer';
import notificationInfoReducer from './NotificationInfo/notificationInfoReducer';
import { RESET_STATE } from './actionTypes';
import globalReducer from './Global/globalReducer';
import companyReducer from './Company/companyReducer';
import accountInfoReducer from './AccountInfo/accountInfoReducer';

const persistConfig = {
  key: 'core',
  storage,
  // debug: true,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_REDUX_SECRET_KEY || 'my-secret-key',
      onError: (error) => {
        console.error(error);
      },
    }),
  ],
};

const appReducer = combineReducers({
  step: stepTrackerReducer,
  customerInfo: customerInfoReducer,
  notificationInfo: notificationInfoReducer,
  globalInfo: globalReducer,
  companyInfo: companyReducer,
  accountInfo: accountInfoReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
