import React, { useState } from "react";
import { FetchTicketDetails } from "./fetch";
import { useParams } from "react-router-dom";
import { Paper } from "@material-ui/core";
import styles from '../styles/Tickets/TicketDetails.module.scss';
import TicketHistoryChat from "./TicketHistoryChat.jsx";
import TicketHistoryComment from "./TicketHistoryComment.jsx";
import ImageFullScreen from "./ImageFullScreen.jsx";
import jwt from "jsonwebtoken";

const TicketDetails = (props) => {
    const { ticketno } = useParams();
    const [fullImageOpen, setFullImageOpen] = useState(false);
    const [fullImageSrc, setFullImageSrc] = useState('');
    const isAdmin = () => {
        const token = localStorage.getItem("token");
        if (!token) return false;
        const decoded = jwt.decode(token);
        if (!decoded.user) return false;
        return !!(decoded.user.isAdmin);
    };

    /**
     * @param {string} src 
     */
    const fullImageHandler = (src) => {
        setFullImageSrc(src);
        setFullImageOpen(true);
    };

    return (
        <FetchTicketDetails ticketno={ticketno} >
        {
            ({ data, error, loading, refetching, onSubmit }) => {
                return (
                    <div className={styles.container} key="c">
                        <Paper className={styles.card}>
                            {/* <div className={styles.card_title}>
                                <Typography>History for ticket #{ticketno} {data && data.subject ? `- ${data.subject}` : ''}</Typography>
                            </div> */}
                            <TicketHistoryChat
                                data={data}
                                loading={loading}
                                refetching={refetching}
                                error={error}
                                fullImageHandler={fullImageHandler}
                            />
                            {!isAdmin() && <TicketHistoryComment loading={loading} refetching={refetching} onSubmit={onSubmit} />}
                            {fullImageOpen && (
                                <ImageFullScreen
                                    handler={() => setFullImageOpen(!fullImageOpen)}
                                    src={fullImageSrc}
                                    open={fullImageOpen}
                                />
                            )}
                        </Paper>
                    </div>
                );
            }
        }
        </FetchTicketDetails>
    )
}

export default TicketDetails;
