import React, { useEffect, useState } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import "./normalize.css";
import "./App.css";

import { ThemeProvider } from "@material-ui/styles";
import { WiLineTheme, StephouseTheme, DefaultTheme } from "./MuiThemes.js";
import PrivateRoute from "./Components/auth/PrivateRoute.js?v=1.0";
import "./icons.js"; // Import individual icons
import { loadRecaptcha } from "react-recaptcha-v3";
import GlobalModal from "./Components/GlobalModal?v=1.0"; // RECAPTCHA V3
import ProtectedRoute from "./Components/auth/ProtectedRoute.js?v=1.0";
import RefreshScreen from "./Components/RefreshScreen?v=1.0";
import Login from "./Components/Login?v=1.0";
import RegisterNewUser from "./Components/RegisterNewUser?v=1.0";
import RegisterUserDetails from "./Components/RegisterUserDetails?v=1.0";
import ForgotPassword from "./Components/ForgotPassword?v=1.0";
import ChangePassword from "./Components/ChangePassword?v=1.0";
import Help from "./Components/Help?v=1.0";
import YourBusinessAccount from "./Components/YourBusinessAccount?v=1.0";
import NotFound from "./Components/NotFound.jsx?v=1.0";
import AdminTools from "./Components/admin/adminTools?v=1.0";
import VerifyRegister from "./Components/VerifyRegister.jsx?v=1.0";
import MyTickets from "./Components/myTickets/MyTickets.jsx?v=1.0";
import Users from "./Components/users/Users.jsx?v=1.0";
import OrderNowWrapper from "./Components/Order/OrderNowWrapper.js?v=1.0";

import { useDispatch, useSelector } from "react-redux";
import { setBusinessUnit } from "./Redux/AccountInfo/accountInfoActions.js";
import { setCompanyByBusinessUnit } from "./Redux/Company/companyActions.js";

// const RefreshScreen = lazy(() => import("./Components/RefreshScreen"));
// const OrderNow = lazy(() => import("./Components/Order/OrderNow"));
// const Login = lazy(() => import("./Components/Login"));
// const RegisterNewUser = lazy(() => import("./Components/RegisterNewUser"));
// const RegisterUserDetails = lazy(() =>
//   import("./Components/RegisterUserDetails")
// );
// const ForgotPassword = lazy(() => import("./Components/ForgotPassword"));
// const ChangePassword = lazy(() => import("./Components/ChangePassword"));
// const Help = lazy(() => import("./Components/Help"));
// const YourBusinessAccount = lazy(() =>
//   import("./Components/YourBusinessAccount")
// );
// const NotFound = lazy(() => import("./Components/NotFound.jsx"));
// const AdminTools = lazy(() => import("./Components/admin/adminTools"));
// const VerifyRegister = lazy(() => import("./Components/VerifyRegister.jsx"));
// const MyTickets = lazy(() => import("./Components/myTickets/MyTickets.jsx"));
// const Users = lazy(() => import("./Components/users/Users.jsx"));

/**
 * Function to get the site type using the url
 * @return string   WNI || SNI || empty
 */
export function siteType() {
  const hostname = window.location.hostname.toLowerCase();
  const WNIurls = process.env.REACT_APP_WILINE_HOSTNAME_ALLOW.split(",").map(
    (url) => url.toLowerCase()
  );
  const SNIurls = process.env.REACT_APP_STEPHOUSE_HOSTNAME_ALLOW.split(",").map(
    (url) => url.toLowerCase()
  );
  if (WNIurls.includes(hostname)) {
    return "WNI";
  } else if (SNIurls.includes(hostname)) {
    return "SNI";
  }
  return null;
}

export function siteTypeByBusinessUnit(business_unit) {
  let res = "";
  const url = window.location.href;
  if (
    business_unit &&
    typeof business_unit !== "undefined" &&
    (business_unit === "SNI" || business_unit === "WNI")
  ) {
    res = business_unit;
  } else if (url) {
    // if no business_unit is defined, pull from the url
    res = siteType();
  }
  return res;
}

const App = () => {
  const [loggedIn] = useState(false);
  const accountInfo = useSelector((state) => state.accountInfo);
  const companyInfo = useSelector((state) => state.companyInfo);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    loadRecaptcha(process.env.REACT_APP_RECAPTCHA, () => {});
    let title = "";
    let faviconImg = "";
    switch (siteType()) {
      case "SNI":
        title = "Customer Portal";
        faviconImg = "/assets/favicon/sni.ico";
        dispatch(setBusinessUnit("SNI"));
        dispatch(setCompanyByBusinessUnit("SNI"));
        break;
      case "WNI":
        title = "Customer Portal";
        faviconImg = "/assets/favicon/wni.ico";
        dispatch(setBusinessUnit("WNI"));
        dispatch(setCompanyByBusinessUnit("WNI"));
        break;
      default:
        title = "Customer Portal";
        dispatch(setBusinessUnit("WNI"));
        dispatch(setCompanyByBusinessUnit("WNI"));
        break;
    }
    document.title = title;

    if (faviconImg) {
      const favicon =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      favicon.type = "image/x-icon";
      favicon.rel = "shortcut icon";
      favicon.href = faviconImg;
      document.getElementsByTagName("head")[0].appendChild(favicon);
    }
  }, []);

  // Sets the theme based on the business unit
  const _getTheme = () => {
    if (siteTypeByBusinessUnit(accountInfo.businessUnit) === "WNI") {
      return WiLineTheme;
    } else if (siteTypeByBusinessUnit(accountInfo.businessUnit) === "SNI") {
      return StephouseTheme;
    } else if (companyInfo === "Wiline") {
      return WiLineTheme;
    } else if (companyInfo === "Stephouse") {
      return StephouseTheme;
    }
    return DefaultTheme;
  };

  return (
    <ThemeProvider theme={_getTheme()}>
      <RefreshScreen>
        <main>
          <Switch>
            <Route
              exact
              path={"/"}
              render={() =>
                loggedIn ? (
                  <Redirect to={"/business"} />
                ) : (
                  <Redirect to={"/login"} />
                )
              }
            />
            <Route path={"/business/my-tickets"} component={MyTickets} />
            <ProtectedRoute
              path={"/business/users"}
              fallback="/business/my-tickets"
              component={Users}
            />
            <Route path={"/login"} component={Login} />
            <Route path={"/register"} component={RegisterNewUser} />
            <Route
              path={"/register-user-details"}
              component={RegisterUserDetails}
            />
            <Route path={"/forgot-password"} component={ForgotPassword} />
            <Route
              path={"/change-password/:token/:email"}
              component={ChangePassword}
            />
            <Route
              path={"/user-registration/:token/:email"}
              component={RegisterUserDetails}
            />
            <Route
              path={"/verify-registration/:token"}
              component={VerifyRegister}
            />
            <Route path={"/order-now/:address"} component={OrderNowWrapper} />
            <Route path={"/help"} component={Help} />
            <PrivateRoute
              adminOnly={false}
              path={"/business"}
              component={YourBusinessAccount}
            />
            <PrivateRoute
              adminOnly={true}
              path={"/admin"}
              component={AdminTools}
            />
            <Route component={NotFound} />
          </Switch>
        </main>
        <GlobalModal />
      </RefreshScreen>
    </ThemeProvider>
  );
};

export { App };
