import { DialogTitle, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";

/**
 * @param {{
 *  open: boolean,
 *  onClose: () => void
 * }} props 
 * @returns 
 */
function FailedAlert (props) {
  const { open, onClose } = props;

  const closeModal = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="comment-dialog-title"
      aria-describedby="comment-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Error</DialogTitle>
      <DialogContent>
        <div>Sorry. An error occured while processing your request. Please try again later.</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FailedAlert;
