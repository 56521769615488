import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import { useLocation, useParams, useHistory } from "react-router-dom";
import "./UpgradeTo.scss";
import { useSelector, useDispatch } from "react-redux";
import { setUpgradableService } from "../../Redux/CustomerInfo/customerInfoActions";

function UpgradeTo({ showError }) {
  const params = useParams();
  const haveStep = "step" in params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const upgradableService = useSelector(
    (state) => state.customerInfo.upgradableService
  );

  const selectedLocation = useSelector(
    (state) => state.customerInfo.selectedLocation
  );

  const changeRouter = () => {
    if (!haveStep) {
      const newParams = new URLSearchParams(location.search);
      newParams.set("step", "Services");
      history.push({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    }
  };
  useEffect(() => {
    changeRouter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plans = [
    { id: 1, name: "50 mb/s", price: "$50/month", speed: 50 },
    { id: 2, name: "100 mb/s", price: "$100/month", speed: 100 },
    { id: 3, name: "150 mb/s", price: "$150/month", speed: 150 },
    {
      id: 4,
      name: "Custom value",
      price: "Custom request for upgrade",
      speed: 9999999,
    },
  ];

  const handleCardClick = (service) => {
    dispatch(setUpgradableService("serviceId", service.id));
    dispatch(setUpgradableService("serviceName", service.name));
    dispatch(setUpgradableService("servicePrice", service.price));
  };

  return (
    <Container>
      {showError && !upgradableService.serviceId && (
        <Typography
          variant="body2"
          className="error-text"
          color="error"
          align="center"
        >
          Please select a service plan!
        </Typography>
      )}
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan) => (
          <>
            {plan.speed > selectedLocation?.serviceSpeed && (
              <Grid item key={plan.id} xs={12} sm={6} md={4} lg={3}>
                <Card
                  className={`card ${
                    upgradableService.serviceId === plan.id ? "selected" : ""
                  } shadow`}
                  onClick={() => handleCardClick(plan)}
                >
                  <CardContent className="text-center">
                    <Typography variant="h5" component="h3" gutterBottom>
                      {plan.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {plan.price}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      color={
                        upgradableService.serviceId === plan.id
                          ? "secondary"
                          : "primary"
                      }
                      onClick={() => handleCardClick(plan)}
                    >
                      {upgradableService.serviceId === plan.id
                        ? "Selected"
                        : "Select"}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </Container>
  );
}

export default UpgradeTo;
