import React, { useEffect, useRef, useState } from 'react';
// Material Ui Components
import { Button, TextField, Grid, FormHelperText } from '@material-ui/core';
// Forms and  Validation
import { Formik, ErrorMessage, Form } from 'formik';
import { FadeIn } from '../animated.js';
import * as yup from 'yup';
import { catchAxios } from '../utils/utils';
import style from '../styles/MainLayout.module.scss';
import Loading from '../Loading';
import { FaSpinner } from 'react-icons/fa';
import { axiosWrapper } from '../../utils/axiosWrapper.js';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerDetails } from '../../Redux/AccountInfo/accountInfoActions.js';

/**
 *  This Component renders a {@link https://jaredpalmer.com/formik/docs/api/formik Formik} form used for changing details related to the account billing information. Current billing account information is queried from the API upon component mounting and, once loaded, prefilled into the form.
 *  @module ChangeBillingAddressForm
 *  @param {function } handleChange - updates the component's state to reflect changes entered into the form
 *  @param {function } handleReset - updatess the compoent's state to clear all mutable values.
 *  @param {function } handleSubmit - Sends the form information to the API
 *  @see {@link https://jaredpalmer.com/formik/docs/api/formik Formik}, </br> {@link https://www.npmjs.com/package/yup Yup }
 */
const AnimatedErrorMessage = FadeIn(ErrorMessage);
const AnimatedDiv = FadeIn('div');

const initialState = {
  user: '',
  email: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  country_code: '',
  postal_code: '',
  firstname: '',
  lastname: '',
  telephone: '',
  fax: '',
  cell: '',
  isVerified: {
    status: false,
    msg: '',
  },
};

/**
 *  Regular expression for validating a phone number
 */
const phoneRegExp =
/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
/**
 *  Yup validation object for various form fields.
 */
const formValidation = yup.object().shape({
address: yup.string().required('The first address line is required.'),
state: yup
  .string()
  .required('A state is required.')
  .length(2, 'A 2 Letter State is required.'),
country_code: yup
  .string()
  .required('A valid country code is required.')
  .length(3, 'Country code maximum length is 3 characters.'),
city: yup
  .string()
  .required('A city is required.')
  .max(24, 'Maximum length 24 characters.'),
postal_code: yup.string().max(12, 'Maximum 12 numbers for postal code.'),
firstname: yup
  .string()
  .min(2, 'Minimum length for first name is 2 characters.')
  .max(40, 'Maximum length for first name is 40 characters.')
  .required('Your first name is required.'),
lastname: yup.string(),
telephone: yup
  .string()
  .matches(phoneRegExp, 'Please use a valid phone number.')
  .min(9, 'You must use a valid phone number.'),
fax: yup
  .string()
  .nullable()
  .matches(phoneRegExp, 'Please use a valid phone number.')
  .min(9, 'You must use a valid phone number.')
  .max(40, 'Maximum size is 40 digits.'),
cell: yup
  .string()
  .nullable()
  .matches(phoneRegExp, 'Please use a valid phone number.')
  .min(9, 'You must use a valid phone number.')
  .max(40, 'Maximum size is 40 digits.'),
});

const ChangeBillingAddressForm = () => {
  const form = useRef(null);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const accountInfo = useSelector((state) => state.accountInfo);
  const dispatch = useDispatch();

  const loadProfileDetails = async () => {
    const authToken = localStorage.getItem('token');
    const accountNo = accountInfo.activeBillingAccount;
    if (!authToken) return;
    setLoading(true);
    const hostname = window.location.hostname;
    axiosWrapper(
      hostname,
      'POST',
      '/profile/details',
      { Authorization: `Bearer ${authToken}`, },
      { accountNo }
    ).then((res) => {
      const data = res.data.data;
      if (res.data.status) {
        setState((prevState) => ({
          ...prevState,
          user: `${data.detailsData.firstname} ${data.detailsData.lastname}`,
          email: data.detailsData.addressbook_email,
          ...data.detailsData,
        }));
      }
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      catchAxios(err);
    });
  };

  useEffect(() => {
    loadProfileDetails();
  }, []);

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    setChanged(true);
  };

  const handleReset = () => {
    // Don't let user reset user name or email
    this.setState((prevState) => ({
      ...initialState,
      user: prevState.user,
      email: prevState.email,
    }));
  };

  // Sets billing address values in redux if update is successful
  const setBillingAddressInRedux = (values) => {
    if (!values) return;
    dispatch(setCustomerDetails(values));
  };

  const handleSubmit = async (values) => {
    // Don't submit form if user hasn't changed anything
    if (!changed) {
      setSubmitting(false);
      setState((prevState) => ({
        ...prevState,
        isVerified: {
          status: false,
          msg: 'Form has not been updated. Please make a change before submitting.',
        },
      }));
      return;
    }
    setLoading(true);
    setSubmitting(true);
    setState((prevState) => ({
      ...prevState,
      isVerified: {
        status: false,
        msg: '',
      },
    }));
    const authToken = localStorage.getItem('token');
    const hostname = window.location.hostname;
    axiosWrapper(
      hostname,
      'POST',
      '/profile/update-billing-address',
      { Authorization: `Bearer ${authToken}` },
      { ...values }
    ).then((response) => {
      setSubmitting(false);
      setLoading(false);
      if (response.data.status) {
        setChanged(false);
        setState((prevState) => ({
          ...prevState,
          isVerified: {
            status: true,
            msg: response.data.msg,
          },
        }));
        setBillingAddressInRedux(values);
      } else {
        setState((prevState) => ({
          ...prevState,
          isVerified: {
            status: false,
            msg: response.data.msg,
          },
        }));
      }
    }).catch((err) => {
      setSubmitting(false);
      setState((prevState) => ({
        ...prevState,
        isVerified: {
          status: false,
          msg: 'There was a problem updating your data, please try again.',
        },
      }));
      catchAxios(err);
    });
  };

  return (
    <Formik
      ref={form}
      initialValues={{ ...state }}
      enableReinitialize={true}
      onSubmit={async (values) => {
        await handleSubmit(values);
      }}
      validationSchema={formValidation}
      render={({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="user"
                value={state.user || ''}
                label="User Name"
                name="user"
                margin="normal"
                variant="outlined"
                disabled={true}
                style={{ width: '100%' }}
              />
              <FormHelperText>
                Please contact support to update user name.
              </FormHelperText>
              <TextField
                id="address"
                name="address"
                onChange={handleChange}
                value={state.address || ''}
                label="Address Line 1"
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="address"
                component="div"
                className={style.error}
                style={{ width: '100%' }}
              />
              <TextField
                id="address2"
                name="address2"
                onChange={handleChange}
                value={state.address2 || ""}
                label="Address Line 2"
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="city"
                name="city"
                label="City"
                onChange={handleChange}
                value={state.city || ''}
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="city"
                component="div"
                className={style.error}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="state"
                name="state"
                label="State"
                onChange={handleChange}
                value={state.state || ''}
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="state"
                component="div"
                className={style.error}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="country_code"
                label="Country Code"
                name="country_code"
                onChange={handleChange}
                value={state.country_code || ''}
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="country_code"
                component="div"
                className={style.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="postal_code"
                label="Post Code"
                name="postal_code"
                onChange={handleChange}
                value={state.postal_code || ''}
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="postal_code"
                component="div"
                className={style.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="firstname"
                name="firstname"
                onChange={handleChange}
                value={state.firstname || ''}
                label="Contact First Name"
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="firstname"
                component="div"
                className={style.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="lastname"
                name="lastname"
                onChange={handleChange}
                value={state.lastname || ''}
                label="Contact Last Name"
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="lastname"
                component="div"
                className={style.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                name="email"
                value={state.email || ''}
                label="email"
                margin="normal"
                variant="outlined"
                disabled
                style={{ width: '100%' }}
              />
              <FormHelperText>
                Please contact support to update email.
              </FormHelperText>
              <AnimatedErrorMessage
                name="email"
                component="div"
                className={style.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="telephone"
                name="telephone"
                onChange={handleChange}
                value={state.telephone || ''}
                label="Telephone"
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="telephone"
                component="div"
                className={style.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="fax"
                label="Fax"
                name="fax"
                onChange={handleChange}
                value={state.fax || ''}
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="fax"
                component="div"
                className={style.error}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: '24px' }}>
              <TextField
                id="cell"
                label="Cell Phone"
                name="cell"
                onChange={handleChange}
                value={state.cell || ''}
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
              <AnimatedErrorMessage
                name="cell"
                component="div"
                className={style.error}
              />
            </Grid>
            {isSubmitting && (
              <div className={style.changePasswordSpinner}>
                <FaSpinner className="fa-spin" />
              </div>
            )}
            {state.isVerified.msg.length > 0 && (
              <Grid item xs={12}>
                <AnimatedDiv
                  className={
                    state.isVerified.status &&
                    state.isVerified.msg.length > 0
                      ? style.success
                      : style.error
                  }
                >
                  {state.isVerified.msg}
                </AnimatedDiv>
              </Grid>
            )}
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                variant="contained"
                type="reset"
                onClick={handleReset}
                disabled={isSubmitting || loading}
                style={{ width: '100%' }}
              >
                Clear
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || loading}
                style={{ width: '100%' }}
              >
                <Loading
                  loading={isSubmitting || loading}
                  loadingText={isSubmitting ? 'Sending' : 'Loading'}
                  submitText="Submit"
                  style={{ width: '100%' }}
                />
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default ChangeBillingAddressForm;
