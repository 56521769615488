import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Link as RouterLink } from "react-router-dom";
import { FadeIn } from "./animated.js";
import TextFieldFormik from "./TextFieldFormik"; // Use Formik as a wrapper for material-ui TextField
import ErrorBoundary from "./ErrorBoundary";
import { Button, Link, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import style from "./styles/LoginForm.module.scss";
import * as yup from "yup";
import { ReCaptcha } from "react-recaptcha-v3";
import { verifyCaptchaToken, decodeToken } from "./utils/utils";
import Loading from "./Loading.jsx";
import Loader from "./Loader";
import { axiosWrapper } from "../utils/axiosWrapper.js";
import { useDispatch } from "react-redux";
import { setActiveBillingAccount } from "../Redux/AccountInfo/accountInfoActions.js";

const AnimatedError = FadeIn(ErrorMessage);

const valid = yup.object().shape({
  username: yup
    .string()
    .email('You must provide a valid email address.')
    .required('A valid email address is required.'),
  password: yup.string().required('Password is a required field.'),
});

const LoginForm = (props) => {
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const recaptcha = useRef(null);

  // Get and store JSON web token
  const handleAuth = async (values, recaptchaToken, actions) => {
    // Login request
    const { username, password } = values;
    const data = {
      username: username,
      password: password,
      recaptchaToken,
    };

    const hostname = window.location.hostname;
    axiosWrapper(hostname, "POST", "/auth", {}, data).then((response) => {
      if (response.data.status) {
        if (response.data.authToken) {
          localStorage.setItem("lastMovement", Date.now().toFixed(0));
          localStorage.setItem("token", response.data.authToken);
          localStorage.setItem("session",
            JSON.stringify({
              name: response.data.userDisplayName,
            })
          );
          const billingaccountno = parseInt(response.data.billingaccountno);
          if (billingaccountno > 0) {
            dispatch(setActiveBillingAccount(billingaccountno));
          }
          // Redirect to correct path based on multiAccount off token
          if (response.data.multiAccount || response.data.isAdmin) {
            props.history.push("/business");
          } else if (response.data.readOnly) {
            props.history.push("/business/my-tickets");
          } else {
            props.history.push("/business/my-account");
          }
          actions.setSubmitting(false);
        } else {
          actions.setSubmitting(false);
          actions.setStatus({
            error: "There was an error, refresh the browser and try again.",
          });
        }
      } else {
        actions.setSubmitting(false);
        actions.setStatus({ error: response.data.msg });
      }
    }).catch((err) => {
      actions.setSubmitting(false);
      if (err.response && err.response.status === 401) {
        actions.setStatus({ error: "Invalid user name or password" });
      } else {
        actions.setStatus({
          error: "Sorry, there was a problem processing your request.",
        });
      }
    });
  };
  
  const handleFormSubmit = async (values, actions) => {
    if (recaptchaVerified) {
      await handleAuth(values, recaptchaToken, actions);
    } else {
      return alert('Please refresh browser and try again.');
    }
  };

  // Get recaptcha user token
  const verifyCallback = async (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!;
    if (recaptchaToken) {
      setRecaptchaToken(recaptchaToken);
      const recaptchaOk = await verifyCaptchaToken(recaptchaToken);
      setRecaptchaVerified(recaptchaOk);
      if (!recaptchaOk) {
        setError('Error verifying CAPTCHA, please try clearing your cache and reloading the page.');
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    // Check if token is expired and then redirect
    if (token) {
      const decodedToken = decodeToken(token);
      const multiAccount = Boolean(
        decodedToken && decodedToken.user && decodedToken.user.multiAccount
      );
      // Only check if token is expired
      // Token will get checked on all private routes
      // Don't refresh token on login page
      if (decodedToken && decodedToken.exp) {
        const now = new Date();
        // Don't redirect if token is expired
        if (now.getTime() > decodedToken.exp * 1000) {
          localStorage.removeItem("token");
          localStorage.removeItem("lastMovement");
        } else {
          localStorage.setItem("lastMovement", Date.now().toFixed(0));
          const path = multiAccount ? "/business" : "/business/my-account";
          props.history.push(path);
        }
      }
    }
  }, []);

  if (typeof localStorage !== "object") {
    return (
      <div>
        This browser is not supported. Please update your browser to continue.
      </div>
    );
  }

  return (
    <div>
      <ErrorBoundary>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={valid}
          onSubmit={(values, actions) => {
            actions.setStatus(null); // Reset status on every submit
            handleFormSubmit(values, actions); // Check reCAPTCHA then submit auth to server
          }}
          render={({
            errors,
            isSubmitting,
            values,
            setStatus,
            status,
          }) => (
            <Form
              style={{ gridColumn: "1 / end" }}
            >
              <div className={style.formGroup}>
                <Field
                  className={style.loginInputField}
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  placeholder="Email"
                  type="email"
                  margin="normal"
                  variant="outlined"
                  component={TextFieldFormik}
                />
                <AnimatedError
                  name="username"
                  component="div"
                  className={style.error}
                />
                <Field
                  className={style.loginInputField}
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  margin="normal"
                  variant="outlined"
                  component={TextFieldFormik}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >{
                          showPassword ? <VisibilityOff /> : <Visibility />
                        }</IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <AnimatedError
                  name="password"
                  component="div"
                  className={style.error}
                />
                {status && status.error && (
                  <div className={style.error}>{status.error}</div>
                )}
                {!recaptchaVerified && (
                  <div className={style.error}>{error}</div>
                )}
                <div className={style.center}>
                  {/* {(this.state.recaptchaVerified) ? */}
                  {!recaptchaVerified ? (
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={style.button}
                      disabled
                    >
                      <Loading
                        loading={true}
                        loadingText="LOADING"
                        submitText="SUBMIT"
                      />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={style.button}
                      disabled={isSubmitting}
                    >
                      <Loading
                        loading={isSubmitting}
                        loadingText="LOADING"
                        submitText="SUBMIT"
                      />
                    </Button>
                  )}
                  <RouterLink to="/register">
                    <Button
                      variant="contained"
                      className={style.button}
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Register
                    </Button>
                  </RouterLink>
                  <RouterLink to="/help">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={`${style.button}`}
                    >
                      Help
                    </Button>
                  </RouterLink>
                </div>
                <div className="login-info-span">
                  {isSubmitting ? (
                    <Loader />
                  ) : (
                    <Link
                      to="/forgot-password"
                      component={RouterLink}
                      color={"textPrimary"}
                      variant="body1"
                    >
                      Forgot Username/Password?
                    </Link>
                  )}
                </div>
              </div>
            </Form>
          )}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <ReCaptcha
          ref={recaptcha}
          sitekey={process.env.REACT_APP_RECAPTCHA}
          action="login"
          verifyCallback={verifyCallback}
        />
      </ErrorBoundary>
    </div>
  );
};

export default withRouter(LoginForm);
