import React, { useState } from "react";
import SectionTitle from "../SectionTitle.jsx";
import CreditCards from "./CreditCards";
import BankAccount from "./BankAccount.jsx";
import {
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
// SASS Styles
import style from "../styles/Payments.module.scss";
import ErrorBoundary from "../ErrorBoundary";
import LoaderPage from "../LoaderPage";
import { useSelector } from "react-redux";

/**
 * This Component renders a form with two radio button options that allow the user to add a payment method.  Depending on the users selection they can either choose to view the {@link module:BankAccount BankAccount} form or {@link module:CreditCards CreditCards} form.  The default form is CreditCards.
 * @module AddNewPaymentMethod
 * @extends Component
 *
 * @see {@link module:BankAccount BankAccount}, </br>{@link module:CreditCards CreditCards}, </br>{@link module:ErrorBoundary ErrorBoundary}, </br>{@link module:SectionTitle SectionTitle}, </br>{@link https://material-ui.com/guides/api/ Material UI Components}
 */
const AddNewPaymentMethod = (props) => {
  const [paymentMethod, setPaymentMethod] = useState('credit');
  const accountInfo = useSelector((state) => state.accountInfo);

  const handleChange = (event) => {
    if (event.target.name === 'paymentMethod') {
      setPaymentMethod(event.target.value);
    }
  };

  return (
    <>
      <LoaderPage />
      <ErrorBoundary>
        <SectionTitle
          variant="h1"
          style={style.welcome}
          title="Add New Payment Method"
        />
      </ErrorBoundary>
      <Grid container spacing={0} style={{ padding: "2vw" }}>
        <Grid item xs={12} xl style={{ marginBottom: "80px" }}>
          <ErrorBoundary>
            <form
              className={style.formContainer}
              noValidate
              autoComplete="off"
            >
              <div>
                {/* Radio Buttons */}
                <FormControl component="fieldset">
                  <Typography
                    variant="h4"
                    color="primary"
                    style={{ textAlign: "center", width: "100%" }}
                  >
                    Payment Method
                  </Typography>
                  <RadioGroup
                    aria-label="Payment Method"
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="credit"
                      control={
                        <Radio
                          color="secondary"
                          checked={
                            paymentMethod === "credit"
                              ? true
                              : false
                          }
                        />
                      }
                      label="Credit Card"
                    />
                    <FormControlLabel
                      value="account"
                      control={<Radio color="secondary" />}
                      label="Bank Account"
                    />
                  </RadioGroup>
                </FormControl>

                {/* Credit Card Text input fields */}
                {paymentMethod === "credit" ? (
                  <CreditCards
                    accNo={accountInfo.activeBillingAccount}
                  />
                ) : (
                  <BankAccount
                    accNo={accountInfo.activeBillingAccount}
                  />
                )}
              </div>
            </form>
          </ErrorBoundary>
        </Grid>
      </Grid>
    </>
  );
};

export default AddNewPaymentMethod;
