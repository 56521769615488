import React from "react";
import "./ImageFullScreen.scss";
import { MdOutlineClear } from "react-icons/md";

/**
 * 
 * @param {{
 *  src: string,
 *  open: boolean,
 *  handler: () => void,
 * }} props 
 * @returns 
 */
const ImageFullScreen = (props) => {
  const { src, open, handler } = props;

  if (!open) return null;

  return (
    <div className="image_overlay" onClick={handler}>
      <div className="close_icon" onClick={handler}>
        <MdOutlineClear size={40} />
      </div>
      <div className="image">
        <img src={src} alt="Failed to load" />
      </div>
    </div>
  );
};

export default ImageFullScreen;
