import React, { Component } from "react";
import SectionTitle from "../SectionTitle.jsx";
// Material Ui Components
import {
  Grid,
  TextField,
  Paper,
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Slide,
} from "@material-ui/core";
// Forms and Validation
import { Formik, Field } from "formik";
import { FadeIn, enterComponent } from "../animated.js";
import TextFieldFormik from "../TextFieldFormik.js";
import * as yup from "yup";
// SASS Styles
import style from "../styles/MainLayout.module.scss";
import { catchAxios, getSessionStorage } from "../utils/utils";
import axios from "axios";
import Loading from "../Loading.jsx";
import ErrorBoundary from "../ErrorBoundary";
import LoaderPage from "../LoaderPage";
import { FaSpinner } from "react-icons/fa";

const SNI_DOMAINS = process.env.REACT_APP_STEPHOUSE_HOSTNAME_ALLOW;

const WNI_HOST = process.env.REACT_APP_API_HOST;
const SNI_HOST = process.env.REACT_APP_API_SNI_HOST;

/**
 * This Component renders a {@link https://jaredpalmer.com/formik/docs/api/formik Formik} form that allows users to change their password by entering their current password, a new password, and then repeating the new password.
 * @module ChangePassword
 *  @see {@link https://jaredpalmer.com/formik/docs/api/formik Formik}, </br> {@link https://www.npmjs.com/package/yup Yup}
 */

const AnimatedDiv = FadeIn("div");
const AnimateGrid = enterComponent(Grid);

// adds a slide up transition for the dialog box
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = () => ({
  paperBg: {
    padding: "24px",
    maxWidth: "90%",
    margin: "0 auto",
    marginBottom: "80px", // margin is required to clear recaptcha
  },
});

class ChangePassword extends Component {
  state = {
    dialogOpen: false,
    dialogMsg: "",
  };

  updatePassword = (values, setSubmitting, setStatus) => {
    setSubmitting(true);
    const authToken = localStorage.getItem("token");
    const hostname = window.location.hostname;
    const urlHost = SNI_DOMAINS.includes(hostname) ? SNI_HOST : WNI_HOST;
    axios
      .post(
        urlHost + "/users/change-password",
        {
          oldPassword: values.oldPassword,
          password: values.password,
          passwordRepeated: values.passwordRepeated,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          withCredentials: true,
        }
      )
      .then((data) => {
        data = data.data;
        setStatus({ isVerified: { status: data.status, msg: data.msg } });
        this.setState({ dialogOpen: data.status, dialogMsg: data.msg });
        if (data.msg.includes("was changed")) {
          return this.setState({ dialogOpen: true });
        }
        setSubmitting(false);
      })
      .catch((err) => {
        setStatus({ isVerified: { status: false, msg: err.message } });
        setSubmitting(false);
        catchAxios(err);
      });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
    this.props.history.push("/");
  };

  render() {
    const { classes } = this.props; // receives the theme styles from props

    const validationSchema = yup.object().shape({
      password: yup
        .string()
        .matches(
          /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z]).{8,72}$/,
          {
            message:
              "Minimum 8 characters. Must contain 2 Uppercase Letters, 2 Lowercase Letters, 1 Special Character (Ie. $,#,£,@) 2" +
              " Digits.",
            excludeEmptyString: true,
          }
        )
        .trim("Passwords cannot contain leading or trailing spaces.")
        .strict()
        .required("A password is required."),
      passwordRepeated: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match") // ensures both values are equal.
        .required("This field cannot be empty."),
    });

    return (
      <>
        <LoaderPage />
        <div style={{ gridColumn: "1/-1", padding: 0 }}>
          <SectionTitle
            variant="h1"
            style={style.welcome}
            title="Change Password"
          />
          <Grid container justifyContent="center" className={style.container}>
            <AnimateGrid item xs={12} md={6} xl={6}>
              <Paper
                className={`${style.shadow} ${classes.paperBg}`}
                square={true}
              >
                <ErrorBoundary>
                  <Formik
                    initialValues={{
                      password: "",
                      oldPassword: "",
                      passwordRepeated: "",
                    }}
                    onSubmit={(values, { setSubmitting, setStatus }) =>
                      this.updatePassword(values, setSubmitting, setStatus)
                    }
                    validationSchema={validationSchema}
                    render={(props) => (
                      <form onSubmit={props.handleSubmit}>
                        <TextField
                          fullWidth={true}
                          id="userLoggedIn"
                          label="User Logged In"
                          value={getSessionStorage("session.name")}
                          margin="normal"
                          variant="outlined"
                          disabled
                        />
                        <Field
                          component={TextFieldFormik}
                          fullWidth={true}
                          id="oldPassword"
                          name="oldPassword"
                          label="Old Password"
                          value={props.values.oldPassword}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          type="password"
                        />
                        {props.touched.oldPassword &&
                          props.errors.oldPassword && (
                            <AnimatedDiv id="error-old" className={style.error}>
                              {props.errors.oldPassword}
                            </AnimatedDiv>
                          )}
                        <Field
                          component={TextFieldFormik}
                          fullWidth={true}
                          id="password"
                          name="password"
                          label="New Password"
                          value={props.values.password}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          type="password"
                        />
                        {props.touched.password && props.errors.password && (
                          <AnimatedDiv
                            id="error-password"
                            className={style.error}
                          >
                            {props.errors.password}
                          </AnimatedDiv>
                        )}
                        <Field
                          component={TextFieldFormik}
                          fullWidth={true}
                          id="passwordRepeated"
                          name="passwordRepeated"
                          label="Retype Password"
                          value={props.values.passwordRepeated}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          type="password"
                        />
                        {props.status &&
                          props.status.isVerified &&
                          !props.isSubmitting && (
                            <AnimatedDiv
                              className={
                                props.status.isVerified.status
                                  ? style.success
                                  : style.error
                              }
                            >
                              {props.status.isVerified.msg}
                            </AnimatedDiv>
                          )}
                        {props.isSubmitting && (
                          <div className={style.changePasswordSpinner}>
                            <FaSpinner className="fa-spin" />
                          </div>
                        )}
                        {props.touched.passwordRepeated &&
                          props.errors.passwordRepeated && (
                            <AnimatedDiv
                              id="error-passwordRepeated"
                              className={style.error}
                            >
                              {props.errors.passwordRepeated}
                            </AnimatedDiv>
                          )}
                        <Grid container justifyContent="space-between">
                          <Button
                            variant="contained"
                            onClick={props.handleReset}
                            style={{ width: "47%" }}
                            disabled={props.isSubmitting}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ width: "47%" }}
                            disabled={props.isSubmitting}
                          >
                            <Loading
                              loading={props.isSubmitting}
                              loadingText="LOADING"
                              submitText="SUBMIT"
                            />
                          </Button>
                        </Grid>
                      </form>
                    )}
                  />
                </ErrorBoundary>
              </Paper>
            </AnimateGrid>
          </Grid>
          <Dialog
            open={this.state.dialogOpen || false}
            onClose={this.handleClose}
            maxWidth="md"
            aria-labelledby="dialog-title"
            TransitionComponent={Transition}
            keepMounted
          >
            <DialogTitle id="dialog-title" onClose={this.handleClose}>
              {`Your Password Has Been Changed`}
            </DialogTitle>
            <DialogContent>
              <Typography gutterBottom variant="h5">
                {this.state.dialogMsg}
                <br />
                For your security, we will now log you out of the portal.
                <br />
                <br />
                You will be required to login with your new password.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                color="primary"
                variant="contained"
              >
                close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ChangePassword);
