import React from "react";
import Loader from "../Loader.jsx";
import { generateKey, catchAxios } from "../utils/utils";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import SelectPropertyOrCard from "../SelectPropertyOrCard.jsx";
import { FadeIn } from "../animated.js";
import ErrorBoundary from "../ErrorBoundary";
import style from "../styles/ContactCard.module.scss";
import { FaBuilding } from "react-icons/fa";
import { Typography } from "antd";
import { axiosWrapper } from "../../utils/axiosWrapper.js";

/**
 *  This Component renders a list of the addresses associated with a billing account.  The address is displayed using the {@link module:SelectPropertyOrCard SelectPropertyOrCard} Component. Upon component mounting, the API is called to get a list of the locations.  The locations are then saved to this component's state.
 *  @module ServiceAddress
 *  @see {@link module:SelectPropertyOrCard SelectPropertyOrCard}
 */

const styles = (theme) => ({
  tableHeader: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: "12px",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },
});

const FadeLocation = FadeIn(SelectPropertyOrCard);

class ServiceAddress extends React.Component {
  _isMounted = false; // Prevents updating unmounted component

  state = {
    locationsList: [],
    loading: false,
  };

  componentDidMount() {
    this._isMounted = true; // Prevents state update on unmounted component
    const authToken = localStorage.getItem("token");
    this.setState({ loading: true });
    const hostname = window.location.hostname;

    axiosWrapper(hostname, "POST", "/profile/locations-list", {
      Authorization: `Bearer ${authToken}`,
    })
      .then((res) => {
        if (this._isMounted) {
          if (res.data.status) {
            this.setState({
              locationsList: res.data.data,
              loading: false,
            });
          } else {
            this.setState({
              locationsList: [],
              loading: false,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          locationsList: [],
        });
        catchAxios(err);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={style.billingContainer}>
        <div className={classes.tableHeader}>SERVICE ADDRESS</div>
        {this.state.loading || this.props.loading ? (
          <div className={style.serviceAddressLoader}>
            <Loader height="160px" />
          </div>
        ) : (
          <div className={style.content}>
            <Grid container spacing={2}>
              {this.state.locationsList &&
              this.state.locationsList.length > 0 ? (
                this.state.locationsList.map((item, i) => {
                  return (
                    <ErrorBoundary key={generateKey("EB" + item + i)}>
                      <FadeLocation
                        key={generateKey(item + i)}
                        delay={`${i * 80}ms`}
                        icon={<FaBuilding size={32} />}
                        description={`${item.address} ${
                          item.address2 ? item.address2 : ""
                        } ${item.city}, ${item.state} ${item.postal_code}`}
                        // heading="Property"
                        iconSize="32px"
                        orgid={item.orgid}
                        activeWorkOrders={[item.active_work_orders]}
                      />
                    </ErrorBoundary>
                  );
                })
              ) : (
                <Typography align="center" className={style.errorMessage}>
                  No Service Address for this Account
                </Typography>
              )}
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ServiceAddress);
