import * as customerInfoType from "./customerInfoType";

const START_STATE = {
  serviceAddress: [],
  selectedService: {
    serviceId: null,
    serviceName: ""
  },
  contactInformation: {
    firstName: "",
    lastName: "",
    email: "",
    customerAddress: ""
  },
  billingInformation: {
    cardHolderName: "",
    cardNumber: "",
    expDate: "",
    cvc: ""
  },
  selectedLocation: { 
    serviceId: null,
    serviceName: ""
  },
  upgradableService: {
    serviceId: null,
    serviceName: ""
  },
  customRequest: "",
};

const customerInfoReducer = (state = START_STATE, action) => {
  switch (action.type) {    
    case customerInfoType.CUSTOMER_INFO_SET_PROPERTY:
      return {
        ...state,
        contactInformation: {
          ...state.contactInformation,
          [action.key]: action.value
        }
      };
    case customerInfoType.CUSTOMER_INFO_SET_BILLING_INFO:
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          [action.key]: action.value
        }
      };
    case customerInfoType.CUSTOMER_INFO_SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: action.payload
      };
    case customerInfoType.CUSTOMER_INFO_REMOVE_SERVICE_ADDRESS:
      return {
        ...state,
        serviceAddress: state.serviceAddress.filter((_, i) => i !== action.payload),
      };
      case customerInfoType.CUSTOMER_INFO_ADD_SERVICE_ADDRESS:
      return {
        ...state,
        serviceAddress: [...state.serviceAddress, action.payload]
      };
      case customerInfoType.CUSTOMER_INFO_SET_SELECTED_PLAN:
        return {
          ...state,
          selectedService: {
            ...state.selectedService,
            [action.key]: action.value
          }
        };
        case customerInfoType.CUSTOMER_INFO_SET_SELECTED_LOCATION:
        return {
          ...state,
          selectedLocation: {
            ...state.selectedLocation,
            [action.key]: action.value
          }
        };
        case customerInfoType.CUSTOMER_INFO_SET_UPGRADABLE_SERVICE:
        return {
          ...state,
          upgradableService: {
            ...state.upgradableService,
            [action.key]: action.value
          }
        };
        case customerInfoType.CUSTOMER_INFO_SET_CUSTOM_REQUEST:
      return {
        ...state,
        customRequest: action.payload
      };
    default:
      return state;
  }
};

export default customerInfoReducer;
