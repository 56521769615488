import React, { Component } from "react";
import InvoiceTable from "./InvoiceTable.jsx";
import qs from "qs";
import { formatDate } from "../utils/utils.js";
import { catchAxios } from "../utils/utils";
import { axiosWrapper } from "../../utils/axiosWrapper.js";

/**
 * Gets invoice data and then renders InvoiceTable
 * @param {Object} props Loading prop and additional styles
 * @module InvoiceDataContainer
 * @extends Component
 */
class InvoiceDataContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    let limit = 0;
    let months = 24;
    let tableTitle;
    if (props.limit) {
      limit = props.limit;
    }
    if (props.months) {
      months = props.months;
    }

    if (props.tableTitle) {
      tableTitle = props.tableTitle;
    } else {
      tableTitle = "YOUR LATEST INVOICES";
    }

    this.state = {
      success: "",
      error: "",
      loading: true,
      loadingProforma: true,
      data: [],
      proforma: [],
      limit,
      months,
      tableTitle,
    };
  }

  // Get invoice document
  handleSubmit = (file) => {
    const data = {
      fileName: file,
    };

    const authToken = localStorage.getItem("token");
    if (authToken) {
      // Get and download file
      const hostname = window.location.hostname;

      axiosWrapper(
        hostname,
        "POST",
        "/invoices/document",
        {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${authToken}`,
        },
        qs.stringify(data),
        true,
        "blob"
      )
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", file);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          catchAxios(err);
        });
    }
  };

  // Sort invoice date in descending order
  sortData = (data) => {
    data.sort(function (a, b) {
      return new Date(formatDate(b.date)) - new Date(formatDate(a.date));
    });
  };

  // Return appropriate data objects
  buildData = (data) => {
    if (data.length === 0) {
      return data;
    }

    return data.map((e) => {
      return {
        date: e.date,
        file: e.file,
        invoiceNumber: e.invoiceNumber,
      };
    });
  };

  getRoutePath = (type) => {
    return type === "proforma" ? "proforma-data" : "invoice-data";
  };

  handleResponse = (response, type) => {
    if (response?.data?.msg) {
      if (response.data.status === true) {
        if (type === "proforma") {
          this.setState({
            success: response.data.msg,
            proforma: response.data.data,
          });
        } else {
          this.setState({
            success: response.data.msg,
            data: response.data.data,
          });
        }
      } else {
        this.setState({
          error: response.data.msg,
        });
      }
    } else {
      this.setState({
        error: "There was a problem retrieving your invoice data.",
      });
    }
  };

  getInvoiceData = (type) => {
    const routePath = this.getRoutePath(type);

    this.setState({
      success: "",
      error: "",
      loading: true,
    });
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const hostname = window.location.hostname;

      axiosWrapper(
        hostname,
        "POST",
        `/invoices/${routePath}`,
        {
          Authorization: `Bearer ${authToken}`,
        },
        { limit: this.state.limit, months: this.state.months }
      )
        .then((response) => {
          if (this._isMounted) {
            this.handleResponse(response, type);
          }
        })
        .catch((err) => {
          this.setState({
            error: "There was a problem retrieving your invoice data.",
            loading: false,
          });
          catchAxios(err);
        })
        .finally(() => {
          if (type === "proforma") {
            this.setState({
              loadingProforma: false,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.getInvoiceData();
    this.getInvoiceData("proforma");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let data = this.buildData(this.state.data);
    let proforma = this.buildData(this.state.proforma);
    const invoicePath = window.location.href.split("/");

    return (
      <>
        <InvoiceTable
          additionalStyles={this.props.additionalStyles}
          tableTitle={this.state.tableTitle}
          handleSubmit={this.handleSubmit}
          loading={this.props.loading ? true : this.state.loading}
          data={data}
        />
        {this.state.proforma.length > 0 &&
          invoicePath.includes("my-invoices") && (
            <InvoiceTable
              additionalStyles={this.props.additionalStyles}
              tableTitle="PF INVOICES"
              handleSubmit={this.handleSubmit}
              loading={this.props.loadingProforma ? true : this.state.loading}
              data={proforma}
            />
          )}
      </>
    );
  }
}

export default InvoiceDataContainer;
