import React from "react";
import { roundTo } from "../utils/paymentUtils";

const PaymentFeeNote = (props) => {
  const { paymentMethod = null, amountDue = 0.0, loading = false } = props;
  const serviceCharge = (paymentMethod === 'CC') ? roundTo(amountDue * 0.03, 2) : 0;
  const totalDue = roundTo(amountDue * 1.03, 2);

  if (paymentMethod === null || !(paymentMethod.length) || loading) return null;

  if (paymentMethod === 'CC') {
    return (
      <div style={{ marginTop: '0.75rem', textAlign: 'center' }}>
        <div>Since you&apos;ve opted to pay by credit card, the system will automatically add a 3% administrative processing fee.</div>
        <div>In this case, if your bill is <i>${amountDue.toFixed(2)}</i>, total charged will be <i>${totalDue.toFixed(2)}</i>. Please enter the actual amount due displayed above, and the system will automatically apply and process the additional <i>${serviceCharge.toFixed(2)}</i>.</div>
        <div>To avoid this fee, please use ACH, as there is no charge for ACH payments. Thank you.</div>
      </div>
    );
  }

  return (
    <div style={{ marginTop: '0.75rem', textAlign: 'center' }}>
      <div>No administrative processing fee will be applied to any bank account payments.</div>
    </div>
  );
};

export default PaymentFeeNote;
