import React from "react";
import style from "./styles/Aside.module.scss";
import { withRouter } from "react-router-dom";
import { clearServerSession, decodeToken } from "./utils/utils";
import BackButton from "./BackButton";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { siteType } from "../App";
import { FaSignOutAlt, FaUserShield } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setCompanyByBusinessUnit } from "../Redux/Company/companyActions.js";
import { setActiveBillingAccount } from "../Redux/AccountInfo/accountInfoActions.js";

const useStyles = makeStyles((theme) => ({
  logout: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
    paddingLeft: "24px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

/**
 * A simplified top nav bar for the {@link module:MultipleAccounts MultipleAccounts} module.
 * @module SimpleNav
 */
function SimpleNav(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  let token = {};
  let isAdmin = false;
  const hashToken = localStorage.getItem("token");
  if (!hashToken) {
    props.history.push("/login");
  } else {
    token = decodeToken(localStorage.getItem("token"));
    isAdmin = token.user.isAdmin;
    if (!token.user.multiAccount) {
      props.history.push("/business/my-account");
    }
  }

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Remove token and redirect to login page
  const logout = async () => {
    dispatch(setActiveBillingAccount('0'));
    dispatch(setCompanyByBusinessUnit(siteType()));
    await clearServerSession();
    props.history.push("/");
  };

  // Only render a back button if navigation came from the drawer
  let backButton =
    props.history.location &&
    props.history.location.state &&
    props.history.location.state.from;

  return (
    <>
      <div
        className={`${style.simpleNav} ${
          backButton || isAdmin
            ? style.simpleNavMultiple
            : style.simpleNavSingle
        }`}
      >
        <div onClick={handleDialogOpen}>
          <span className={classes.logout}>
            LOGOUT <FaSignOutAlt />
          </span>
        </div>
        {isAdmin && (
          <Button
            variant="outlined"
            color="primary"
            aria-label="Admin Tools"
            href="/#/admin/stats"
            style={{ margin: "0 2vw" }}
          >
            <FaUserShield
              style={{ marginRight: "8px" }}
            />
            <span style={{ textTransform: "uppercase" }}>Admin Tools</span>
          </Button>
        )}
        {backButton && (
          <div className={style.backNav}>
            <BackButton history={props.history} />
          </div>
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          {"Are you sure you want to logout?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to logout from the application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ minWidth: "120px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={logout}
            variant="contained"
            color="primary"
            style={{ minWidth: "120px" }}
            autoFocus
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Let drawer access router location
const SimpleNavWithRouter = withRouter(SimpleNav);
export default SimpleNavWithRouter;
