import React, { useEffect, useState } from "react";
import { catchAxios } from "../../utils/utils.js";
import { axiosWrapper } from "../../../utils/axiosWrapper.js";

const FetchTickets = (props) => {
  const { children } = props;

  const [data, setData] = useState({ hasMore: false, tickets: [] });
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const authToken = localStorage.getItem("token");
        const hostname = window.location.hostname;

        const response = await axiosWrapper(hostname, "GET", "/tickets", {
          Authorization: `Bearer ${authToken}`,
        });
        const { data } = response;
        setData(data);
      } catch (err) {
        setError(err);
        catchAxios(err);
      } finally {
        setLoading(false);
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = async (lastId) => {
    try {
      setLoadingMore(true);
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;

      const response = await axiosWrapper(
        hostname,
        "GET",
        `/tickets?last_id=${lastId}`,
        {
          Authorization: `Bearer ${authToken}`,
        }
      );
      if (data.tickets.length > 0) {
        setData((prevState) => {
          return {
            ...prevState,
            tickets: [...prevState.tickets, ...response.data.tickets],
            hasMore: response.data.hasMore,
          };
        });
      } else setData(response.data);
    } catch (err) {
      setError(err);
      catchAxios(err);
    } finally {
      setLoadingMore(false);
    }
  };

  const onLoadMore = async (lastId) => {
    await loadMore(lastId);
  };

  return (
    <div>
      {children({
        data,
        loading,
        loadingMore,
        error,
        onLoadMore,
      })}
    </div>
  );
};

export default FetchTickets;
