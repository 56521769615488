import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle.jsx";
import { Grid, Typography } from "@material-ui/core";
import SelectPropertyOrCard from "../SelectPropertyOrCard";
import { getPayMethods, isExpired } from "../utils/paymentUtils.js";
import { FadeIn } from "../animated.js";
// SASS Styles
import style from "../styles/Payments.module.scss";
import styled from "styled-components";
import ErrorBoundary from "../ErrorBoundary";
import Loader from "../Loader.jsx";
import CardModal from "./CardModal";
import { catchAxios, getSessionStorage } from "../utils/utils";
import LoaderPage from "../LoaderPage";
import {
  FaMoneyCheck,
  FaCcAmex,
  FaCcDiscover,
  FaCcMastercard,
  FaCcVisa,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import { axiosWrapper } from "../../utils/axiosWrapper.js";
import { useDispatch } from "react-redux";
import { removePaymentOption, setPaymentOptions } from "../../Redux/AccountInfo/accountInfoActions.js";

/**
 *  This Component renders the Payment methods available to the users account.
 *  @module PaymentMethods
 *  @param {function} handleRemoveCard - Takes single argument: string.  The string should be the unique identifier associated with the card to be removed.  Returns a new array of payment methods excluding the removed card.
 *  @param {function} toggleModal - Takes no arguments. Toggles state.open.
 *  @param {function} setActiveAccountId - Takes 3 arguments: payId, index, type. Sets these values to state.
 *  @param {function} removeCard - Takes no arguments.  Calls API to remove a payment option.
 *  @param {function} setAutoPay - Takes no argument.  Calls API to set a payment option to auto-pay.
 *  @see {@link module:CardModal CardModal}, </br> {@link module:ErrorBoundary ErrorBoundary}, </br> {@link module:SectionTitle SectionTitle}
 */

const WrappedSelectCard = FadeIn(SelectPropertyOrCard);

const Hidden = styled(SelectPropertyOrCard)`
  visibility: hidden;
`;

const cardIcon = (payType) => {
  if (payType === 'AmericanExpress') return <FaCcAmex size={64} />;
  if (payType === 'MasterCard') return <FaCcMastercard size={64} />;
  if (payType === 'Visa') return <FaCcVisa size={64} />;
  return <FaCcDiscover size={64} />;
}

const PaymentMethods = (props) => {
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [error, setError] = useState('');
  const [type, setType] = useState('');
  const [payId, setPayId] = useState(null); // payId to remove
  const [index, setIndex] = useState(null); // payment index to remove
  const dispatch = useDispatch();

  const handleRemoveCard = (id) => {
    const newCards = cards.filter(
      (card) => card.pay_account_id !== id
    );
    setCards([ ...newCards ]);
  }

  const initialLoad = async () => {
    setLoading(true);
    const hostname = window.location.hostname;
    const payMethods = await getPayMethods(hostname);
    if (payMethods.data.status) {
      setCards([ ...payMethods.data.data ]);
      dispatch(setPaymentOptions([ ...payMethods.data.data ]));
    }
    setLoading(false);
  };

  useEffect(() => {
    initialLoad();
  }, []);

  const toggleModal = () => {
    // Clear status messages if modal is open
    if (open) {
      setOpen(false);
      // Quick fix to prevent state change bug
      setTimeout(() => {
        setError('');
        setConfirmationMsg('');
      }, 500);
    } else {
      setOpen(true);
    }
  };

  // Sets the payment information to be removed
  const setActiveAccountID = (payId, index, type) => {
    setPayId(payId);
    setIndex(index);
    setType(type);
    setOpen(true);
  };

  const removeCard = async () => {
    this.setState({ modalLoading: true, err: "" });
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const hostname = window.location.hostname;
      axiosWrapper(hostname, "POST", "/payments/remove-pay-method",
        { Authorization: `Bearer ${authToken}` },
        { payId, }
      ).then((res) => {
        if (res.data.status === true) {
          handleRemoveCard(payId);
          dispatch(removePaymentOption(index));
          setModalLoading(false);
          setConfirmationMsg(res.data.msg);
        } else {
          setModalLoading(false);
          setError(res.data.msg);
        }
      }).catch((err) => {
        setModalLoading(false);
        setError('There was a problem processing your request.');
        catchAxios(err);
      });
    } else {
      setModalLoading(false);
      setError('There was a problem with parameters.');
    }
  };

  const removeAutoPay = async () => {
    setModalLoading(true);
    setError('');
    const authToken = localStorage.getItem("token");
    if (authToken && payId) {
      const hostname = window.location.hostname;
      axiosWrapper(hostname, "POST", "/payments/disallow",
        { Authorization: `Bearer ${authToken}` },
        { payId }
      ).then((res) => {
        if (res.data.status) {
          setModalLoading(false);
          setConfirmationMsg(res.data.msg);
          setCards([ ...res.data.cards ]);
        } else {
          setModalLoading(false);
          setError('There was a problem processing your request.');
        }
      }).catch((err) => {
        catchAxios(err);
      });
    } else {
      setModalLoading(false);
      setError('There was a problem with parameters.');
    }
  };

  // Sets a payment method as default auto-pay option
  const setAutoPay = async () => {
    setModalLoading(true);
    setError('');
    const authToken = localStorage.getItem("token");
    if (authToken && payId) {
      const hostname = window.location.hostname;
      axiosWrapper(hostname, "POST", "/payments/authorize",
        { Authorization: `Bearer ${authToken}` },
        { payId }
      ).then((res) => {
        if (res.data.status) {
          setModalLoading(false);
          setConfirmationMsg(res.data.msg);
          setCards([ ...res.data.cards ]);
        } else {
          setModalLoading(false);
          setError('There was a problem processing your request.');
        }
      }).catch((err) => {
        catchAxios(err);
      });
    } else {
      setModalLoading(false);
      setError('There was a problem with parameters.');
    }
  };

  return (
    <>
      <LoaderPage />
      <ErrorBoundary>
        <SectionTitle
          variant="h1"
          style={style.welcome}
          title="Your Payment Methods"
        />
      </ErrorBoundary>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <div className={style.yourCards}>
            {loading ? (
              <ErrorBoundary>
                <Loader bg="#E9F1F8" />
              </ErrorBoundary>
            ) : (
              <Grid container spacing={2} justifyContent="flex-start">
                {cards.map((item, index) => {
                  if (
                    item.payType === "MasterCard" ||
                    item.payType === "Visa" ||
                    item.payType === "Discover" ||
                    item.payType === "AmericanExpress"
                  ) {
                    return (
                      <ErrorBoundary key={item.pay_account_id}>
                        <WrappedSelectCard
                          delay={"0s"}
                          icon={cardIcon(item.payType)}
                          iconSize="64px"
                          heading={`${item.prefixMask} ${item.lastDigitsCC}`}
                          description={item.nameOnAccount}
                          setActiveAccount={(type) =>
                            setActiveAccountID(
                              item.pay_account_id,
                              index,
                              type
                            )
                          }
                          payId={item.pay_account_id}
                          expired={isExpired(
                            item.expireMonth,
                            item.expireYear
                          )}
                          autoPay={item.status === "ACTIVE"}
                        />
                      </ErrorBoundary>
                    );
                  } else {
                    //methodType === ACH
                    return (
                      <ErrorBoundary key={item.pay_account_id}>
                        <WrappedSelectCard
                          delay={"0s"}
                          icon={<FaMoneyCheckAlt size={64} />}
                          iconSize="3x"
                          heading={`${item.prefixMask} ${item.lastDigitsACH}`}
                          description={item.nameOnAccount}
                          setActiveAccount={(type) =>
                            setActiveAccountID(
                              item.pay_account_id,
                              index,
                              type
                            )
                          }
                          payId={item.pay_account_id}
                          expired={isExpired(
                            item.expireMonth,
                            item.expireYear
                          )}
                          autoPay={item.status === "ACTIVE"}
                        />
                      </ErrorBoundary>
                    );
                  }
                })}
                {/* adds invisible SelectPropertyOrCard components to fix grid spacing */}
                {/* TODO: Add a proper grid layout that doesn't need hidden dom elements. */}
                {cards.length >= 4 && (
                  <>
                    <Hidden
                      icon={<FaMoneyCheck size={24} />}
                      iconSize="24px"
                      heading="hidden"
                      description="hidden"
                    />
                    <Hidden
                      icon={<FaMoneyCheck size={24} />}
                      iconSize="24px"
                      heading="hidden"
                      description="hidden"
                    />
                  </>
                )}
              </Grid>
            )}
            {/* display a message and a link if there are no methods on file */}
            {(cards && cards.length < 1 && !loading) && (
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Typography variant="h3" style={{ lineHeight: "3em" }}>
                      No payment methods on file.
                    </Typography>
                    <Typography variant="h4">
                      Click &nbsp;
                      <Link
                        to="/business/payments/add-new-card"
                        style={{ fontWeight: "bold" }}
                      >
                        here
                      </Link>
                      &nbsp; to add a payment method.
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </div>
        </Grid>
        <CardModal
          open={open}
          closeModal={toggleModal}
          loading={modalLoading}
          type={type}
          removeCard={removeCard}
          setAutoPay={setAutoPay}
          removeAutoPay={removeAutoPay}
          confirmationMsg={confirmationMsg}
          err={error}
        />
      </Grid>
    </>
  );
};

export default PaymentMethods;
