import React, { Component } from "react";
import Cards from "react-credit-cards";
import { withRouter } from "react-router-dom";
import { TextField, Button, Grid } from "@material-ui/core";
import {
  catchAxios,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "../utils/utils";
import { FadeIn } from "../animated.js";
// SASS & CSS Styles
import style from "../styles/Payments.module.scss";
import "react-credit-cards/es/styles-compiled.css";
import { FaSpinner } from "react-icons/fa";
import { axiosWrapper } from "../../utils/axiosWrapper.js";
// import FetchPaymentFeeExempt from "./FetchPaymentFeeExempt.jsx";

/**
 * This Component renders a form for adding a new CreditCard.  This component does not take any props. It uses a third party library, {@link https://www.npmjs.com/package/react-credit-cards react-credit-cards}, to render credit card visual while the user fills out their cards information.
 * @module CreditCards
 * @extends Component
 *
 * @param {function} handleSubmit - Handles form submission. Does some basic credit card validation checks, makes sure all required fields are filled out and sends the data to the server to be input into the DB.  Must have a valid JWT to run. Returns a response object from the server.
 * @param {function} handleCallback - Verifies the card number provided belongs to a valid issuer and then stores the issuer in state.
 * @param {function} handleInputFocus - Sets state to focused form field.
 * @param {function} handleInputChange - Handles form field data in state as it changes
 * @param {function} handleServerMsg - Sets the server response to state.
 *
 * @see {@link #FadeIn FadeIn}
 */

const AnimatedDiv = FadeIn("div");

class CreditCards extends Component {
  state = {
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    focused: "",
    formData: null,
    loading: false,
    serverRes: false,
    serverMessage: "",
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    } else if (target.name === "name") {
      // Allow only letters and limit the length to 40
      if (/^[A-Za-z\s]{0,40}$/.test(target.value)) {
        this.setState({ [target.name]: target.value });
        return;
      }
      return;
    }

    this.setState({ [target.name]: target.value });
  };

  handleServerMsg = (msg) => {
    this.setState({
      serverMessage: msg,
      serverRes: true,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let msg;
    this.setState({ loading: true }); //set loading spinner on submit
    const { issuer } = this.state;
    const acceptedIssuers = ["amex", "discover", "visa", "mastercard"];
    if (issuer && !acceptedIssuers.includes(issuer)) {
      msg =
        "Your card cannot be accepted. We are only accepting Visa, MasterCard, Discover, and American Express.";
      this.handleServerMsg(msg);
      this.setState({ loading: false });
    } else if (issuer) {
      const token = localStorage.getItem("token");
      if (token) {
        const hostname = window.location.hostname;

        axiosWrapper(
          hostname,
          "POST",
          "/payments/new-method",
          {
            Authorization: `Bearer ${token}`,
          },
          {
            values: this.state,
            payMethod: "CC",
          }
        )
          .then((response) => {
            if (response.data.status === true) {
              // Redirect to make payment on success
              this.props.history.push({
                pathname: "/business/payments/make-payment",
                state: { status: response.data.msg },
              });
            } else {
              msg = response.data.msg.replace(":", ".");
              this.handleServerMsg(msg);
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            catchAxios(err);
          });
      } else {
        this.props.history.push("/login");
      }
    } else {
      msg =
        "The credit card number you provided is not a valid credit card.  Please check that you entered the correct number and try again.";
      this.handleServerMsg(msg);
      this.setState({ loading: false });
    }
  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;

    const buttonTxt = this.state.loading ? "LOADING" : "CONFIRM";
    const isResponseError = this.state.serverMessage.includes(
      "Your credit card information has been added."
    )
      ? style.success
      : style.error;

    return (
      <>
        <AnimatedDiv className={style.cardPosFix}>
          <Cards
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
          />
        </AnimatedDiv>
        {/* <FetchPaymentFeeExempt>{
          ({ feeExempt, feeExemptLoading }) => {
            if (feeExempt || feeExemptLoading) return null;
            return (
              <div className={style.card_message_box}>
                <div>A 3% administrative processing fee will be applied to all credit card charges.</div>
                <div>For your convenience, customers may avoid this extra fee by paying with Bank Account! Thank You!</div>
              </div>
            );
          }
        }</FetchPaymentFeeExempt> */}
        <AnimatedDiv ref={(c) => (this.form = c)} onSubmit={this.handleSubmit}>
          <Grid item xs={12}>
            <TextField
              type="tel"
              name="number"
              className="form-control"
              pattern="[\d| ]{16,22}"
              value={number}
              required
              InputLabelProps={{ required: false }}
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              variant="outlined"
              fullWidth={true}
              label="Card Number"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="name"
              className="form-control"
              value={name}
              required
              InputLabelProps={{ required: false }}
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              variant="outlined"
              fullWidth={true}
              label="Name"
              margin="normal"
            />
          </Grid>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                type="tel"
                name="expiry"
                className="form-control"
                placeholder="Valid Thru"
                value={expiry}
                pattern="\d\d/\d\d"
                required
                InputLabelProps={{ required: false }}
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                fullWidth={true}
                variant="outlined"
                label="Expiration Date"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                type="tel"
                name="cvc"
                className="form-control"
                placeholder="CVC"
                value={cvc}
                pattern="\d{3,4}"
                required
                InputLabelProps={{ required: false }}
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                fullWidth={true}
                variant="outlined"
                label="CVC"
                margin="normal"
              />
            </Grid>
          </Grid>
          <input type="hidden" name="issuer" value={issuer} />
          {this.state.serverRes && (
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <AnimatedDiv
                  style={{ marginTop: "24px" }}
                  className={isResponseError}
                >
                  {this.state.serverMessage}
                </AnimatedDiv>
              </Grid>
            </Grid>
          )}
          <div>
            <Grid
              className={style.marginFix}
              container
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                onClick={(e) =>
                  this.setState({
                    number: "",
                    name: "",
                    expiry: "",
                    cvc: "",
                    issuer: "",
                    focused: "",
                    formData: null,
                  })
                }
                style={{ width: "48%" }}
              >
                Clear
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={this.handleSubmit}
                style={{ width: "48%" }}
                disabled={this.state.loading}
              >
                {buttonTxt}
                {this.state.loading && (
                  <FaSpinner
                    style={{ marginLeft: "12px" }}
                    className="fa-spin"
                  />
                )}
              </Button>
            </Grid>
          </div>
        </AnimatedDiv>
        {formData && (
          <div className="App-highlight">
            {formatFormData(formData).map((d, i) => (
              <div key={i}>{d}</div>
            ))}
          </div>
        )}
      </>
    );
  }
}

export default withRouter(CreditCards);
