import React from 'react';
import style from './styles/Aside.module.scss';
import { useSelector } from 'react-redux';

function NavLogo() {
  const company = useSelector((state) => state.companyInfo);

  return (
    <div className={style.logo}>
      <img
        src={company.logo}
        alt={company.logoAlt}
      />
    </div>
  )
}

export default NavLogo;
