import React from "react";
import { Switch, Route } from "react-router-dom";
import DocumentsLanding from "./DocumentsLanding.jsx";
import CallDetailRecord from "./CallDetailRecord";
import Invoices from "./Invoices";
import PaymentHistory from "./PaymentHistory";
import Notifications from "./Notifications";
import ArchivedNotifications from "./ArchivedNotifications";
import NotFound from "../NotFound.jsx";

/**
 * Handles routing for the documents section. Resolves any sub-route that starts with /business/documents
 * @module Documents
 */
function Documents() {
  return (
    <>
      <Switch>
        <Route exact path="/business/documents/" component={DocumentsLanding} />
        <Route
          exact
          path="/business/documents/call-details"
          component={CallDetailRecord}
        />
        <Route
          exact
          path="/business/documents/my-invoices"
          component={Invoices}
        />
        <Route
          exact
          path="/business/documents/payment-history"
          component={PaymentHistory}
        />
        <Route
          exact
          path="/business/documents/notifications"
          component={Notifications}
        />
        <Route
          exact
          path="/business/documents/notifications/archived"
          component={ArchivedNotifications}
        />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default Documents;
