import React from "react";
import Particles from "../utils/particleEffect";
import OrderNow from "./OrderNow";

function OrderNowWrapper(props) {
  return (
    <>
      <Particles />
      <OrderNow {...props} />
    </>
  );
}

export default OrderNowWrapper;
