import React from 'react';
import {generateKey} from "./utils/utils";
// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from 'react-redux';
import { closeGlobalModal } from '../Redux/Global/globalActions';

function GlobalModal() {
    const dispatch = useDispatch();
    const globalModal = useSelector((state) => state.globalInfo.globalModal);
    const closeModal = () => {
        dispatch(closeGlobalModal());
    };

    return (
        <Dialog
            open={globalModal.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <>
                <DialogTitle id="alert-dialog-title">{globalModal.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText key={generateKey('d_0')} variant="body1" color="primary" align="center">
                        {globalModal.content}
                    </DialogContentText>
                </DialogContent>
            </>
            <DialogActions>
                <Button onClick={closeModal} variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default GlobalModal;