import React, { useEffect, useState } from "react";
import { catchAxios } from "../../utils/utils.js";
import { axiosWrapper } from "../../../utils/axiosWrapper.js";

const FetchTicketDetails = (props) => {
  const { ticketno, children } = props;

  /**
   * @type {[
   *  {
   *    id: string, queue: string, owner: string, creator: string, subject: string,
   *    status: string, priority: number, initialPriority: number, finalPriority: number,
   *    requestors: string, cc: string, adminCc: string, created: string, starts: string,
   *    started: string, due: string, resolved: string, told: string, lastUpdated: string,
   *    timeEstimated: number, timeWorked: number, timeLeft: number,
   *    customFields: {[key: string]: string},
   *    history: {
   *      id: string, ticket: string, timeTaken: number, type: string, field: string,
   *      oldValue: string, newValue: string, data: string, description: string,
   *      content: string[], creator: string, created: string,
   *      attachments: {id: string, ticket: string, contentType: string, contentEncoding: string, content: string[]}[],
   *      isCustomer: boolean,
   *    }[],
   *   } | null,
   *   React.Dispatch<React.SetStateAction<any>>
   * ]}
   */
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const [error, setError] = useState(null);

  /**
   *
   * @param {boolean} refetching
   */
  const loadData = async (refetching) => {
    try {
      if (refetching) setRefetching(true);
      else setLoading(true);
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;

      const response = await axiosWrapper(
        hostname,
        "GET",
        `/tickets/${ticketno}/history`,
        {
          Authorization: `Bearer ${authToken}`,
        }
      );
      const { data } = response;
      setData(data);
    } catch (err) {
      setError(err);
      catchAxios(err);
    } finally {
      if (refetching) setRefetching(false);
      else setLoading(false);
    }
  };

  const onSubmit = async (comment) => {
    try {
      setRefetching(true);
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;

      const response = await axiosWrapper(
        hostname,
        "POST",
        "/tickets/" + ticketno + "/reply",
        {
          Authorization: `Bearer ${authToken}`,
        },
        { comment: comment }
      );
      if (response.status === 200) {
        await loadData(true);
        setRefetching(false);
        return true;
      }
      console.error("Error adding comment");
      setRefetching(false);
      return false;
    } catch (err) {
      console.error("Error adding comment", err);
      setRefetching(false);
      return false;
    }
  };

  useEffect(() => {
    loadData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {children({
        data,
        loading,
        refetching,
        error,
        ticketno,
        onSubmit,
      })}
    </div>
  );
};

export default FetchTicketDetails;
