import React from "react";
import PropTypes from "prop-types";

const Error = (props) => {
  const { error } = props;
  return (
    <div style={{ padding: "3vh 2vw", color: "#FF8888" }}>
      <h2 style={{ color: "#FF8888" }}>Oops! Something went wrong!</h2>
      <details style={{ whiteSpace: "pre-wrap" }}>
        <p>{error}</p>
      </details>
    </div>
  );
};

Error.propTypes = {
  error: PropTypes.string,
};

export default Error;
