import React, { useState } from "react";
import { Button } from "@material-ui/core";
import styles from "../styles/Tickets/AddNewTicket.module.scss";
import AddTicketModal from "./AddTicketModal";
import AddFinalModal from "./AddFinalModal";
import { axiosWrapper } from "../../utils/axiosWrapper";

const AddNewTicket = (props) => {
  const { refreshFunc = () => {} } = props;

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addModalLoading, setAddModalLoading] = useState(false);
  const [, setAddError] = useState("");

  const handleAddModalOpen = () => {
    setAddModalOpen(true);
  };
  const handleAddModalClose = () => {
    setAddModalOpen(false);
  };

  const [finalModalOpen, setFinalModalOpen] = useState(false);
  const [finalModalTitle, setFinalModalTitle] = useState("");
  const [finalModalContent, setFinalModalContent] = useState("");

  const handleFinalModalOpen = () => {
    setFinalModalOpen(true);
  };
  const handleFinalModalClose = () => {
    setFinalModalOpen(false);
    window.location.reload();
  };

  const handleAddModalSubmit = async (subject, content) => {
    try {
      setAddModalLoading(true);
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;

      const response = await axiosWrapper(
        hostname,
        "POST",
        "/tickets/new",
        {
          Authorization: `Bearer ${authToken}`,
        },
        { subject, content }
      );
      if (response.status === 200) {
        const ticketno =
          response.data && response.data.ticketno ? response.data.ticketno : "";
        setFinalModalTitle("New ticket created");
        setFinalModalContent(`Ticket #${ticketno} has been created.`);
        refreshFunc();
      } else {
        setFinalModalTitle("Error");
        setFinalModalContent(
          response.data.msg
            ? String(response.data.msg)
            : "An error occured while processing your request."
        );
      }
    } catch (err) {
      setAddError(err);
      setFinalModalContent(err);
    } finally {
      handleAddModalClose();
      handleFinalModalOpen();
      setAddModalLoading(false);
    }
  };
  return (
    <div className={styles.add_container}>
      <Button className={styles.add_button} onClick={handleAddModalOpen}>
        Create new ticket
      </Button>
      {addModalOpen && (
        <AddTicketModal
          open={addModalOpen}
          onClose={handleAddModalClose}
          onSubmit={handleAddModalSubmit}
          loading={addModalLoading}
        />
      )}
      {finalModalOpen && (
        <AddFinalModal
          open={finalModalOpen}
          onClose={handleFinalModalClose}
          title={finalModalTitle}
          content={finalModalContent}
        />
      )}
    </div>
  );
};

export default AddNewTicket;
