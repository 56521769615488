import React, { useEffect } from 'react';
import ReloadToken from './ReloadToken';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyByBusinessUnit } from '../Redux/Company/companyActions';
import { setGlobalLoader } from '../Redux/Global/globalActions';
import { setActiveBillingAccount } from '../Redux/AccountInfo/accountInfoActions';

function LoaderPage() {
  const company = useSelector((state) => state.companyInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setGlobalLoader(true));
    ReloadToken().then(async (res) => {
      if (res) {
        dispatch(setCompanyByBusinessUnit(res.business_unit_account));
        dispatch(setActiveBillingAccount(res.billingaccountno));
      }
      dispatch(setGlobalLoader(false));
    }).catch(err => {
      dispatch(setGlobalLoader(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <></>
  );
}

export default LoaderPage;
