import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import "../styles.css";

/* Component Views */
import MyAccount from "./myAccount/MyAccount.jsx";
import Documents from "./documents/Documents";
import Payments from "./payments/Payments";
import BuyNow from "./buyNowUpgrade/BuyNow.js";
import Upgrade from "./buyNowUpgrade/Upgrade.js";
import Help from "./help/Help";
import style from "./styles/MainLayout.module.scss";
import NestedDrawer from "./NestedDrawer.js";
import SimpleNav from "./SimpleNav.js";
import MultipleAccounts from "./myAccount/MultipleAccounts";
import NotFound from "./NotFound.jsx";
import ProtectedRoute from "./auth/ProtectedRoute.js";

/**
 * Handles main routing for the nested drawer (aside navigation). The aside is not visible if user is at /business.
 * @module BusinessAccount
 */
const BusinessAccount = (props) => {
  const routerLocation = props.history.location.pathname;

  if (routerLocation === "/business/") {
    return <Redirect to="/business" />;
  }

  return (
    <div className={style.layout}>
      {routerLocation !== "/business" ? (
        <NestedDrawer />
      ) : (
        <SimpleNav />
      )}
      <div className={style.gridFix}>
        <div>
          <Switch>
            <Route
              exact
              path="/business"
              component={MultipleAccounts}
            />
            <Route path="/business/my-account" component={MyAccount} />
            <ProtectedRoute path="/business/documents" component={Documents} fallback="/business/my-tickets" />
            <ProtectedRoute path="/business/payments" component={Payments} fallback="/business/my-tickets" />
            <ProtectedRoute path="/business/buy-now" component={BuyNow} fallback="/business/my-tickets" />
            <ProtectedRoute path="/business/upgrade" component={Upgrade} fallback="/business/my-tickets" />
            <Route path="/business/help" component={Help} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

// Let drawer access router location
const BusinessAccountWithRouter = withRouter(BusinessAccount);
export default BusinessAccountWithRouter;
