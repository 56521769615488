import React, { useEffect } from 'react';
import { Container, Grid, Card, CardContent, CardActions, Button, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { setSelectedService } from "../../Redux/CustomerInfo/customerInfoActions";
import './ServiceRequest.scss';

function ServiceRequest({ showError }) {
  const params = useParams();
  const haveStep = 'step' in params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const selectedPlan = useSelector((state) => state.customerInfo.selectedService);

  const changeRouter = () => {
    if (!haveStep) {
      const newParams = new URLSearchParams(location.search);
      newParams.set('step', 'Service Request');
      history.push({ pathname: location.pathname, search: newParams.toString() });
    }
  };

  useEffect(() => {    
    changeRouter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plans = [
    { id: 1, name: '50 mb/s', price: '$50/month' },
    { id: 2, name: '100 mb/s', price: '$100/month' },
    { id: 3, name: '150 mb/s', price: '$150/month' },
  ];

  const handleCardClick = (id, name) => {
    dispatch(setSelectedService('serviceId', id));
    dispatch(setSelectedService('serviceName', name));
  };

  return (
    <Container>
      {showError && !selectedPlan.serviceId && (
        <Typography variant="body2" className="error-text" color="error" align="center">
          Please select a service plan!
        </Typography>
      )}
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan) => (
          <Grid item key={plan.id} xs={12} sm={6} md={4} lg={3}>
            <Card
              className={`service-card ${selectedPlan.serviceId === plan.id ? 'selected' : ''}`}
              onClick={() => handleCardClick(plan.id, plan.name)}
            >
              <CardContent className="text-center">
                <Typography variant="h5" component="h3" gutterBottom>
                  {plan.name}
                </Typography>
                <Typography variant="body1" className="mb-3">
                  {plan.price}
                </Typography>
              </CardContent>
              <CardActions className="justify-content-center">
                <Button variant="contained" color={selectedPlan.serviceId === plan.id ? 'secondary' : 'primary'}>
                  {selectedPlan.serviceId === plan.id ? 'Selected' : 'Select'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ServiceRequest;
