import * as accountInfoType from "./accountInfoType";

const START_STATE = {
  customer: {},
  activeBillingAccount: "",
  paymentOptions: [],
  balance: "",
  businessUnit: "",
  voice: false,
};

const accountInfoReducer = (state = START_STATE, action) => {
  switch (action.type) {
    case accountInfoType.SET_CUSTOMER:
      return { ...state, customer: action.payload };
    case accountInfoType.LOGOUT_USER:
      return { ...state, customer: {} };
    case accountInfoType.SET_CUSTOMER_DETAILS:
      return {
        ...state,
        customer: { ...state.customer, detailsData: action.payload },
      };
    case accountInfoType.SET_ACTIVE_BILLING_ACCOUNT:
      return { ...state, activeBillingAccount: action.payload };
    case accountInfoType.SET_PAYMENT_OPTIONS:
      return { ...state, paymentOptions: action.payload };
    case accountInfoType.REMOVE_PAYMENT_OPTION:
      const newOptions = state.paymentOptions.filter(
        (x) => x.pay_account_id !== action.payload
      );
      return newOptions.length !== state.paymentOptions.length
        ? { ...state, paymentOptions: newOptions }
        : state;
    case accountInfoType.SET_BALANCE:
      return { ...state, balance: action.payload };
    case accountInfoType.SET_VOICE:
      return { ...state, voice: action.payload };
    case accountInfoType.SET_BUSINESS_UNIT:
      return { ...state, businessUnit: action.payload };
    default:
      return state;
  }
};

export default accountInfoReducer;
