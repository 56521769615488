import * as accountInfoType from "./accountInfoType";

export const setCustomer = (value) => ({
  type: accountInfoType.SET_CUSTOMER,
  payload: value,
});

export const logoutUser = () => ({
  type: accountInfoType.LOGOUT_USER,
});

export const removePaymentOption = (value) => ({
  type: accountInfoType.REMOVE_PAYMENT_OPTION,
  payload: value,
});

export const setActiveBillingAccount = (value) => ({
  type: accountInfoType.SET_ACTIVE_BILLING_ACCOUNT,
  payload: value,
});

export const setBalance = (value) => ({
  type: accountInfoType.SET_BALANCE,
  payload: value,
});

export const setCustomerDetails = (value) => ({
  type: accountInfoType.SET_CUSTOMER_DETAILS,
  payload: value,
});

export const setPaymentOptions = (value) => ({
  type: accountInfoType.SET_PAYMENT_OPTIONS,
  payload: value,
});

export const setVoice = (value) => ({
  type: accountInfoType.SET_VOICE,
  payload: value,
});

export const setBusinessUnit = (value) => ({
  type: accountInfoType.SET_BUSINESS_UNIT,
  payload: value,
});
