import React from 'react';
import style from '../styles/HelpSection.module.scss';
import {Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux';

/**
 *  This Component displays the pertinent contact information including Corporate Headquarters location and contact, sales support contact, customer support contact, technical support contact, billing inquires contact, and customer portal help contact.  The contact information provided is pulled from the redux and will display the correct information depending on the subsidiary website visited.
 *  @module ContactsVertical
 *
 */

const useStyles = makeStyles(theme => ({
  borderColor: {
    border: `1px solid ${theme.palette.primary.main}`
  }
}))


function ContactVertical() {
  const company = useSelector((state) => state.companyInfo);
  const classes = useStyles();

  return (
    <ul className={style.contactList}>
      <Typography className={style.title} component="h2" color="primary">Other Contacts</Typography>
      <li className={`${style.listItem} ${classes.borderColor}`}>
        <h3 className={style.listHeading}>General Inquiries</h3>
        <p>{company.address.street || ''}, {company.address.suite || ''}</p>
        <p>{company.address.city || ''}, {company.address.state_short || ''} {company.address.zip || ''}</p>
        <p><a href={`mailto:${company.email}`} >{company.email}</a></p>
      </li>
      <li className={`${style.listItem} ${classes.borderColor}`}>
        <h3 className={style.listHeading}>Sales Support</h3>
        <p><a href={`mailto:${company.salesEmail}`}>{company.salesEmail}</a></p>
      </li>
      <li className={`${style.listItem} ${classes.borderColor}`}>
        <h3 className={style.listHeading}>Customer Support</h3>
        <p>{company.phone}</p>
        <p><a href={`mailto:${company.supportEmail}`}>{company.supportEmail}</a></p>
      </li>
      <li className={`${style.listItem} ${classes.borderColor}`}>
        <h3 className={style.listHeading}>Technical Support</h3>
        <p><a href={`mailto:${company.supportEmail}`}>{company.supportEmail}</a></p>
      </li>
      <li className={`${style.listItem} ${classes.borderColor}`}>
        <h3 className={style.listHeading}>Billing Inquiries</h3>
        <p><a href={`mailto:${company.billingEmail}`}>{company.billingEmail}</a></p>
      </li>
      <li className={`${style.listItem} ${classes.borderColor}`}>
        <h3 className={style.listHeading}>Customer Portal Help Line</h3>
        <p>{company.portalHelpLine}</p>
      </li>
    </ul>
  );
}

export default ContactVertical;
