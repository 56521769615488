import React, { useEffect } from "react";
import qs from "qs";
import { withRouter } from "react-router";
import { generateKey } from "./utils/utils.js";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "./Loader.jsx";
import Loading from "./Loading.jsx";
import { Link } from "react-router-dom";
import { catchAxios } from "./utils/utils";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { fadeAndFallEndlessTable } from "./animated.js";
// Material Ui
import Grid from "@material-ui/core/Grid";
import {
  withTheme,
  Paper,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
//SASS Styles
import style from "./styles/Table.module.scss";
import { axiosWrapper } from "../utils/axiosWrapper.js";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationCountInfo } from "../Redux/NotificationInfo/notificationInfoActions.js";

const AnimatedTable = fadeAndFallEndlessTable(InfiniteScroll);

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.main,
  },
  header: {
    background: theme.palette.primary.main,
    width: "100%",
    fontWeight: "bold",
    fontSize: "14px",
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: "0.9em",
  },
  data: {
    color: theme.palette.grey[400],
  },
}));

const EndlessNotificationTable = (props) => {
  const length = props.rows[0] ? props.rows[0].length : 0;
  const loading = props.loading;
  const classes = useStyles();
  const accountInfo = useSelector((state) => state.accountInfo);
  const dispatch = useDispatch();
  let location = props.history.location.pathname;

  const styles = {
    table: {
      height: props.tableHeight,
      overflow: "auto",
    },
    cellWidth: {
      minWidth: `${props.minCellWidth}px`,
      width: `${(1 / length) * 100}%`,
    },
    headerWidth: {
      minWidth: `${props.minCellWidth * length}px`,
      textAlign: "center",
    },
    rowWidth: {
      minWidth: `${props.minCellWidth * length}px`,
    },
    headers: {
      minWidth: `${props.minCellWidth}px`,
      width: `${(1 / length) * 100}%`,
      backgroundColor: props.theme.palette.primary.main,
    },
    title: {
      fontWeight: 600,
      fontSize: "0.9em",
    },
  };

  const loadNotificationsCount = async () => {
    try {
      const authToken = localStorage.getItem("token");
      const hostname = window.location.hostname;
      const response = await axiosWrapper(
        hostname,
        "GET",
        `/notifications/my/unread/${accountInfo.activeBillingAccount}`,
        {
          Authorization: `Bearer ${authToken}`,
        }
      );
      const { data } = response;
      dispatch(setNotificationCountInfo(data.data));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadNotificationsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get invoice document
  const handleSubmit = (file, dataFull, rowIndex) => {
    const data = {
      fileName: file,
    };

    const authToken = localStorage.getItem("token");
    if (authToken) {
      // Get and download file
      const hostname = window.location.hostname;

      axiosWrapper(
        hostname,
        "POST",
        "/notifications/document",
        {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${authToken}`,
        },
        qs.stringify(data),
        true,
        "blob"
      )
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", file);
          document.body.appendChild(link);
          link.click();
          link.remove();

          axiosWrapper(
            hostname,
            "PUT",
            `/notifications/${dataFull[rowIndex].id}/read/${accountInfo.activeBillingAccount}`,
            {
              Authorization: `Bearer ${authToken}`,
            }
          )
            .then((response) => {
              loadNotificationsCount();
              props.refreshData();
            })
            .catch((err) => {
              catchAxios(err);
            });
        })
        .catch((err) => {
          catchAxios(err);
        });
    }
  };

  return (
    <div
      className={style.tableContainer}
      style={{ overflowX: "auto", maxWidth: "100%", maxHeight: "100%" }}
    >
      <div style={styles.rowWidth} className={classes.background}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          {props.headers.length > 1 ? (
            props.headers.map((title, i) => {
              return (
                <Paper
                  className={` ${classes.header} ${style.cell} `}
                  style={styles.cellWidth}
                  square={true}
                  key={generateKey(title + i)}
                >
                  <Typography color="textSecondary" className={classes.title}>
                    {title}
                  </Typography>
                </Paper>
              );
            })
          ) : (
            <div className={style.cell} style={styles.headerWidth}>
              {props.headers[0]}
            </div>
          )}
        </Grid>
      </div>
      <div style={styles.rowWidth}>
        <Grid item xs={12}>
          {loading ? (
            <Loader height="48px" />
          ) : props.rows && props.rows.length > 0 ? (
            <AnimatedTable
              style={{ overflowY: "overlay" }}
              dataLength={props.rows.length}
              next={props.next}
              hasMore={props.hasMore}
              loader={
                <div className={style.endlessFooter}>
                  <Loading loading={true} loadingText="Loading" />
                </div>
              }
              height={`${props.tableHeight}px`}
              endMessage={
                <div
                  className={style.endlessFooter}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h5" color="primary" gutterBottom>
                    No more records...
                  </Typography>
                  {location === "/business/documents/notifications" ||
                    (location === "/business/documents/notifications/" && (
                      <Link to="/business/documents/notifications/archived">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ width: "100%" }}
                        >
                          Archive
                        </Button>
                      </Link>
                    ))}
                </div>
              }
              startheight="44px"
              endheight={props.tableHeight}
            >
              {props.rows.map((invoice, index) => {
                return (
                  <div
                    className={style.row}
                    key={generateKey(`${invoice[0]}+${index}`)}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {invoice.map((item, i) => {
                        // This is file
                        if (i === 1) {
                          return (
                            <div
                              className={`${style.data} ${style.cell} ${style.download}`}
                              style={styles.cellWidth}
                              key={generateKey(item + i)}
                              onClick={() =>
                                handleSubmit(item, props.dataFull, index)
                              }
                            >
                              {item}
                            </div>
                          );
                        }
                        if (i === 3) {
                          return (
                            <div
                              className={`${style.data} ${style.cell}`}
                              style={styles.cellWidth}
                              key={generateKey(item + i)}
                            >
                              {item === "Read" && (
                                <VisibilityIcon
                                  className={`${style.visibilityIcon} ${style.green}`}
                                />
                              )}
                              {item === "Unread" && (
                                <VisibilityOffIcon
                                  className={`${style.visibilityIcon} ${style.red}`}
                                />
                              )}
                              {item === "NO_STATUS" ? "" : item}
                            </div>
                          );
                        }
                        return (
                          <div
                            className={`${style.data} ${style.cell}`}
                            style={styles.cellWidth}
                            key={generateKey(item + i)}
                          >
                            {item}
                          </div>
                        );
                      })}
                    </Grid>
                  </div>
                );
              })}
            </AnimatedTable>
          ) : (
            <div style={{ padding: "12px 8px", backgroundColor: "white" }}>
              {`No ${props.dataName} available.`}
            </div>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(withTheme(EndlessNotificationTable));
