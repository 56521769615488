import React, { useRef, useState } from "react";
import { catchAxios } from "./utils/utils";
import { Formik, Form, ErrorMessage } from "formik";
import Loading from "./Loading.jsx";
import { FadeIn } from "./animated.js";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";
import style from "./styles/ContactCard.module.scss";
import * as Yup from "yup";
import ErrorBoundary from "./ErrorBoundary";
import { axiosWrapper } from "../utils/axiosWrapper.js";
import { useSelector } from "react-redux";

const initialState = {
  firstnameContacts: "",
  lastnameContacts: "",
  emailContacts: "",
  telephoneContacts: "",
  orgid: null,
  isVerified: {
    status: false,
    msg: "",
  },
};

const AnimatedError = FadeIn(ErrorMessage);

const phoneRegExp =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?(\d{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
const formValidation = Yup.object().shape({
  firstnameContacts: Yup.string()
    .min(2, "Your first name must be at least 2 characters long.")
    .required("Your first name is required"),
  lastnameContacts: Yup.string()
    .min(2, "Your last name must be at least 2 characters long.")
    .required("Your last name is required"),
  emailContacts: Yup.string()
    .email("It should be a valid email address.")
    .required("Your email is required."),
  telephoneContacts: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Your phone is required."),
});

const ChangeContactDetails = (props) => {
  const [formType, setFormType] = useState(props.type);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const accountInfo = useSelector((state) => state.accountInfo);
  const formik = useRef(null);

  // fetch the data from the session
  const loadData = async () => {
    const authToken = localStorage.getItem("token");
    const accountNo = accountInfo.activeBillingAccount;
    setIsLoading(true);
    const hostname = window.location.hostname;
    return axiosWrapper(hostname, "POST", "/profile/locations-list",
      { Authorization: `Bearer ${authToken}` },
      { accountNo }
    );
  };

  // refresh the data.
  const refresh = async () => {
    let fType;
    loadData().then((res) => {
      const data = res.data.data;
      if (res.data.status && state.orgid !== null) {
        if (formType === "tech") {
          data.filter((item) => {
            if (item.orgid === state.orgid) {
              fType = item.tech_contact_data;
            }
            return null;
          });
        } else if (formType === "site") {
          data.filter((item) => {
            if (item.orgid === state.orgid) {
              fType = item.site_contact_data;
            }
            return null;
          });
        }
        if (fType) {
          setState({
            firstnameContacts: fType.firstname,
            lastnameContacts: fType.lastname,
            emailContacts: fType.email,
            telephoneContacts: fType.telephone,
          });
        }
        setIsLoading(false);
      }
    }).catch((err) => {
      setIsLoading(false);
      setState((prevState) => ({
        ...prevState,
        isVerified: { status: false, msg: err },
      }));
      catchAxios(err);
    });
  };

  const handleClickOpen = async () => {
    await refresh();
    // sets the orgid for the active property we want to change contacts for.
    setDialogIsOpen(true);
    setState((prevState) => ({
      ...prevState,
      orgid: props.orgid,
    }));
  };

  const handleReset = () => {
    if (formik.current) {
      formik.current.resetForm();
    }
  };

  const handleClose = () => {
    setDialogIsOpen(false);
  };

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const hostname = window.location.hostname;
    const data = { ...values };
    axiosWrapper(hostname, "POST", "/profile/update-contact-info",
      { Authorization: `Bearer ${token}` },
      { ...data }
    ).then((res) => {
      const data = res.data;
      setIsLoading(false);
      setState((prevState) => ({
        ...prevState,
        isVerified: { status: data.status, msg: data.msg },
      }));
      if (data.status) {
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            isVerified: { status: false, msg: '' },
          }));
          handleClose();
        }, 2500);
      }
    }).catch((err) => {
      setIsLoading(false);
      setState((prevState) => ({
        ...prevState,
        isVerified: { status: data.status, msg: `There was an error! ${data.msg}` },
      }));
      catchAxios(err);
    });
  };

  return (
    <div>
      <ErrorBoundary>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          style={{
            minWidth: "90%",
            margin: "0 auto",
            display: "block",
          }}
        >
          {props.type} Contact
        </Button>
        <Dialog
          open={dialogIsOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Formik
            ref={formik}
            enableReinitialize={true}
            initialValues={{
              ...state,
            }}
            validationSchema={formValidation}
            onSubmit={handleSubmit}
            onReset={handleReset}
            render={(resetForm) => (
              <Form
                id={props.contact}
                noValidate
                autoComplete="off"
                style={{ margin: "12px" }}
              >
                <Grid container spacing={0} justifyContent="space-between">
                  <Grid item xs={12}>
                    <DialogTitle
                      id="alert-dialog-slide-title"
                      style={{ textAlign: "center" }}
                    >
                      {props.type === "site"
                        ? "Change Site Contact Details"
                        : "Change Tech Contact Details"}
                    </DialogTitle>
                  </Grid>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Grid item sm={6} xs={12}>
                        <TextField
                          id="firstnameContacts"
                          name="firstnameContacts"
                          label="First Name"
                          onChange={handleChange}
                          value={state.firstnameContacts}
                          margin="normal"
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                        <AnimatedError
                          name="firstnameContacts"
                          component="div"
                          className={style.error}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          id="lastnameContacts"
                          name="lastnameContacts"
                          label="Last Name"
                          onChange={handleChange}
                          value={state.lastnameContacts}
                          margin="normal"
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                        <AnimatedError
                          name="lastnameContacts"
                          component="div"
                          className={style.error}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="emailContacts"
                          name="emailContacts"
                          label="Email Address"
                          onChange={handleChange}
                          value={state.emailContacts}
                          margin="normal"
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                        <AnimatedError
                          name="emailContacts"
                          component="div"
                          className={style.error}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="telephoneContacts"
                          name="telephoneContacts"
                          label="Phone"
                          onChange={handleChange}
                          value={state.telephoneContacts}
                          margin="normal"
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                        <AnimatedError
                          name="telephoneContacts"
                          component="div"
                          className={style.error}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Grid>
                <DialogActions
                  style={{
                    gridColumn: "1/-1",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    justifyContent: "space-between",
                    margin: "0 16px",
                  }}
                >
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    <Loading
                      loading={isLoading}
                      loadingText="Loading"
                      submitText="SUBMIT"
                    />
                  </Button>
                  <div
                    className={
                      state.isVerified.status
                        ? style.success
                        : style.error
                    }
                  >
                    {state.isVerified.msg}
                  </div>
                </DialogActions>
              </Form>
            )}
          />
        </Dialog>
      </ErrorBoundary>
    </div>
  );
};

export default ChangeContactDetails;
