import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import PaymentHistory from '../documents/PaymentHistory.js'
import MakeAPayment from "./MakeAPayment"
import AddNewPaymentMethod from './AddNewPaymentMethod'
import PaymentMethods from './PaymentMethods'
import NotFound from '../NotFound.jsx';
import ProtectedRoute from '../auth/ProtectedRoute.js'

/**
 *  This is the primary navigation component for the Payments section of the Customer Portal.
 *  @module Payments
 *  @see {@link module:MakeAPayment MakeAPayment}, </br> {@link module:AddNewPaymentMethod AddNewPaymentMethod}, </br> {@link module:PaymentMethods PaymentMethods}, </br> {@link module:PaymentHistory PaymentHistory}, </br> {@link https://reacttraining.com/react-router/web/guides/quick-start react-router-dom}
 */
const Payments = (props) => {
    return (
        <>
            {props.location.pathname === "/business/payments" && <Redirect to="/business/payments/make-payment"/>}
            {props.location.pathname === "/business/payments/" && <Redirect to="/business/payments/make-payment"/>}
            <Switch>
                <ProtectedRoute exact path="/business/payments" fallback="/business/my-tickets" component={MakeAPayment}/>
                <ProtectedRoute exact path="/business/payments/make-payment" fallback="/business/my-tickets" component={MakeAPayment}/>
                <ProtectedRoute exact path="/business/payments/add-new-card" fallback="/business/my-tickets" component={AddNewPaymentMethod}/>
                <ProtectedRoute exact path="/business/payments/payment-methods" fallback="/business/my-tickets" component={PaymentMethods}/>
                <ProtectedRoute exact path="/business/payments/payment-history" fallback="/business/my-tickets" component={PaymentHistory}/>
                <Route component={NotFound}/>
            </Switch>
        </>
    )
};

export default Payments;

