import styled from "styled-components";

/**
 *  Wraps a component with a 'FadeIn' animation.  To be used with an existing component to allow for animations upon mounting.
 *  @param {Component} - example: const FadeInMyComponent = FadeIn(MyComponent)
 */
export function FadeIn(component) {
  return styled(component)`
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: ${(props) => `${props.delay}`};
    animation-duration: .6s;
    animation-fill-mode: forwards;

    @keyframes fadeIn {
    0% {
        opacity: 0
        transform: translateY(-10px)
      }
    70% {
        transform: translateY(0px)
      }
    100% {
        opacity: 1
      }
    }
    `;
}

/**
 *  Wraps a component with a 'enterComponent' animation.  To be used with an existing component to allow for animations upon mounting.
 *  @param {Component} - example: const EnterComponent = enterComponent(MyComponent)
 */
export function enterComponent(component) {
  return styled(component)`
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: ${(props) => `${props.delay}`};
    animation-duration: .6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;

    @keyframes fadeIn {
    0% {
        opacity: 0
        transform: translateY(20px)
      }
    90% {
        transform: translateY(0px)
      }
    100% {
        opacity: 1
      }
    }
    `;
}

/**
 *  Wraps a component with a 'fadeAndFall' animation.  To be used with an existing component to allow for animations upon mounting.
 *  @param {Component} - example: const StyledComponent = fadeAndFall(MyComponent)
 */
export function fadeAndFall(component) {
  return styled(component)`
    opacity: 0;
    animation-name: fadeAndGrow;
    animation-delay: ${(props) => `${props.delay}`};
    animation-duration: ${(props) =>
      props.duration ? `${props.duration}` : "0.5s"};
    animation-fill-mode: forwards;

    @keyframes fadeAndGrow {
      0% {
        opacity: 0;
        max-height: ${(props) => `${props.startheight}`};
      }
      100% {
        opacity: 1;
        max-height: ${(props) => `${props.endheight}`};
      }
    }
  `;
}

// Duplicate styled component
// Duplicate animation names cause conflicts
export function fadeAndFallEndlessTable(component) {
  return styled(component)`
    opacity: 0;
    animation-name: fadeAndGrowEndlessTable;
    animation-delay: ${(props) => `${props.delay}`};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    @keyframes fadeAndGrowEndlessTable {
      0% {
        opacity: 0;
        max-height: ${(props) => `${props.startheight}`};
      }
      100% {
        opacity: 1;
        max-height: ${(props) => `${props.endheight}`};
      }
    }
  `;
}

/**
 *  Wraps a component with a 'transitionComponent' animation.  To be used with an existing component to allow for animations upon mounting. This animation will most likely need to be used with the {@link module.DelayUnmounting DelayUnmounting} HOC in order for the animation to be visible upon unmounting.
 *  @param {Component} - example: const StyledComponent = transitionComponent(MyComponent)
 *  @see {@link module:DelayUnmounting DelayUnmounting}
 */
export function transitionComponent(component) {
  return styled(component)`
    animation-name: ${(props) => `${props.ismounted}`};
    animation-delay: ${(props) => `${props.delay}`};
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    @keyframes bounceIn {
      0% {
        transform: translateY(-2000px);
        opacity: 0;
      }
      60% {
        transform: translateY(40px);
        opacity: 1;
      }
      100% {
        transform: translateY(0px);
      }
    }

    @keyframes bounceOut {
      100% {
        transform: translateY(2000px);
        opacity: 0;
      }
    }
  `;
}

export function transitionFade(component) {
  return styled(component)`
    animation-name: fadeIn;
    animation-delay: ${(props) => `${props.delay}`};
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fadeOut {
      100% {
        opacity: 0;
      }
    }
  `;
}

export function bounceOut(component) {
  return styled(component)`
    opacity: 0;
    animation-name: bounceOut;
    animation-delay: ${(props) => `${props.delay}`};
    animation-duration: 2s;
    animation-fill-mode: forwards;

    @keyframes bounceOut {
      20% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(0.1);
        opacity: 0;
      }
    }
  `;
}
