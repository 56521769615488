import React from "react";
import SectionTitle from "../SectionTitle.jsx";
import ContactVertical from "./ContactsVertical";
import Loader from "../Loader.jsx";
import { FadeIn } from "../animated.js";
// Material Ui components
import {
  Grid,
  Paper,
  Button,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
} from "@material-ui/core";
// Forms and Validation
import { withFormik } from "formik";
import * as yup from "yup";
// SASS Styles
import style from "../styles/HelpSection.module.scss";
import { catchAxios } from "../utils/utils";
import ErrorBoundary from "../ErrorBoundary";
import LoaderPage from "../LoaderPage";
import { axiosWrapper } from "../../utils/axiosWrapper.js";
import { useSelector } from "react-redux";

/**
 *  This Component renders a {@link https://jaredpalmer.com/formik/docs/api/withformik withFormik()} form that allows the user to send a message directly to support staff. Upon form submission an email will be sent to the selected department with the users message and a modal will pop-up to confirm that the message as been sent.
 *  @module ContactUsForm
 *  @param {Object} props - Component receives the {@link https://jaredpalmer.com/formik/docs/api/withformik#the-formikbag formik-bag} as props
 *  @see {@link module:ContactsVertical ContactsVertical}, </br> {@link https://jaredpalmer.com/formik/docs/api/withformik withFormik()}
 */

const AnimatedDiv = FadeIn("div");

const departments = [
  { id: 0, department: "Sales" },
  { id: 1, department: "Support" },
  { id: 2, department: "Billing" },
  { id: 3, department: "Customer" },
  { id: 4, department: "Technical" },
  { id: 5, department: "Other" },
];

export function ContactUsForm(props) {
  const company = useSelector((state) => state.companyInfo);

  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    status,
    setStatus,
    setValues,
    isSubmitting,
  } = props;

  values.property = company;

  return (
    <>
      <LoaderPage />
      <ErrorBoundary>
        <SectionTitle variant="h1" style={style.welcome} title="Contact Us" />
      </ErrorBoundary>
      <AnimatedDiv className={style.formContainer}>
        <Grid
          spacing={2}
          container
          className={`${style.container} ${style.flex}`}
          style={{ margin: "0 1vw", minHeight: "auto", maxWidth: "100%" }}
        >
          <Grid item xs={12} md={9} lg={8} style={{ flex: 1 }}>
            <Paper className={style.paperForm}>
              <ErrorBoundary>
                <form
                  className={style.contactForm}
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl>
                    <InputLabel htmlFor="age-simple">Department</InputLabel>
                    <Select
                      value={values.department}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{
                        name: "department",
                        id: "department",
                      }}
                      id="department"
                      label="department"
                    >
                      {departments.map((dep) => (
                        <MenuItem value={`${dep.department}`} key={dep.id}>
                          {dep.department}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.department && touched.department && (
                    <AnimatedDiv className={style.error}>
                      {errors.department}
                    </AnimatedDiv>
                  )}
                  <TextField
                    id="name"
                    label="Name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    style={style}
                  />
                  {errors.name && touched.name && (
                    <AnimatedDiv className={style.error}>
                      {errors.name}
                    </AnimatedDiv>
                  )}
                  <TextField
                    id="email"
                    label="Email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    style={style}
                  />
                  {errors.email && touched.email && (
                    <AnimatedDiv className={style.error}>
                      {errors.email}
                    </AnimatedDiv>
                  )}
                  <TextField
                    id="subject"
                    label="Subject"
                    value={values.subject}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    style={style}
                  />
                  {errors.subject && touched.subject && (
                    <AnimatedDiv className={style.error}>
                      {errors.subject}
                    </AnimatedDiv>
                  )}
                  <TextField
                    id="message"
                    label="Message"
                    multiline
                    rows="4"
                    value={values.message}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                  {errors.message && touched.message && (
                    <AnimatedDiv className={style.error}>
                      {errors.message}
                    </AnimatedDiv>
                  )}
                  {isSubmitting && (
                    <div style={{ margin: "8px" }}>
                      <Loader />
                    </div>
                  )}
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ maxHeight: "24px", margin: "12px 0" }}
                  >
                    <Button
                      variant="contained"
                      type="reset"
                      style={{ width: "48%" }}
                      disabled={isSubmitting}
                      onClick={() =>
                        setValues({
                          name: "",
                          email: "",
                          subject: "",
                          department: "",
                          message: "",
                        })
                      }
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ width: "48%" }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "loading" : "Submit"}
                    </Button>
                  </Grid>
                </form>
              </ErrorBoundary>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper className={style.paperList}>
              <ErrorBoundary>
                <ContactVertical />
              </ErrorBoundary>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          open={
            !status || !status.open || !status.message ? false : status.open
          }
          onClose={() => setStatus({ open: false })}
          aria-labelledby="simple-dialog-title"
        >
          <div className={style.confirmationMessage}>
            {status && status.message && (
              <>
                <p>{status.message}</p>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setStatus({ open: false })}
                >
                  CLOSE
                </Button>
              </>
            )}
          </div>
        </Dialog>
      </AnimatedDiv>
    </>
  );
}

const FormikRegisterForm = withFormik({
  mapPropsToValues: (props) => {
    return {
      name: "",
      email: "",
      subject: "",
      department: "",
      message: "",
      property: "",
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().min(2).required("This is a required field."),
    email: yup
      .string()
      .email("It must be a valid email.")
      .required("This is a required field."),
    subject: yup.string().required("This is a required field."),
    department: yup.string().required("This is a required field."),
    message: yup
      .string()
      .min(20, "Minimum 20 characters")
      .required("This is a required field."),
  }),
  handleSubmit(values, { setSubmitting, setStatus, resetForm }) {
    const authToken = localStorage.getItem("token");
    setSubmitting(true);
    const hostname = window.location.hostname;

    axiosWrapper(
      hostname,
      "POST",
      "/contact-us",
      {
        Authorization: `Bearer ${authToken}`,
      },
      {
        ...values,
      }
    )
      .then((res) => {
        let validationMessage = res.data.msg;
        if (res.data.status) {
          resetForm();
          setStatus({ open: true, message: validationMessage });
          setSubmitting(false);
        } else {
          setStatus({ open: true, message: validationMessage });
          setSubmitting(false);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        catchAxios(err);
      });
  },
})(ContactUsForm);

export default FormikRegisterForm;
