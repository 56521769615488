import React from "react";
import { Grid } from "@material-ui/core";
import ErrorBoundary from "../ErrorBoundary";
import { enterComponent, FadeIn } from "../animated.js";
import { FetchTicketSummary } from "./fetch";
import styles from "../styles/Tickets/Summary.module.scss";
import Loading from "../Loading.jsx";
import { STATUSES } from "./constants";
import { Error } from "../common/index.jsx";
import Tickets from "./Tickets.jsx";
import AddNewTicket from "./AddNewTicket.jsx";
import jwt from "jsonwebtoken";

const AnimateGrid = enterComponent(Grid);

const FadeWrapper = FadeIn("div");

const Summary = (props) => {
  const isAdmin = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;
    const decoded = jwt.decode(token);
    if (!decoded.user) return false;
    return !!(decoded.user.isAdmin);
  };

  return (
    <div>
      <FetchTicketSummary>
        {({ data, error, loading }) => {
          if (loading) {
            return (
              <div className={`${styles.container} ${styles.loader}`}>
                <Loading
                  loading={loading}
                  loadingText="Loading ticket summary"
                  submitText="SUBMIT"
                />
              </div>
            );
          }
          if (error) {
            return <Error error={error.toString()} />;
          }
          return (
            <div className={styles.container}>
              <div className={styles.details}>
                <Grid container spacing={1}>
                  <AnimateGrid item xs={12} sm={12} md={4}>
                    <ErrorBoundary>
                      <FadeWrapper className={styles["detail-container"]}>
                        <div className={styles.title}>Number of tickets:</div>
                        <div className={styles.info}>
                          {data[STATUSES.TOTAL]}
                        </div>
                      </FadeWrapper>
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <FadeWrapper className={styles["detail-container"]}>
                        <div className={styles.title}>
                          Number of new tickets:
                        </div>
                        <div className={styles.info}>{data[STATUSES.NEW]}</div>
                      </FadeWrapper>
                    </ErrorBoundary>
                  </AnimateGrid>
                  <AnimateGrid item xs={12} sm={12} md={4}>
                    <ErrorBoundary>
                      <FadeWrapper className={styles["detail-container"]}>
                        <div className={styles.title}>
                          Number of open tickets:
                        </div>
                        <div className={styles.info}>{data[STATUSES.OPEN]}</div>
                      </FadeWrapper>
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <FadeWrapper className={styles["detail-container"]}>
                        <div className={styles.title}>
                          Number of stalled tickets:
                        </div>
                        <div className={styles.info}>
                          {data[STATUSES.STALLED]}
                        </div>
                      </FadeWrapper>
                    </ErrorBoundary>
                  </AnimateGrid>
                  <AnimateGrid item xs={12} sm={12} md={4}>
                    <ErrorBoundary>
                      <FadeWrapper className={styles["detail-container"]}>
                        <div className={styles.title}>
                          Number of resolved tickets:
                        </div>
                        <div className={styles.info}>
                          {data[STATUSES.RESOLVED]}
                        </div>
                      </FadeWrapper>
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <FadeWrapper className={styles["detail-container"]}>
                        <div className={styles.title}>
                          Number of rejected tickets:
                        </div>
                        <div className={styles.info}>
                          {data[STATUSES.REJECTED]}
                        </div>
                      </FadeWrapper>
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <FadeWrapper className={styles["detail-container"]}>
                        <div className={styles.title}>
                          Number of deleted tickets:
                        </div>
                        <div className={styles.info}>
                          {data[STATUSES.DELETED]}
                        </div>
                      </FadeWrapper>
                    </ErrorBoundary>
                  </AnimateGrid>
                </Grid>
              </div>
            </div>
          );
        }}
      </FetchTicketSummary>
      {!isAdmin() && <AddNewTicket />}
      <Tickets />
    </div>
  );
};

export default Summary;
