import React, { useState, useEffect } from "react";
import { axiosWrapper } from "../../utils/axiosWrapper";

/**
 *
 * @param {{ contentType: string, ticket: string, id: string, fullImageHandler: (_: string) => void }} props
 */
const TicketAttachmentImage = (props) => {
  const { ticket, contentType, id, fullImageHandler } = props;
  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const authToken = localStorage.getItem("token");

  useEffect(() => {
    const fetchRawContent = async () => {
      const hostname = window.location.hostname;

      axiosWrapper(
        hostname,
        "GET",
        `/tickets/${ticket}/attachment/${id}?mime=${encodeURIComponent(
          contentType
        )}`,
        {
          Authorization: `Bearer ${authToken}`,
          Accept: contentType,
        },
        undefined,
        true,
        'blob',
      )
        .then((response) => {
          if (response.status !== 200) {
            setLoading(false);
            throw new Error("Failed to fetch image");
          }
          return response.data;
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setImageSrc(url);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    };
    fetchRawContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <div>Loading image...</div>;

  return (
    <img
      onClick={() => fullImageHandler(imageSrc)}
      src={imageSrc}
      alt="An error occured while fetching data"
    />
  );
};

export default TicketAttachmentImage;
