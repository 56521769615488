import React, { useEffect, useMemo, useRef } from "react";
import "./TicketHistoryChat.scss";
import Loading from "../Loading";
import Loader from "../Loader.jsx";
import { Error } from "../common/index.jsx";
import { ConfigProvider, Timeline } from "antd";
import { GrNotes } from "react-icons/gr";
import { TbTool } from "react-icons/tb";
import { FaCheck, FaEnvelopeOpenText } from "react-icons/fa";
import TicketHistoryContent from "./TicketHistoryContent.jsx";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import TicketCommentAttachments from "./TicketCommentAttachments.jsx";
import jwt from "jsonwebtoken";

/**
 * 
 * @param {{
 *  data: {
 *    id: string, queue: string, owner: string, creator: string, subject: string,
 *    status: string, priority: number, initialPriority: number, finalPriority: number,
 *    requestors: string, cc: string, adminCc: string, created: string, starts: string,
 *    started: string, due: string, resolved: string, told: string, lastUpdated: string,
 *    timeEstimated: number, timeWorked: number, timeLeft: number,
 *    customFields: {[key: string]: string},
 *    history: {
 *      id: string, ticket: string, timeTaken: number, type: string, field: string,
 *      oldValue: string, newValue: string, data: string, description: string,
 *      content: string[], creator: string, created: string,
 *      attachments: {id: string, ticket: string, contentType: string, contentEncoding: string, content: string[]}[],
 *      isCustomer: boolean,
 *    }[],
 *  } | null,
 *  loading: boolean,
 *  refetching: boolean,
 *  error: any,
 *  fullImageHandler: (_: string) => void,
 * }} props 
 */
const TicketHistoryChat = (props) => {
  const { data, loading, refetching, error, fullImageHandler } = props;
  const bottomRef = useRef();

  const scrollToLast = () => {
    const { current } = bottomRef;
    if (current) {
      current.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    if (data !== null) {
      setTimeout(scrollToLast, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, bottomRef]);

  const filtered = useMemo(() => 
    data !== null ? data.history.filter((h, idx) => (h.type === 'Create' && idx === 0) || h.type !== 'Create') : [], [data]);

  /**
   * @param {string} strDate 
   * @returns 
   */
  const stringToDateString = (strDate) => {
    const corrDate = new Date(`${strDate.replace(' ', 'T')}Z`);
    const year = corrDate.getFullYear();
    const month = String(corrDate.getMonth() + 1).padStart(2, '0');
    const day = String(corrDate.getDate()).padStart(2, '0');
    const hours = String(corrDate.getHours()).padStart(2, '0');
    const minutes = String(corrDate.getMinutes()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  /**
   * 
   * @param {{
   *  id: string, ticket: string, timeTaken: number, type: string, field: string,
   *  oldValue: string, newValue: string, data: string, description: string,
   *  content: string[], creator: string, created: string,
   *  attachments: {id: string, ticket: string, contentType: string, contentEncoding: string, content: string[]}[],
   *  isCustomer: boolean
   * }} historyEvent
   */
  const getEventDescription = (historyEvent) => {
    if (historyEvent.type === 'Status') return `Status of the ticket #${historyEvent.ticket} changed to: ${historyEvent.newValue}`;
    if (historyEvent.type === 'CustomField' && historyEvent.description.startsWith('Customer Portal Update')) {
      return `${historyEvent.newValue}`;
    }
    return '';
  }
  
  const formatTimeDifference = (value, unit) => {
    return `${value} ${unit}${value > 1 ? 's' : ''} ago`;
  };
  
  const getTimeDifference = (ds) => {
    const years = Math.floor(ds / 31536000);
    if (years > 0) return formatTimeDifference(years, 'year');
    
    const months = Math.floor(ds / 2628000);
    if (months > 0) return formatTimeDifference(months, 'month');
    
    const weeks = Math.floor(ds / 604800);
    if (weeks > 0) return formatTimeDifference(weeks, 'week');
    
    const days = Math.floor(ds / 86400);
    if (days > 0) return formatTimeDifference(days, 'day');
    
    const hours = Math.floor(ds / 3600);
    if (hours > 0) return formatTimeDifference(hours, 'hour');
    
    const minutes = Math.floor(ds / 60);
    if (minutes > 0) return formatTimeDifference(minutes, 'minute');
    
    if (ds > 0) return formatTimeDifference(ds, 'second');
    
    return 'Just now';
  };

  /**
   * 
   * @param {string} created 
   */
  const timeDelta = (created) => {
    const corrDate = new Date(`${created.replace(' ', 'T')}Z`);
    const now = new Date();
    const ds = Math.floor((now.getTime() - corrDate.getTime()) * 0.001);
    return getTimeDifference(ds);
  };

  const isAdmin = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;
    const decoded = jwt.decode(token);
    if (!decoded.user) return false;
    return !!(decoded.user.isAdmin);
  };

  if (loading) {
    return (
      <div className={`chat_center ${isAdmin() ? 'admin' : 'user_chatbox_input'}`}>
        <Loader />
        <div className="loader">
          <Loading
            loading={loading}
            loadingText="Loading history"
            submitText="SUBMIT"
          />
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className={`chat_center ${isAdmin() ? 'admin' : 'user_chatbox_input'}`}>
        <Error error="An error occured while processing your request" />
      </div>
    );
  }

  if (error !== null) {
    return (
      <div className={`chat_center ${isAdmin() ? 'admin' : 'user_chatbox_input'}`}>
        <Error error={error.toString()} />
      </div>
    );
  }

  return (
    <div className={`chat_wrapper ${isAdmin() ? 'admin' : 'user_chatbox_input'}`}>
      <div className="chat_container">
        <ConfigProvider
          theme={{
            components: {
              Timeline: {
                tailColor: 'rgba(5, 5, 5, 0.5)',
              },
            },
          }}
        >
          <Timeline
            mode="left"
            items={filtered.map((h, idx) => {
              if (h.type === 'Create' && idx === 0) {
                return {
                  label: stringToDateString(h.created),
                  dot: <div className="fill wiline"><GrNotes size={17} /></div>,
                  color: 'blue',
                  children: (
                    <React.Fragment>
                      <div className="fancy-hr-container">
                        <div className="fancy-hr-line">
                          <FaArrowLeftLong size={16} className="arrow-left" />
                          <div className="line"></div>               
                          <FaArrowRightLong size={16} className="arrow-right" />
                        </div>
                        <div className="text">{`Ticket #${h.ticket} created`}</div>
                      </div>
                      {(h.content.length > 0 && h.content[0] !== 'This transaction appears to have no content') && (
                        <div key={`${h.id}-h`} className={`chat_${h.isCustomer ? 'customer' : 'wiline'}_wrapper`}>
                          <div className="chatbox_wrapper">
                            <div className="chatbox">
                              <div key="title" className="title_row">
                                <div className="title">{h.creator}</div>
                                <div className="date">{timeDelta(h.created)}</div>
                              </div>
                              <span className="wiline-hr"></span>
                              <TicketHistoryContent content={h.content} id={h.id} />
                              <TicketCommentAttachments
                                key="attach"
                                attachments={h.attachments}
                                fullImageHandler={fullImageHandler}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ),
                };
              }
              if (h.type === 'Status' || h.type === 'CustomField') {
                return {
                  color: '#dd7800',
                  label: stringToDateString(h.created),
                  dot: (
                    h.type === 'Status' ?
                    (
                      h.newValue === 'open' ?
                      <div className="fill wiline"><FaEnvelopeOpenText size={17} /></div>
                      :
                      <div className="fill cyan"><FaCheck size={17} /></div>
                    ) :
                    <div className="fill cyan"><TbTool size={17} /></div>
                  ),
                  children: (
                    <div className="fancy-hr-container">
                      <div className="fancy-hr-line">
                        <FaArrowLeftLong size={16} className="arrow-left" />
                        <div className="line"></div>               
                        <FaArrowRightLong size={16} className="arrow-right" />
                      </div>
                      <div className="text">{getEventDescription(h)}</div>
                    </div>
                  ),
                };
              }
              return {
                label: stringToDateString(h.created),
                color: h.isCustomer ? '#078181' : '#074481',
                children: (
                  <React.Fragment>
                    <div key="chat" className={`chat_${h.isCustomer ? 'customer' : 'wiline'}_wrapper`}>
                      <div className="chatbox_wrapper">
                        <div className="chatbox">
                          <div key="title" className="title_row">
                            <div className="title">{h.creator}</div>
                            <div className="date">{timeDelta(h.created)}</div>
                          </div>
                          <span className="wiline-hr"></span>
                          <TicketHistoryContent content={h.content} id={h.id} />
                          <TicketCommentAttachments
                            key="attach"
                            attachments={h.attachments}
                            fullImageHandler={fullImageHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ),
              };
            })}
          />
        </ConfigProvider>
        {refetching && (
          <div className="chat_event_wrapper" key="new-comment">
            <div className="event">
              <div className="line" />
              <div className="text"><Loading loading={refetching} loadingText="Loading new comment" submitText="Done" /></div>
              <div className="line" />
            </div>
          </div>
        )}
        <div key="dummy-div-bottom" style={{height: '1px'}} ref={bottomRef}></div>
      </div>
    </div>
  )
};

export default TicketHistoryChat;
