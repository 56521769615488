import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Box } from "@material-ui/core";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  AimOutlined,
  ApiOutlined,
  IdcardOutlined,
  CreditCardOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

import ServiceLocation from "./ServiceLocation";
import ServiceRequest from "./ServiceRequest";
import ContactInformation from "./ContactInformation";
import BillingInformation from "./BillingInformation";
import BuyNowOverview from "./BuyNowOverview";
import {
  decrementStep,
  incrementStep,
  setStepData,
} from "../../Redux/StepTracker/stepTrackerActions";

import "./BuyNow.scss";
import OrderFunction from "../../Functions/Order";

const useStyles = makeStyles((theme) => ({
  activeStepIconContainer: {
    backgroundColor: "orange !important",
    color: "#fff",
  },
  inactiveStepIconContainer: {
    backgroundColor: "#074481",
    color: "#fff",
  },
  futureStepIconContainer: {
    backgroundColor: "#bbdefb !important",
    color: "#fff",
  },
}));

function BuyNow() {
  let formData = {
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    phone_number: "",
    package_name: "",
    package_price: "",
    service_address: "",
    payment_method: "creditCard", // You can set a default value if needed
    card_name: "",
    card_number: "",
    exp_date: "",
    cvc: "",
    // bank_name: '',
    // routing_number: '',
    // account_number: '',
    // account_type: 'checking', // You can set a default value if needed
    // complete_data: false,
  };
  const classes = useStyles();
  const buyNowActiveStep = useSelector((state) => state.step.buyNowActiveStep);
  const serviceAddress = useSelector(
    (state) => state.customerInfo.serviceAddress
  );
  const selectedPlan = useSelector(
    (state) => state.customerInfo.selectedService
  );
  const contactInformation = useSelector(
    (state) => state.customerInfo.contactInformation
  );
  const billingInformation = useSelector(
    (state) => state.customerInfo.billingInformation
  );

  // const formData = useSelector((state) => state.formData);
  const dispatch = useDispatch();

  const [showError, setShowError] = useState(false);
  const [loadingOrderSubmit, setLoadingOrderSubmit] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handleNext = async () => {
    const isValid = await validateCurrentStep();
    if (isValid) {
      dispatch(incrementStep("buyNowActiveStep"));
      clearErrors();
    }
  };

  const clearErrors = () => {
    setShowError(false);
  };

  const handleBack = () => {
    dispatch(decrementStep("buyNowActiveStep"));
  };

  const handleStepClick = async (step) => {
    if (buyNowActiveStep > step) {
      dispatch(setStepData("buyNowActiveStep", step));
    } else {
      const isCurrentStepValid = await validateCurrentStep();
      if (isCurrentStepValid) {
        dispatch(setStepData("buyNowActiveStep", step));
      }
    }
  };

  const validateCurrentStep = () => {
    switch (buyNowActiveStep) {
      case 0:
        if (serviceAddress.length === 0) {
          setShowError(true);
          return Promise.resolve(false);
        }
        break;
      case 1:
        if (!selectedPlan.serviceId) {
          setShowError(true);
          return Promise.resolve(false);
        }
        break;
      case 2:
        if (
          !contactInformation.firstName ||
          !contactInformation.lastName ||
          !contactInformation.email ||
          !contactInformation.customerAddress
        ) {
          setShowError(true);
          return Promise.resolve(false);
        }
        break;
      case 3:
        if (
          !billingInformation.cardHolderName ||
          !billingInformation.cardNumber ||
          !billingInformation.expDate ||
          !billingInformation.cvc
        ) {
          setShowError(true);
          return Promise.resolve(false);
        }
        break;
      default:
        break;
    }

    setShowError(false);
    return Promise.resolve(true);
  };

  const getStepIcon = (icon) => {
    const icons = {
      0: <AimOutlined className="stepperIcons" />,
      1: <ApiOutlined className="stepperIcons" />,
      2: <IdcardOutlined className="stepperIcons" />,
      3: <CreditCardOutlined className="stepperIcons" />,
      4: <ContainerOutlined className="stepperIcons" />,
    };
    return icons[icon];
  };

  //Order
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      placeOrder(false);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInformation, selectedPlan, billingInformation, serviceAddress]);

  const handleSubmit = (event) => {
    event.preventDefault();
    placeOrder(true);
  };

  const processFormData = (submit) => {
    formData.first_name = contactInformation?.firstName;
    formData.last_name = contactInformation?.lastName;
    formData.email = contactInformation?.email;
    formData.phone_number = "12345";
    formData.package_name = selectedPlan?.serviceName;
    formData.package_price = parseFloat("$50".replace("$", "")).toFixed(2);
    formData.service_address = "Testing Address";
    formData.card_name = billingInformation?.cardHolderName;
    formData.card_number = billingInformation?.cardNumber;
    formData.exp_date = billingInformation?.expDate;
    formData.cvc = billingInformation?.cvc;
    formData.submitted = submit;

    return formData;
  };

  const placeOrder = (submit) => {
    if (contactInformation?.email) {
      if (submit) {
        setLoadingOrderSubmit(true);
      }
      let formattedFormData = processFormData(submit);

      OrderFunction.placeOrder(formattedFormData)
        .then((response) => {
          if (submit) {
            if (response?.data?.status) {
              setResponseError(false);
              setStatusMessage(response?.data?.msg);
            } else {
              setResponseError(true);
              setStatusMessage(response?.data?.msg);
            }
          }
        })
        .catch((error) => {
          setResponseError(true);
          setStatusMessage(error.toString());
        })
        .finally(() => {
          // handleShowCompleted(submit);
          if (submit) {
            setLoadingOrderSubmit(false);
          }
        });
    }
  };
  return (
    <Container className="buy-now-container">
      <Box className="shadow-box">
        <Grid container className="stepper-container">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={buyNowActiveStep === 0}
              onClick={handleBack}
              startIcon={<ArrowLeftOutlined />}
              className="step-button back-button"
            />
          </Grid>
          <Grid item xs>
            <Stepper
              activeStep={buyNowActiveStep}
              alternativeLabel
              className="stepper"
            >
              {[
                "Address",
                "Service Request",
                "Contact Information",
                "Billing Information",
                "Preview",
              ].map((label, index) => (
                <Step key={label} onClick={() => handleStepClick(index)}>
                  <StepLabel
                    StepIconProps={{
                      icon: getStepIcon(index),
                    }}
                    classes={{
                      iconContainer:
                        buyNowActiveStep === index
                          ? classes.activeStepIconContainer
                          : buyNowActiveStep > index
                          ? classes.inactiveStepIconContainer
                          : classes.futureStepIconContainer,
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={buyNowActiveStep === 4}
              endIcon={<ArrowRightOutlined />}
              className="step-button next-button"
            />
          </Grid>
        </Grid>
      </Box>
      {buyNowActiveStep === 0 && <ServiceLocation showError={showError} />}
      {buyNowActiveStep === 1 && <ServiceRequest showError={showError} />}
      {buyNowActiveStep === 2 && <ContactInformation showError={showError} />}
      {buyNowActiveStep === 3 && <BillingInformation showError={showError} />}
      {buyNowActiveStep === 4 && (
        <BuyNowOverview
          onSubmit={handleSubmit}
          loading={loadingOrderSubmit}
          message={statusMessage}
          hasError={responseError}
        />
      )}
    </Container>
  );
}

export default BuyNow;
