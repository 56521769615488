import React, { useEffect, useState } from "react";
import { catchAxios } from "../../utils/utils.js";
import { axiosWrapper } from "../../../utils/axiosWrapper.js";

const FetchTicketSummary = (props) => {
  const { children } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const authToken = localStorage.getItem("token");
        const hostname = window.location.hostname;

        const response = await axiosWrapper(
          hostname,
          "GET",
          "/tickets/stats/summary",
          {
            Authorization: `Bearer ${authToken}`,
          }
        );
        const { data } = response;
        setData(data);
      } catch (err) {
        setError(err);
        catchAxios(err);
      } finally {
        setLoading(false);
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {children({
        data,
        loading,
        error,
      })}
    </div>
  );
};

export default FetchTicketSummary;
