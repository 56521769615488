import React from "react";
import SectionTitle from "../SectionTitle.jsx";
// Routing
import { withRouter } from "react-router-dom";
// Material UI Components
import { Grid, Paper, Button, Typography } from "@material-ui/core";
//SASS Styles
import style from "../styles/Payments.module.scss";
import { FadeIn } from "../animated.js";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

/**
 *  This Component is displayed after a payment has been made.  If the payment was successful, and success message appears.  If an error occurred during the payment, then the error message is shown.  In either case, the user is provided a link to the {@link module:AccountSummary AccountSummary} page.
 *  @module PaymentSuccess
 *  @param {Object} props - The state of {@link module:MakeAPayment MakeAPayment} is passed as props to this component.
 *  @see {@link module:MakeAPayment MakeAPayment}, </br> {@link module:AccountSummary AccountSummary}
 *
 */

const PaperTransition = FadeIn(Paper);

function PaymentSuccess(props) {
  const finalMessageSuccess =
    props.payment &&
    typeof props.payment.processedMessage !== "undefined" &&
    props.payment.processedMessage
      ? props.payment.processedMessage
      : props.successMessage;
  const finalMessageError =
    props.payment &&
    typeof props.payment.processedMessage !== "undefined" &&
    props.payment.processedMessage
      ? props.payment.processedMessage
      : props.errorMessage;

  return (
    <>
      {props.errorMessage === "" ? (
        // SUCCESS
        <Grid container justifyContent="center">
          <SectionTitle variant="h1" title="Payment Successful" />
          <Grid item xs={12} xl={10}>
            <PaperTransition
              square={true}
              elevation={5}
              className={style.paymentCard}
            >
              <Typography
                variant="h3"
                color="primary"
                style={{ textAlign: "center" }}
              >
                Thank you for your payment!
              </Typography>
              <FaCheckCircle size={32} className={style.checkMark} />
              <Typography
                variant="body1"
                style={{
                  gridColumn: "1/-1",
                  margin: "36px 12px",
                  textAlign: "center",
                }}
              >
                {finalMessageSuccess}
                <br />
                {props.payment && typeof props.payment.error !== "undefined" &&
                  props.payment.error.code &&
                  "Error Code: " + props.payment.error.code}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  gridColumn: "1/-1",
                  margin: "36px 12px",
                  textAlign: "center",
                }}
              >
                An email confirmation has been sent to the email address
                registered.
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                style={{
                  gridColumn: "1/-1",
                  margin: "0px 12px",
                  textAlign: "center",
                }}
              >
                Note: The payment may take a few days to show on your account.
              </Typography>
              <div className={style.dashboardButton}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    props.history.push("/business/my-account");
                  }}
                >
                  Back to Dashboard
                </Button>
              </div>
            </PaperTransition>
          </Grid>
        </Grid>
      ) : (
        // ERROR
        <Grid container justifyContent="center">
          <SectionTitle variant="h1" title="Payment Unsuccessful!" />
          <Grid item xs={12} xl={10}>
            <PaperTransition
              square={true}
              elevation={5}
              className={style.paymentCard}
            >
              <Typography
                variant="h3"
                color="primary"
                style={{ textAlign: "center" }}
              >
                Oops! There was an error processing your payment.
              </Typography>
              <FaTimesCircle size={32} className={style.errorMark} />
              <Typography
                variant="body1"
                style={{
                  gridColumn: "1/-1",
                  margin: "36px 12px",
                  textAlign: "center",
                }}
              >
                {finalMessageError}
                <br />
                {props.payment &&
                  typeof props.payment.error !== "undefined" &&
                  props.payment.error.code &&
                  "Error Code: " + props.payment.error.code}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  gridColumn: "1/-1",
                  margin: "36px 12px",
                  textAlign: "center",
                }}
              >
                Please try again, if the error persists contact{" "}
                <a href={props.billingEmail}>{props.billingEmail}</a>.
              </Typography>
              <div className={style.dashboardButton}>
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    size="medium"*/}
                {/*    disabled={props.loaderEmail}*/}
                {/*    onClick={() => {*/}
                {/*        props.reportErrorByEmail()*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {(props.loaderEmail) ? 'Sending email...' : 'Report this error to support'}*/}
                {/*</Button>*/}
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    props.reset();
                  }}
                >
                  Back to Payments
                </Button>
              </div>
            </PaperTransition>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default withRouter(PaymentSuccess);
