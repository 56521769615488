import axios from "axios";

const SNI_DOMAINS = process.env.REACT_APP_STEPHOUSE_HOSTNAME_ALLOW;

const WNI_HOST = process.env.REACT_APP_API_HOST;
const SNI_HOST = process.env.REACT_APP_API_SNI_HOST;

export const axiosWrapper = (
  hostname,
  method,
  url,
  headers,
  data = undefined,
  withCredentials = true,
  responseType = undefined
) => {
  const urlHost = SNI_DOMAINS.includes(hostname) ? SNI_HOST : WNI_HOST;
  if (responseType) {
    return axios({
      method,
      url: urlHost + url,
      headers,
      withCredentials: withCredentials,
      data: data,
      responseType,
    });
  }
  return axios({
    method,
    url: urlHost + url,
    headers,
    withCredentials: withCredentials,
    data: data,
  });
};
