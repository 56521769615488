import React from "react";
import AccountSummary from "./AccountSummary.js";
import AccountDetailsScreen from "./AccountDetailsScreen.js";
import ChangePassword from "./ChangePassword.js";
import ChangeAccountDetails from "./ChangeAccountDetails.js";
import NotFound from "../NotFound.jsx";
import { Switch, Route } from "react-router-dom";
import ProtectedRoute from "../auth/ProtectedRoute.js";
import MultipleAccounts from "./MultipleAccounts.jsx";

/**
 *  This Component provides routing logic for the "My Account" section.
 *  @module MyAccount
 *  @see {@link module:AccountSummary AccountSummary}, </br> {@link module:AccountDetailsScreen AccountDetailsScreen} </br> {@link module:ChangeAccountDetails ChangeAccountDetails} </br> {@link module:ChangePassword ChangePassword} </br> {@link module:MultipleAccounts MultipleAccounts}, </br> {@link https://reacttraining.com/react-router/web/guides/quick-start react-router-dom}
 */

function MyAccount() {
  return (
    <>
      <Switch>
        <ProtectedRoute
          exact
          path="/business/my-account"
          fallback="/business/my-tickets"
          component={AccountSummary}
        />
        <Route
          exact
          path="/business/my-account/password-reset"
          component={ChangePassword}
        />
        <ProtectedRoute
          path="/business/my-account/change-account-details"
          fallback="/business/my-tickets"
          component={ChangeAccountDetails}
        />
        <ProtectedRoute
          exact
          path="/business/my-account/account-details"
          fallback="/business/my-tickets"
          component={AccountDetailsScreen}
        />
        <Route exact path="/business" component={MultipleAccounts} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default MyAccount;
