import React from 'react'
import InvoiceRow from '../myAccount/InvoiceRow.js';
import Loader from '../Loader.jsx';
import {generateKey} from "../utils/utils";
import { fadeAndFall } from '../animated.js'
// Material UI Components
import {Paper, makeStyles, Typography} from "@material-ui/core";
//SASS Styles
import style from '../styles/Payments.module.scss'

const AnimatedDiv = fadeAndFall('div')

const useStyles = makeStyles( theme => ({
    tableHeader: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        paddingLeft: '12px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    footerButton: {
        float: 'right',
        margin: '0.5em'
    },
    title: {
      color: theme.palette.common.white,
      fontWeight: 600,
      fontSize: '0.9em'
    }
}));

/**
 * Table for invoices
 * @param {Object} props Additional styles, data, loading, handle submit function
 * @module InvoiceTable
 */
function InvoiceTable({additionalStyles, data, loading, handleSubmit, tableTitle}) {
  const classes = useStyles();

  return (
    <div className={style.invoicesContainer} style={additionalStyles}>
      <Paper
        className={`${classes.tableHeader}`}
        style={additionalStyles}
        square={true}
      >
        <Typography
          color="textSecondary"
          className={classes.title}>
            {tableTitle}
        </Typography>
      </Paper>
      {
        loading ?
        <Loader height="44px" /> :
        (
          (data.length > 0) ?
          data.map((e, i) => (
            <AnimatedDiv
              startHeight="44px"
              endHeight="300px"
              key={generateKey(e.date+i)}
            >
            <InvoiceRow
              handleSubmit={handleSubmit}
              file={e.file}
              invoiceNumber={e.invoiceNumber}
              date={e.date}
            />
          </AnimatedDiv>
          ))
          :
          <AnimatedDiv
            startHeight="44px"
            endHeight="44px"
          >
          <div style={{padding: '12px 8px'}}>No invoices available at the moment.</div>
        </AnimatedDiv>
        )
      }
    </div>
  )
}

export default InvoiceTable;

