import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Card, Button, CardContent, Typography} from '@material-ui/core';
import style from './styles/MainLayout.module.scss';
import ChangeContactDetails from "./ChangeContactDetails";
import CardModal from "./payments/CardModal";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'block',
        margin: '0 auto'
    },
    building: {
        minWidth: '50%',
    },
    card: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        height: '100%'
    },
    details: {
        gridColumn: '3/-1',
        display: 'flex',
        flexDirection: 'column',
        padding: '4px',
        textAlign: 'right'
    },
    content: {
        gridColumns: '1/-1',
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gridGap: '4px',
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        },
    },
    cover: {
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
    },
    footer: {
        gridColumn: '1/-1',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        textAlign: 'right',
        paddingRight: '0.5em',
        margin: 0,
        minHeight: '32px',
        lineHeight: '28px',
        padding: '2px'
    },
    icon: {
        gridColumn: '1/2',
        display: 'grid',
        alignItems: 'center',
        padding: '8px',
        minWidth: '25%',
        minHeight: '80px',
        margin: 'auto',
        color: theme.palette.primary.main,
    },
    workOrders: {
        display: 'flex',
        justifyContent: 'flex-end',
        gridColumn: '1/-1',
    },
    workOrdersTitle: {
        gridColumn: '1/-1',
        color: theme.palette.primary.main,
        fontWeight: '600',
        textTransform: 'uppercase'
    },
    whiteFooter: {
        padding: '4px',
        gridColumn: '1/-1',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: '2vw',
        justifyContent: 'space-between'
    }
}));

function SelectPropertyOrCard(props) {
    const expiredStyles = (props.expired) ? {opacity: '0.4'} : {};
    const activeStyle = (props.autoPay) ? {border: '1px solid limegreen'} : {};
    const classes = useStyles();

    const {
        icon,
        iconSize,
        heading,
        description,
        activeWorkOrders,
        className,
    } = props;

    return (
        <Grid item xs={12} sm={10} md={6} lg={4}
              className={`${className} ${activeWorkOrders && classes.building} ${classes.container}`}>
            <Card className={`${classes.card} ${style.shadow}`} raised={true} square={true}>
                <CardContent className={classes.content} style={activeStyle}>
                    <Grid item xs={3} className={classes.icon}>
                        <div style={{ ...expiredStyles, width: iconSize, height: iconSize }}>{icon}</div>
                    </Grid>
                    <div className={classes.details}>
                        <Typography component="p" style={expiredStyles}>
                            {heading}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" style={expiredStyles}>
                            {description}
                        </Typography>
                        <div className={classes.workOrders}>
                            {/* If this is a credit card no active work orders should be shown as they are irrelevant. */}
                            {activeWorkOrders &&
                            <div>
                                <p className={classes.workOrdersTitle}>Active Customer Service Agreements:</p>
                                {/*If there aren't active work orders print the word NONE.*/}
                                {(activeWorkOrders[0].length > 0) ?
                                    <p style={{
                                        display: 'block',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    }}>{activeWorkOrders.map((order, i, arr) => {
                                        if (i < arr.length - 1) {
                                            return (`${order}, `)
                                        } else {
                                            return order 
                                        }
                                    })}</p>
                                    :
                                    <p style={{display: 'block', textAlign: 'right'}}> NONE </p>
                                }
                            </div>
                            }
                            {/* expired prop is a boolean only passed to pay method cards, not buildings. */}
                            {props.expired &&
                            <div>
                                <Typography variant="body1" style={expiredStyles}> EXPIRED </Typography>
                            </div>
                            }
                            {/* Autopay */}
                            {props.autoPay &&
                            <div>
                                <Typography variant="body1" style={{color: 'limegreen'}}> AUTO-PAY ENABLED </Typography>
                            </div>
                            }
                        </div>
                    </div>
                    {/*This should be Remove for Credit Cards and No button for Buildings only buildings will have active work orders so we can conditional render via that prop.*/}
                    {!activeWorkOrders ?
                        <div className={classes.whiteFooter}>
                            {!props.autoPay &&
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => props.setActiveAccount('remove')}
                                >
                                    Remove
                                </Button>
                            }
                            {!props.autoPay ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => props.setActiveAccount('autopay')}
                                    disabled={props.expired}
                                >
                                    <CardModal payId={props.payId}/> Set as Autopay
                                </Button>
                                :
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => props.setActiveAccount('removeAutopay')}
                                >
                                    Remove Autopay
                                </Button>
                            }
                        </div>
                        :
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            justifyContent: 'space-between',
                            gridColumn: '1/-1',
                            gridGap: '2vw',
                            padding: '1vh 1vw',
                            alignItems: 'end'
                        }}>
                            <ChangeContactDetails type="site" orgid={props.orgid} />
                            <ChangeContactDetails type="tech" orgid={props.orgid} />
                        </div>
                    }
                </CardContent>
            </Card>
        </Grid>
    )
}

SelectPropertyOrCard.propTypes = {
    content: PropTypes.object,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    orgid: PropTypes.number, // not needed for payment methods card
    iconSize: PropTypes.string,
    heading: PropTypes.string,
    description: PropTypes.string,
    activeWorkOrders: PropTypes.array,
    className: PropTypes.string,
};

export default SelectPropertyOrCard;
