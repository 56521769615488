import React, { Component } from "react";
import { Formik, Field } from "formik";
import { Button, Grid, FormHelperText } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import * as yup from "yup";
import { FaChartPie, FaChartBar } from "react-icons/fa";
import Loading from "../Loading";

// Validation schema with custom validation for date range
const validationSchema = yup.object().shape({
  dateFrom: yup
    .date()
    .required("Start date is required")
    .test(
      "is-valid-start-date",
      "Start date cannot be after end date",
      function (value) {
        const { dateTo } = this.parent; // Access dateTo from the same form
        return !value || !dateTo || value <= dateTo; // Allow if value is empty or valid range
      }
    ),
  dateTo: yup
    .date()
    .required("End date is required")
    .test(
      "is-valid-end-date",
      "End date cannot be before start date",
      function (value) {
        const { dateFrom } = this.parent; // Access dateFrom from the same form
        return !value || !dateFrom || value >= dateFrom; // Allow if value is empty or valid range
      }
    ),
});

class DateRangeForm extends Component {
  state = {
    dateFrom: new Date(new Date().valueOf() - 86350989), // Yesterday
    dateTo: new Date(),
    submitting: false,
    activeChart: "pie",
  };

  handleChange = (type, payload) => {
    // Ensure start date is not later than end date
    if (type === "dateFrom" && payload > this.state.dateTo) {
      this.setState({
        dateFrom: this.state.dateTo,
      });
    } else if (type === "dateTo" && payload < this.state.dateFrom) {
      this.setState({
        dateTo: this.state.dateFrom, // Reset dateTo to be same as dateFrom
      });
    } else {
      this.setState({
        [type]: payload,
      });
    }
  };

  render() {
    const { setChart, submit, submitting, valid, activeChart } = this.props; // Parent component handles all state

    return (
      <Formik
        initialValues={{
          dateFrom: this.state.dateFrom,
          dateTo: this.state.dateTo,
        }}
        validationSchema={validationSchema}
        onSubmit={() => {
          submit(this.state.dateFrom, this.state.dateTo); // Submit from parent
        }}
      >
        {(props) => {
          const { handleSubmit, errors, touched } = props;

          return (
            <form onSubmit={handleSubmit} style={{ padding: "16px" }}>
              <Grid container justifyContent="space-evenly">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item>
                    <Field name="dateFrom">
                      {({ field }) => (
                        <KeyboardDatePicker
                          {...field}
                          disableToolbar
                          variant="inline"
                          disabled={submitting}
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="dateFrom-picker"
                          label="Start Date"
                          value={this.state.dateFrom}
                          onChange={(payload) =>
                            this.handleChange("dateFrom", payload)
                          }
                          style={{ height: "68px" }}
                        />
                      )}
                    </Field>
                    {errors.dateFrom && touched.dateFrom && (
                      <FormHelperText error>{errors.dateFrom}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <Field name="dateTo">
                      {({ field }) => (
                        <KeyboardDatePicker
                          {...field}
                          disableToolbar
                          variant="inline"
                          disabled={submitting}
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="dateTo-picker"
                          label="End Date"
                          value={this.state.dateTo}
                          onChange={(payload) =>
                            this.handleChange("dateTo", payload)
                          }
                          style={{ height: "68px" }}
                        />
                      )}
                    </Field>
                    {errors.dateTo && touched.dateTo && (
                      <FormHelperText error>{errors.dateTo}</FormHelperText>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={submitting || !valid}
                  >
                    <Loading
                      loading={submitting}
                      loadingText="LOADING"
                      submitText="Get Stats"
                    />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setChart("pie")}
                    color={activeChart === "pie" ? "secondary" : "primary"}
                    variant="contained"
                  >
                    <FaChartPie size={24} />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setChart("bar")}
                    color={activeChart === "bar" ? "secondary" : "primary"}
                    variant="contained"
                  >
                    <FaChartBar size={24} />
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default DateRangeForm;
