import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route } from 'react-router-dom'
import './normalize.css';
import { App } from './App';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/store";
import { unregister } from "./serviceWorker";

unregister();

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <Route path="/" render={() => (
            <App />
          )} />
        </HashRouter>,
      </PersistGate>,
    </Provider>, document.getElementById('root'));
};

render();
