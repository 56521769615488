import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

function OrderSuccessModal(props) {
  const closeModal = () => {
    props.onClose();
  };
  return (
    <Dialog
      open={props.open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <>
        <DialogTitle id="alert-dialog-title" align="center">
          Order Successful!
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1" color="primary" align="center">
            The order was created successfully, an email confirmation has been
            sent to the email address provided.
          </DialogContentText>
        </DialogContent>
      </>
      <DialogActions>
        <Button onClick={closeModal} variant="contained">
          Close
        </Button>
        <Button
          //   onClick={logout}
          variant="contained"
          color="primary"
          //   href={props.link}
        >
          Go to login
          {/* {props.confirmButton} */}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderSuccessModal;
