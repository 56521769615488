import React, { Component } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import TimeModal from "./TimeModal";
//import {clearServerSession} from "../utils/utils";
import SocketIO from "../SocketIO";
import { useDispatch } from "react-redux";
import { resetState } from "../../Redux/action.js";

/**
 *  This component is a wrapper for any route that requires authentication.  It will only allow a user to proceed to the requested route if they have a valid, non-expired jwt. If a user does not have a valid, non-expired jwt and they attempt to access a component wrapped with <PrivateRoute /> they will be redirected to the login page.
 *  @module PrivateRoute
 *  @param {function} expired() - Checks to see if a jwt is expired. </br>takes 0 arguments </br>returns a boolean
 *  @param {function} auth() -  Checks to see if a jwt exists, and makes sure it has an expiration time.  </br> takes 0 arguments </br> returns a boolean
 */

class PrivateRoute extends Component {
  state = {
    openModal: false,
  };

  // Check if token is expired (ONLY CHECKS EXPIRED)
  // false = valid, true = expired
  expired = () => {
    try {
      const token = localStorage.getItem("token");
      // (Let auth handle no token)
      if (!token) {
        return true;
      }
      // Decode jwt and then check expired
      const decoded = jwt.decode(token);
      if (decoded && decoded.exp) {
        const d = new Date();
        const now = d.getTime();
        return now > decoded.exp * 1000; // Expiry is in seconds not ms
      } else {
        return true; // Expired function only handles time
      }
    } catch (err) {
      return true;
    }
  };

  /**
   *  Checks that the user has a token.
   *  Also checks that user as an admin if adminOnly is true (Only for admin routes)
   *  @function
   *  @param   {bool} adminOnly Should the function check if the user is an admin.
   *  @returns {bool}           Is auth valid
   */
  auth = (adminOnly) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return false;
      } else if (token) {
        const decoded = jwt.decode(token);

        // If token doesn't have an exp attribute it's not from us
        if (!decoded || !decoded.exp) {
          return false;
        }

        // If this is a route only for admins then check that user is admin
        if (adminOnly) {
          if (!decoded.user || !decoded.user.isAdmin) {
            return false;
          }
        }
      }
    } catch (err) {
      return false;
    }
    return true;
  };

  // Check to see if token is expired
  // Set modal state
  checkExpired = () => {
    const expired = this.expired();

    // Open modal if token is expired
    if (expired) {
      const dispatch = useDispatch();
      dispatch(resetState());
      this.setState({
        openModal: true,
      });
      // socket is disconnected 2 seconds after the session expires but the server session flush happens only after the closure of the modal
      setTimeout(() => {
        SocketIO("disconnect_socket");
      }, 2000);
    }
  };

  // Create interval to check expired token
  async componentDidMount() {
    this.interval = setInterval(() => {
      // get current location
      const path = this.props.location.pathname;

      // Don't set check for expired token login page
      if (path !== "/login" && path !== "/register") {
        this.checkExpired();
      }
    }, 10000);

    // If history changed then reset scroll bar
    this.props.history.listen((location, action) => {
      window.scrollTo(0, 0);
    });
  }

  // Remove interval when component unmounts
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // Handle modal close
  closeModal = () => {
    // flush server session and clear socket
    //clearServerSession();
    this.setState({
      openModal: false,
    });
    //redirect to login page.
    this.props.history.push("/login");
  };

  render() {
    let { component: Component, ...rest } = this.props;
    const { adminOnly } = this.props;
    const closeModal = this.closeModal.bind(this);
    let open = this.state.openModal;

    return (
      <Route
        {...rest}
        render={(props) =>
          this.auth(adminOnly) !== false ? (
            <>
              <Component {...props} />
              <TimeModal open={open} closeModal={closeModal} />
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

export default withRouter(PrivateRoute);
