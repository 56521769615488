import React from "react";
import clsx from "clsx"; // to build classnames
import Loader from "../Loader.jsx";
import { FadeIn } from "../animated.js";
// Material Ui Components
import { Button, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
// SASS Styles
import style from "../styles/ContactCard.module.scss";
import ErrorBoundary from "../ErrorBoundary";
import jwt from "jsonwebtoken";

/**
 *  This component displays the billing contact details including account name, address, billing contact, email, phone, cell, and fax. It also provides an "edit" button which links the user to a form where they can update or change their billing information.
 *  @module BillingAddress
 *  @param {Object} props - accountName: String </br> address: String </br> billingContact: String </br> email: String </br> phone: String </br> cell: String </br> fax: String </br> loading: Boolean </br> link: String </br> history: history Object
 *  @see {@link module:Loader Loader}, </br> {@link module:ErrorBoundary ErrorBoundary}
 */

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  header: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "1em",
    padding: "8px 0 8px 12px",
  },
}));

function BillingAddress(props) {
  const { accountName, address, billingContact, email, phone, fax, cell } =
    props;

  const token = localStorage.getItem("token");
  const decoded = jwt.decode(token);
  const readOnly = !!decoded.user.readOnly;
  const AnimateGrid = FadeIn(Grid);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    window.location.href = '/#/business/my-account/change-account-details/billing-address';
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div className={style.billingContainer}>
      <h1 className={classes.header}>BILLING ADDRESS</h1>
      <div className={style.content}>
        {props.loading ? (
          <Loader height="258px" />
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" className={style.billingTitle}>
                  BUSINESS NAME:
                </Typography>
              </Grid>
              <AnimateGrid item xs={12} sm={6}>
                <Typography variant="h5" className={`${style.info}`}>
                  {accountName}
                </Typography>
              </AnimateGrid>
            </Grid>

            <Grid container>
              <AnimateGrid item delay="30ms" xs={12} sm={6}>
                <Typography variant="h5" className={style.billingTitle}>
                  ADDRESS:
                </Typography>
              </AnimateGrid>
              <AnimateGrid item delay="30ms" xs={12} sm={6}>
                <Typography variant="h5" className={`${style.info}`}>
                  {address}
                </Typography>
              </AnimateGrid>
            </Grid>

            <Grid container>
              <AnimateGrid item delay="60ms" xs={12} sm={6}>
                <Typography variant="h5" className={style.billingTitle}>
                  BILLING CONTACT:
                </Typography>
              </AnimateGrid>
              <AnimateGrid item delay="60ms" xs={12} sm={6}>
                <Typography variant="h5" className={`${style.info}`}>
                  {billingContact}
                </Typography>
              </AnimateGrid>
            </Grid>

            <Grid container>
              <AnimateGrid item delay="90ms" xs={12} sm={6}>
                <Typography variant="h5" className={style.billingTitle}>
                  EMAIL:
                </Typography>
              </AnimateGrid>
              <AnimateGrid item delay="90ms" xs={12} sm={6}>
                <Typography variant="h5" className={`${style.info}`}>
                  {email}
                </Typography>
              </AnimateGrid>
            </Grid>

            <Grid container>
              <AnimateGrid item delay="120ms" xs={12} sm={6}>
                <Typography variant="h5" className={style.billingTitle}>
                  PHONE:
                </Typography>
              </AnimateGrid>
              <AnimateGrid item delay="120ms" xs={12} sm={6}>
                <Typography variant="h5" className={`${style.info}`}>
                  {phone}
                </Typography>
              </AnimateGrid>
            </Grid>

            <Grid container>
              <AnimateGrid item delay="150ms" xs={12} sm={6}>
                <Typography variant="h5" className={style.billingTitle}>
                  CELL:
                </Typography>
              </AnimateGrid>
              <AnimateGrid item delay="150ms" xs={12} sm={6}>
                <Typography variant="h5" className={`${style.info}`}>
                  {cell}
                </Typography>
              </AnimateGrid>
            </Grid>

            <Grid container>
              <AnimateGrid item delay="180ms" xs={12} sm={6}>
                <Typography variant="h5" className={style.billingTitle}>
                  FAX:
                </Typography>
              </AnimateGrid>
              <AnimateGrid item delay="180ms" xs={12} sm={6}>
                <Typography variant="h5" className={`${style.info}`}>
                  {fax}
                </Typography>
              </AnimateGrid>
            </Grid>
          </>
        )}
      </div>
      <div className={style.buttonFix}>
        <Button
          style={{ margin: "8px" }}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
          disabled={readOnly}
        >
          EDIT
        </Button>
      </div>
      <ErrorBoundary>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Edit Billing Address?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                label="Account Name"
                value="Name from Context API"
                className={clsx(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                fullWidth
                disabled
              />
              <TextField
                label="Address"
                className={clsx(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Billing Contact"
                value="name from context API"
                className={clsx(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                fullWidth
                disabled
              />
              <TextField
                label="Email"
                className={clsx(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Phone"
                className={clsx(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Fax/Cell"
                className={clsx(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              color="primary"
              autoFocus
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </ErrorBoundary>
    </div>
  );
}

export default BillingAddress;
