import React from 'react';
import ssl from '../assets/img/ssl-logo.png';
import style from './styles/Footer.module.scss';
import Typography from '@material-ui/core/Typography';
import ErrorBoundary from "./ErrorBoundary";
import { FaCcAmex, FaCcDiscover, FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { useSelector } from 'react-redux';

function Footer() {
    // Used for the footer copyright date
    const newDate = (new Date()).getFullYear();
    const company = useSelector((state) => state.companyInfo);

    return (
        <ErrorBoundary>
            <div id="footer" style={{marginTop: '2vh'}}>
                <div className="img-container">
                    <div className={style.centerBot}>
                        <img className={style.imgSize} src={ssl} alt="SSL"/>
                        <div className={style.iconContainer}>
                            <FaCcVisa className={style.icon}/>
                            <FaCcMastercard className={style.icon} />
                            <FaCcAmex className={style.icon} />
                            <FaCcDiscover className={style.icon}/>
                        </div>
                    </div>
                </div>
                <div className={style.copyright}>
                    <Typography variant="body1">&copy; {newDate} {company.name}</Typography>
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default Footer;
