import * as globalType from "./globalType";

/**
 * @param {boolean} value 
 * @returns 
 */
export const setGlobalLoader = (value) => ({
  type: globalType.SET_GLOBAL_LOADER,
  payload: value,
});

/**
 * @param {{ title: string, content: string }} value 
 * @returns 
 */
export const openGlobalModal = (value) => ({
  type: globalType.OPEN_GLOBAL_MODAL,
  payload: value,
});

export const closeGlobalModal = () => ({
  type: globalType.CLOSE_GLOBAL_MODAL,
});

/**
 * @param {string} value 
 * @returns 
 */
export const openGlobalDialog = (value) => ({
  type: globalType.OPEN_GLOBAL_MODAL,
  payload: value,
});

export const closeGlobalDialog = () => ({
  type: globalType.CLOSE_GLOBAL_DIALOG,
});
