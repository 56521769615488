import React, { useState } from "react";
import UsersWrapper from "./UsersWrapper";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  Button,
} from "@material-ui/core";
import Loading from "../Loading";
import styles from "./Users.module.scss";
import AddUserConfirmModal from "./AddUserConfirmModal";
import DeleteUserConfirmModal from "./DeleteUserConfirmModal";
import FailedAlert from "./FailedAlert";
import SuccessAlert from "./SuccessAlert";
import jwt from "jsonwebtoken";
import DenyUserConfirmModal from "./DenyUserConfirmModal";
import ApproveUserConfirmModal from "./ApproveUserConfirmModal";

const REG_STATUS = {
  pending_cportal: "Pending",
  approved_cportal: "Approved",
  denied_cportal: "Denied",
  registered_cportal: "Registered",
  denied: "Denied",
  pending: "Pending",
  approved: "Approved",
  registered: "Registered",
};

/**
 * @param {string} status
 */
const displayRegReqStatus = (status) => REG_STATUS[status] || "Unknown";

const isAdmin = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;
  const decoded = jwt.decode(token);
  if (!decoded.user) return false;
  return !!decoded.user.isAdmin;
};

const MainPage = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [showUserConfirmModal, setShowUserConfirmModal] = useState(false);
  const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState("");
  const [action, setAction] = useState("");

  const [approveUID, setApproveUID] = useState("");
  const [denyUID, setDenyUID] = useState("");
  const [showUserApproveModal, setShowUserApproveModal] = useState(false);
  const [showUserDenyModal, setShowUserDenyModal] = useState(false);

  /**
   * @param {string} value
   */
  const handleEmailChange = (value) => {
    const emailRegex = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
    const v = value.trim();
    setEmail(v);
    setEmailValid(!v.length || emailRegex.test(v));
  };

  const handleClickAddUser = () => {
    setShowUserConfirmModal(true);
  };

  /**
   * @param {string} email
   */
  const handleClickDeleteUser = (email) => {
    setDeleteEmail(email);
    setShowUserDeleteModal(true);
  };

  /**
   * @param {string} uid
   */
  const handleClickApproveUser = (email, uid) => {
    setEmail(email);
    setApproveUID(uid);
    setShowUserApproveModal(true);
  };

  /**
   * @param {string} uid
   */
  const handleClickDenyUser = (email, uid) => {
    setEmail(email);
    setDenyUID(uid);
    setShowUserDenyModal(true);
  };

  return (
    <div>
      <UsersWrapper>
        {
          /**
           * @param {{
           *  data: {
           *    user_id: string, user_name: string, user_display_name: string, status: string,
           *    email: string, uid: string
           *  }[],
           *  loading: boolean,
           *  error: string,
           *  success: string,
           *  handleAddUser: (_: string) => Promise<void>
           *  handleDeleteUser: (_: string) => Promise<void>
           *  handleApproveUser: (_: string, _: string) => Promise<void>
           *  handleDenyUser: (_: string, _: string) => Promise<void>
           *  handleClearError: () => void,
           *  handleClearSuccess: () => void,
           * }} _
           */
          ({
            data,
            loading,
            error,
            success,
            handleAddUser,
            handleDeleteUser,
            handleApproveUser,
            handleDenyUser,
            handleClearError,
            handleClearSuccess,
          }) => {
            return (
              <div className={styles.container}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="tickets-table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Full Name</TableCell>
                        <TableCell align="left">Registration Status</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isAdmin() && (
                        <TableRow
                          key="add"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            <div>
                              <input
                                type="email"
                                value={email}
                                onChange={(e) =>
                                  handleEmailChange(e.target.value)
                                }
                                className={styles.input}
                                aria-invalid={!emailValid}
                              />
                              {!emailValid && (
                                <div className={styles.input_error}>
                                  Please enter valid email address
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                          ></TableCell>
                          <TableCell
                            align="left"
                          ></TableCell>
                          <TableCell align="right">
                            <Button
                              disabled={!emailValid || !email.length || loading}
                              onClick={handleClickAddUser}
                              variant="contained"
                              color="primary"
                              className={styles.add_btn}
                            >
                              Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                      {data.map((row) => (
                        <TableRow
                          key={row.user_id ? row.user_id : row.uid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            {row.user_name ? row.user_name : row.email}
                          </TableCell>
                          <TableCell align="left">
                            {row.user_display_name
                              ? row.user_display_name
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {displayRegReqStatus(row.status)}
                          </TableCell>
                          <TableCell align="center">
                            <div className={styles.statusCell}>
                              {row.status === "pending" ||
                              row.status === "pending_cportal" ? (
                                <>
                                  <Button
                                    disabled={loading}
                                    onClick={() =>
                                      handleClickApproveUser(row.email, row.uid)
                                    }
                                    variant="contained"
                                    color="primary"
                                    className={styles.approve_btn}
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    disabled={loading}
                                    onClick={() =>
                                      handleClickDenyUser(row.email, row.uid)
                                    }
                                    variant="contained"
                                    color="secondary"
                                    className={styles.deny_btn}
                                  >
                                    Deny
                                  </Button>
                                </>
                              ) : null}
                              <Button
                                disabled={loading}
                                onClick={() => handleClickDeleteUser(row.email)}
                                variant="contained"
                                className={styles.delete_btn}
                              >
                                Delete
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                      {loading && (
                        <TableRow
                          key="loading"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" colSpan={4}>
                            <Loading
                              loading={loading}
                              loadingText="Loading users"
                              submitText="Loading users"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      {!loading && (!data || !data.length) && (
                        <TableRow
                          key="empty"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" colSpan={4}>
                            <div>No users found.</div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {error && error.length && (
                  <FailedAlert
                    open={error && !!error.length}
                    onClose={handleClearError}
                  />
                )}
                {success && success.length && (
                  <SuccessAlert
                    open={success && !!success.length}
                    action={action}
                    onClose={handleClearSuccess}
                  />
                )}
                {showUserApproveModal && (
                  <ApproveUserConfirmModal
                    open={showUserApproveModal}
                    email={email}
                    loading={loading}
                    onClose={() => setShowUserApproveModal(false)}
                    onSubmit={() => {
                      setAction("approve");
                      setShowUserApproveModal(false);
                      handleApproveUser(email, approveUID);
                      setEmail("");
                      setApproveUID("");
                    }}
                  />
                )}
                {showUserDenyModal && (
                  <DenyUserConfirmModal
                    open={showUserDenyModal}
                    email={email}
                    loading={loading}
                    onClose={() => setShowUserDenyModal(false)}
                    onSubmit={() => {
                      setAction("deny");
                      setShowUserDenyModal(false);
                      handleDenyUser(email, denyUID);
                      setEmail("");
                      setDenyUID("");
                    }}
                  />
                )}
                {showUserConfirmModal && (
                  <AddUserConfirmModal
                    open={showUserConfirmModal}
                    email={email}
                    loading={loading}
                    onClose={() => setShowUserConfirmModal(false)}
                    onSubmit={() => {
                      setAction("add");
                      setShowUserConfirmModal(false);
                      handleAddUser(email);
                      setEmail("");
                    }}
                  />
                )}
                {showUserDeleteModal && (
                  <DeleteUserConfirmModal
                    open={showUserDeleteModal}
                    email={deleteEmail}
                    loading={loading}
                    onClose={() => setShowUserDeleteModal(false)}
                    onSubmit={() => {
                      setAction("delete");
                      setShowUserDeleteModal(false);
                      handleDeleteUser(deleteEmail);
                      setDeleteEmail("");
                    }}
                  />
                )}
              </div>
            );
          }
        }
      </UsersWrapper>
    </div>
  );
};

export default MainPage;
