import React, { useEffect, useState } from "react";
import SectionTitle from "../SectionTitle.jsx";
import BillingAddress from "./BillingAddress.js";
import ServiceAddress from "./ServiceAddress.jsx";
import ErrorBoundary from "../ErrorBoundary";
import { enterComponent } from "../animated.js";
// Material UI
import { Grid } from "@material-ui/core";
// SASS Styles
import style from "../styles/AccountSummary.module.scss";
import { catchAxios, handleServerStatus } from "../utils/utils";
import LoaderPage from "../LoaderPage";
import { axiosWrapper } from "../../utils/axiosWrapper.js";
import { useDispatch, useSelector } from "react-redux";
import { setActiveBillingAccount, setCustomer } from "../../Redux/AccountInfo/accountInfoActions.js";

/**
 *  This Component renders a section that displays more details about the user's billing account.  It renders a {@link module:BillingAddress BillingAddress} Compone which displays billing contact details, as well as a {@link module:ServiceAddress ServiceAddress} component that displays the addresses serviced for this billing account. Information is queried from the API upon component mounting and stored in state to be passed to children components.
 *  @module AccountDetailsScreen
 *  @see {@link module:BillingAddress BillingAddress}, </br> {@link module:ServiceAddress ServiceAddress}
 */

const AnimateGrid = enterComponent(Grid);

const AccountDetailsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [accountNo, setAccountNo] = useState(' ');
  const [detailsData, setDetailsData] = useState({});
  const accountInfo = useSelector((state) => state.accountInfo);
  const dispatch = useDispatch();

  const loadProfileDetails = async () => {
    const authToken = localStorage.getItem("token");
    setLoading(true);
    const hostname = window.location.hostname;
    // Get the customer details from the server, and passes the activeBillingAccount stored in redux.
    axiosWrapper(
      hostname,
      "POST",
      "/profile/details",
      { Authorization: `Bearer ${authToken}`, },
      { accountNo: accountInfo.activeBillingAccount, }
    ).then((res) => {
      if (res.data && res.data.status) {
        const data = res.data.data;
        setDetailsData(data.detailsData);
        setAccountNo(data.detailsData.billingaccountno);
        dispatch(setCustomer({
          detailsData: data.detailsData,
          techContactData: data.techContactData,
          siteContactData: data.siteContactData,
        }));
        dispatch(setActiveBillingAccount(data.detailsData.billingaccountno));
        setLoading(false);
      } else if (res.data && !res.data.status) {
        setLoading(false);
        handleServerStatus(res.data.msg);
      }
    }).catch((err) => {
      catchAxios(err);
    });
  };

  useEffect(() => {
    loadProfileDetails();
  }, []);

  return (
    <>
      <LoaderPage />
      <div>
        <SectionTitle
          variant="h1"
          title={`Account #${accountNo}`}
          style={style.welcome}
        />
        <div className={`${style.container} ${style.accDetails}`}>
          <Grid
            container
            style={{ padding: "0 10px 80px 10px", maxWidth: "100%" }}
          >
            <AnimateGrid
              item
              xs={12}
              lg={12}
              style={{ marginBottom: "24px" }}
            >
              <ErrorBoundary>
                <BillingAddress
                  accountName={detailsData.customername}
                  address={detailsData.address ? detailsData.address : ""}
                  billingContact={`${detailsData.firstname} ${detailsData.lastname}`}
                  //email={isAdmin ? detailsData.addressbook_email : detailsData.email}
                  email={detailsData.addressbook_email}
                  phone={detailsData.telephone ? detailsData.telephone : ""}
                  fax={detailsData.fax ? detailsData.fax : ""}
                  cell={detailsData.cell ? detailsData.cell : ""}
                  link="billing"
                  loading={loading}
                />
              </ErrorBoundary>
            </AnimateGrid>
            <AnimateGrid item xs={12} lg={12}>
              <ErrorBoundary>
                <ServiceAddress loading={loading} />
              </ErrorBoundary>
            </AnimateGrid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AccountDetailsScreen;
