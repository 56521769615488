import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { useParams } from "react-router-dom";
import style from "./styles/VerifyRegister.module.scss";
import { axiosWrapper } from "../utils/axiosWrapper";
import Header from "./Header";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    bg: {
        backgroundColor: theme.palette.primary.main
    },
})
);

export default function VerifyRegister () {
    const params = useParams();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

  useEffect(() => {
    const handleVerifyUser = async () => {
      const hostname = window.location.hostname;
      await axiosWrapper(
        hostname,
        "POST",
        `/users/verify-reg-request/${params.token}`,
        {},
        undefined,
        false
      )
        .then((data) => {
          setData(data.data);
          setLoading(false);
          setSuccess(true);
          setError(false);
        })
        .catch((err) => {
          setData(err);
          setLoading(false);
          setSuccess(false);
          setError(true);
        });
    };
    handleVerifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
        <div className={`${style.container} ${classes.bg}`}>
            <Header />
            {loading ?
                <div className={style.content}><Loading loading={loading} loadingText={'Verifying... Please wait'} /></div> :
                <div className={style.content}>{data.msg}</div>
            }
            {success && <div className={style.message}>Verification successful. You can close this window now.</div>}
            {error && <div className={style.message}>Verification failed. Please try refreshing this page.</div>}
        </div>
    );
}
