import * as globalType from "./globalType";

const START_STATE = {
  dialog: {
    open: false,
    msg: '',
  },
  globalLoad: false,
  globalModal: {
    open: false,
    title: '',
    content: '',
  },
};

const globalReducer = (state = START_STATE, action) => {
  switch (action.type) {
    case globalType.SET_GLOBAL_LOADER:
      return {
        ...state,
        globalLoad: action.payload,
      };
    case globalType.OPEN_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: {
          open: true,
          title: action.payload.title,
          content: action.payload.content,
        },
      };
    case globalType.CLOSE_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: {
          open: false,
          title: '',
          content: '',
        },
      };
    case globalType.OPEN_GLOBAL_DIALOG:
      return {
        ...state,
        dialog: {
          open: true,
          msg: action.payload,
        },
      };
    case globalType.CLOSE_GLOBAL_DIALOG:
      return {
        ...state,
        dialog: {
          open: false,
          msg: '',
        },
      };
    default:
      return state;
  }
};

export default globalReducer;