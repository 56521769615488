import { DialogTitle, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import PropTypes from 'prop-types';
import styles from '../styles/Tickets/AddNewTicket.module.scss';

function AddTicketModal (props) {
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');

  const closeModal = () => {
    props.onClose();
  };

  const submitModal = () => {
    props.onSubmit(subject, content);
  };

  return (
    <Dialog
      open={props.open}
      onClose={closeModal}
      aria-labelledby="comment-dialog-title"
      aria-describedby="comment-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      className={styles.modal}
    >
      <DialogTitle id="alert-dialog-title" className={styles.title}>Create new ticket</DialogTitle>
      <DialogContent>
        <div>Subject</div>
        <input disabled={props.loading} rows={2} className={styles.input} value={subject} onChange={(e) => setSubject(e.target.value)} />
        <div>Content</div>
        <textarea disabled={props.loading} rows={5} className={styles.input} value={content} onChange={(e) => setContent(e.target.value)}></textarea>
      </DialogContent>
      <DialogActions>
        <Button disabled={props.loading} onClick={closeModal} variant="contained">
          Cancel
        </Button>
        <Button disabled={props.loading || content.length === 0 || subject.length === 0} onClick={submitModal} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddTicketModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AddTicketModal;
