import React, { useMemo, useState } from "react";
import SlotCounter from 'react-slot-counter';

const formattedValue = (value) => {
  const conv = parseFloat(value, 10);
  if (isNaN(conv)) return { neg: false, val: 0 };
  return { neg: (conv < 0), val: Math.abs(conv) };
};

const NumberCounter = (props) => {
  const { value } = props;
  const [isStatic, setIsStatic] = useState(false);
  const { val, neg } = useMemo(() => formattedValue(value), [value]);

  const handleAnimationEnd = () => {
    setTimeout(() => setIsStatic(true), 10);
  };

  // After animation finishes show static value to prevent font misalignment
  if (isStatic) return <span>{value}</span>;

  return (
    <>
      {neg && <span>-</span>}
      <SlotCounter
        startValue={0}
        value={val.toFixed(2)}
        sequentialAnimationMode
        animateOnVisible
        onAnimationEnd={handleAnimationEnd}
      />
    </>
  );
};

export default NumberCounter;
