import { SET_COMPANY_BY_BUSINESS_UNIT } from "./companyType";
import stephouseWhite from "../../assets/img/stephouse-white.svg";
import stephouseQuickGuide from "../../assets/img/StephouseQuickGuide.png";
import wilineLogo from "../../assets/img/wiline.svg";
import wilineQuickGuide from "../../assets/img/WiLineQuickGuide.png";

const WNI_STATE = {
  id: 0,
  company: 'WiLine',
  name: 'WiLine Networks, Inc.',
  contact: '',
  address: {
    street: '104 Carnegie Center',
    suite: 'Suite 201',
    city: 'Princeton',
    state_long: 'New Jersey',
    state_short: 'NJ',
    zip: '08540',
  },
  phone: '1-888-494-5463',
  email: 'info@wiline.com',
  salesEmail: 'sales@wiline.com',
  supportEmail: 'support@wiline.com',
  billingEmail: 'billing@wiline.com',
  portalEmail: 'support@wiline.com',
  portalUrl: 'https://my.wiline.com',
  portalHelpLine: '650-523-5494',
  logo: wilineLogo,
  quickGuide: wilineQuickGuide,
  color: '#074481',
};

const SNI_STATE = {
  id: 1,
  company: 'Stephouse',
  name: 'Stephouse Networks, Inc.',
  contact: '',
  address: {
    street: '921 SW Washington Street',
    suite: `#224`,
    city: 'Portland',
    state_long: 'Oregon',
    state_short: 'OR',
    zip: '97205',
  },
  phone: '',
  email: 'billing@stephouse.net',
  salesEmail: 'billing@stephouse.net',
  supportEmail: 'billing@stephouse.net',
  billingEmail: 'billing@stephouse.net',
  portalEmail: 'billing@stephouse.net',
  portalUrl: 'https://my.stephouse.net',
  portalHelpLine: '503-548-2000',
  logo: stephouseWhite,
  quickGuide: stephouseQuickGuide,
  color: '#29abe2',
};

const START_STATE = {
  id: -1,
  company: '',
  name: '',
  contact: '',
  address: {
    street: '',
    suite: '',
    city: '',
    state_long: '',
    state_short: '',
    zip: '',
  },
  phone: '',
  email: '',
  salesEmail: '',
  supportEmail: '',
  billingEmail: '',
  portalEmail: '',
  portalUrl: '',
  portalHelpLine: '',
  logo: '',
  quickGuide: '',
  color: '',
};

const companyReducer = (state = START_STATE, action) => {
  switch (action.type) {
    case SET_COMPANY_BY_BUSINESS_UNIT: {
      const unit = action.payload;
      if (!unit) return START_STATE;
      if (unit === 'WNI' || unit === 'wni' || unit === 'ALL') return WNI_STATE;
      return SNI_STATE;
    }
    default:
      return state;
  }
};

export default companyReducer;
