import { catchAxios } from "./utils";
import { axiosWrapper } from "../../utils/axiosWrapper";

/**
 *  This function calls the API to retrieve all the payment methods saved to a users billing account.
 *  @function getPayMethods
 *  @param {int} accountNo - A billing account number.
 */

export const getPayMethods = async (hostname) => {
  const token = localStorage.getItem("token");
  let response;
  try {
    response = axiosWrapper(hostname, "POST", "/payments/pay-methods", {
      Authorization: `Bearer ${token}`,
    });
  } catch (err) {
    response = [];
    catchAxios(err);
  }
  return response;
};

/**
 * Checks to see whether a credit card is expired
 * @function isExpired
 * @param {int} expMonth  - The month a card expires.  Expressed in the format XX
 * @param {int} expYear   - The year a credit card expires.  Expressed in the format XXXX
 */

// check card expiration
export const isExpired = (expMonth, expYear) => {
  const month = parseInt(expMonth);
  const year = parseInt(expYear);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  return (year === currentYear && month <= currentMonth) || year < currentYear;
};

/**
 *  This function calls the API to check if the ACH payment can be handled
 *  @function checkAchPayment
 *  @param {payId} payment account ID, {number} payAmount
 */
export const checkAchPayment = async (payId, payAmount) => {
  const token = localStorage.getItem("token");
  let response;
  try {
    const hostname = window.location.hostname;

    response = axiosWrapper(
      hostname,
      "POST",
      "/payments/check-ach",
      {
        Authorization: `Bearer ${token}`,
      },
      {
        payId,
        payAmount,
      }
    );
  } catch (err) {
    response = [];
    catchAxios(err);
  }
  return response;
};

/**
 * Round n to digits
 * @param {number} n 
 * @param {number} digits 
 */
export const roundTo = (n, digits = 0) => {
  const multiplicator = Math.pow(10, digits);
  const v = parseFloat((n * multiplicator).toFixed(11));
  return Math.round(v) / multiplicator;
};
