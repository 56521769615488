import React from "react";
import Loading from "../Loading";
import { withRouter } from "react-router-dom";
import { FadeIn } from "../animated.js";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  TextField,
} from "@material-ui/core";
// forms and validation
import * as yup from "yup";
import { withFormik } from "formik";
import style from "../styles/Payments.module.scss";
import errorStyle from "../styles/MainLayout.module.scss";
import { catchAxios, getSessionStorage } from "../utils/utils";
import { axiosWrapper } from "../../utils/axiosWrapper.js";

const AnimatedDiv = FadeIn("div");
const AnimateGrid = FadeIn(Grid);

/**
 *  This Component renders a form using "withFormik". All props are injected within this module using the withFormik() wrapper. See {@link https://jaredpalmer.com/formik/docs/api/withformik withFormik()} for more info.
 *  @module BankAccount
 *  @extends Component
 *
 *  @param {Object} props - formik Bag
 */

function BankAccount(props) {
  const {
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    errors,
    isSubmitting,
    status,
    setValues,
  } = props;

  return (
    <>
      <AnimateGrid delay={80} item xs={12}>
        <TextField
          id="accName"
          label="Account Name"
          value={values.accName}
          onBlur={handleBlur}
          onChange={handleChange}
          margin="normal"
          fullWidth={true}
          variant="outlined"
        />
      </AnimateGrid>
      {errors.accName && touched.accName && (
        <AnimatedDiv className={errorStyle.error}>{errors.accName}</AnimatedDiv>
      )}
      <AnimateGrid delay={160} item xs={12}>
        <TextField
          id="accNumber"
          label="Account Number"
          value={values.accNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          margin="normal"
          fullWidth={true}
          variant="outlined"
        />
      </AnimateGrid>
      {errors.accNumber && touched.accNumber && (
        <AnimatedDiv className={errorStyle.error}>
          {errors.accNumber}
        </AnimatedDiv>
      )}
      <AnimateGrid delay={240} item xs={12}>
        <TextField
          id="bankName"
          label="Bank Name"
          value={values.bankName}
          onBlur={handleBlur}
          onChange={handleChange}
          margin="normal"
          fullWidth={true}
          variant="outlined"
        />
      </AnimateGrid>
      {errors.bankName && touched.bankName && (
        <AnimatedDiv className={errorStyle.error}>
          {errors.bankName}
        </AnimatedDiv>
      )}
      <AnimateGrid delay={320} item xs={12}>
        <TextField
          id="routingNumber"
          label="Routing Number"
          value={values.routingNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          margin="normal"
          fullWidth={true}
          variant="outlined"
        />
      </AnimateGrid>
      {errors.routingNumber && touched.routingNumber && (
        <AnimatedDiv className={errorStyle.error}>
          {errors.routingNumber}
        </AnimatedDiv>
      )}
      <AnimateGrid delay={400} item xs={12}>
        <FormControl>
          <InputLabel
            htmlFor="account-type-select"
            style={{ minWidth: "100px" }}
          >
            Account Type
          </InputLabel>
          <Select
            value={values.accountType}
            onChange={handleChange}
            inputProps={{
              name: "accountType",
              id: "account-type-select",
            }}
          >
            <MenuItem value="BUSINESSCHECKING">Business Checking</MenuItem>
            <MenuItem value="CHECKING">Checking</MenuItem>
            <MenuItem value="SAVINGS">Savings</MenuItem>
          </Select>
        </FormControl>
      </AnimateGrid>
      {status && status.error && (
        <div className={style.error}>{status.error}</div>
      )}
      <AnimateGrid
        delay={480}
        className={style.marginFix}
        container
        justifyContent="space-between"
      >
        <Button
          variant="contained"
          style={{ width: "47%" }}
          onClick={() =>
            setValues({
              accName: "",
              accNumber: "",
              bankName: "",
              routingNumber: "",
              accountType: "BUSINESSCHECKING",
            })
          }
        >
          {" "}
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          style={{ width: "47%" }}
          disabled={isSubmitting}
        >
          <Loading
            loading={isSubmitting}
            loadingText="LOADING"
            submitText="CONFIRM"
          />
        </Button>
      </AnimateGrid>
    </>
  );
}

const FormikAddBankAccount = withFormik({
  mapPropsToValues: () => ({
    accName: "",
    accNumber: "",
    bankName: "",
    routingNumber: "",
    accountType: "BUSINESSCHECKING",
  }),
  validationSchema: yup.object().shape({
    accName: yup
      .string()
      .max(
        40,
        "This field can only contain 40 characters. Must not contain numbers."
      )
      .required("This is a required field."),
    accNumber: yup
      .number()
      .typeError("Account Number must be a number.")
      .min(1)
      .max(9999999999999999, "The Account number must be 16 digits or less.")
      .required("This is a required field"),
    bankName: yup
      .string()
      .max(
        40,
        "This field can only contain 40 characters. Must not contain numbers."
      )
      .required("This is a required field."),
    routingNumber: yup
      .number()
      .typeError("Routing Number must be a number.")
      .required("This is a required field."),
    accountType: yup.string().required(),
  }),
  handleSubmit(values, { setSubmitting, resetForm, setStatus, props }) {
    setSubmitting(true);
    setStatus({ success: "" });
    setStatus({ error: "" }); //remove errors upon submitting

    const token = getSessionStorage("token");
    const hostname = window.location.hostname;

    axiosWrapper(
      hostname,
      "POST",
      "/payments/new-method",
      {
        Authorization: `Bearer ${token}`,
      },
      {
        values,
        payMethod: "ACH",
        billingaccountno: props.accNo,
      }
    )
      .then((res) => {
        if (res.data && res.data.status === true) {
          props.history.push({
            pathname: "/business/payments/make-payment",
            state: { status: res.data.msg },
          });
        } else if (res.data && res.data.status === false) {
          setStatus({ error: res.data.msg });
          setSubmitting(false);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        catchAxios(err);
      });
  },
})(BankAccount);

export default withRouter(FormikAddBankAccount);
