import React, { useState } from "react";
import "./TicketHistoryComment.scss";
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import { TbMessageCircle } from "react-icons/tb";

const TicketHistoryComment = (props) => {
  const {
    loading = false,
    refetching = false,
    onSubmit = () => {},
  } = props;
  const [comment, setComment] = useState('');

  const handleSubmit = async (comment) => {
    const res = await onSubmit(comment);
    if (res) setComment('');
  };

  return (
    <div className="comment_box">
    <div className="comment_icon">
      <TbMessageCircle size={44}  color="#fd7e14d9" />
    </div>
      <textarea
        disabled={loading || refetching}
        className="bottom-border-input"
        rows={2}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      ></textarea>
      <Button
        disabled={(comment.length === 0 || loading || refetching)}
        onClick={() => handleSubmit(comment)}
        variant="contained"
        color="primary"
        className="send_btn"
      >
        {(loading || refetching) ? 
          <div><Loading loading={(loading || refetching)} loadingText="Loading" submitText="Loading" /></div> :
          <span>Submit</span>
        }
      </Button>
    </div>
  );
};

export default TicketHistoryComment;
