import React from 'react';
import NotFound from '../NotFound.jsx';
import {Switch, Route} from 'react-router-dom';
import style from "../styles/MainLayout.module.scss";
import NestedDrawer from "../NestedDrawer.js";
import MainPage from './MainPage.jsx';
import ProtectedRoute from '../auth/ProtectedRoute.js';

function Users() {
  return (
    <div className={style.layout}>
      <NestedDrawer />
      <div className={style.gridFix}>
        <div>
          <Switch>
            <ProtectedRoute path="/business/users" fallback="/business/my-tickets" component={MainPage} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Users;
