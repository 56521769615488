import * as notificationInfoType from "./notificationInfoType";

export const setNotificationCountInfo = (value) => ({
  type: notificationInfoType.NOTIFICATION_INFO_SET_COUNT_INFO,
  payload: value,
});

export const lowerNotificationCountInfo = () => ({
  type: notificationInfoType.NOTIFICATION_INFO_LOWER_COUNT_INFO,
});
