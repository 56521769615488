import React from "react";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import { FadeIn, transitionComponent } from "../animated.js";
import * as yup from "yup";
import SectionTitle from "../SectionTitle.jsx";
import NumberFormat from "react-number-format";
import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Paper,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { isExpired, checkAchPayment } from "../utils/paymentUtils.js";
import style from "../styles/Payments.module.scss";
import AmountDueSummary from "./AmountDueSummary.jsx";
import PaymentFeeNote from "./PaymentFeeNote.jsx";
import FetchPaymentFeeExempt from "./FetchPaymentFeeExempt.jsx";
import { useSelector } from "react-redux";

const FadeInError = FadeIn(ErrorMessage);
const PaperTransition = transitionComponent(Paper);

/**
 * Function that returns a jsx component with a properly formatted number.
 * @params {Object} props - takes the props passed into the PaymentDetails component
 * @return {component}
 */
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale={true}
      decimalSeparator="."
      isNumericString={true}
      thousandSeparator
      allowNegative={false}
      prefix="$"
    />
  );
}

function buildCards(data) {
  if (data && data.paymentMethods) {
    return (
      data.paymentMethods
        // .filter((card) => card.payMethod !== "CC")
        .map((card, i) => {
          const lastFour =
            card.methodType === "AHC" ? card.lastDigitsACH : card.lastDigitsCC;
          const validCards = isExpired(card.expireMonth, card.expireYear) ? (
            "" // if card is expired do not return as a valid payment option.
          ) : (
            <MenuItem value={card} key={`${lastFour}-${i}`}>
              {card.payType} - ending in {lastFour} - {card.nameOnAccount}
            </MenuItem>
          );
          return validCards;
        })
    );
  } else {
    return null;
  }
}

const PaymentDetails = (props) => {
  const {
    data,
    submitPayment,
    loading,
    isSubmitting,
    addPaymentStatus, // Exists only if user redirected from add payment page
  } = props;

  const buttonTxt = loading || isSubmitting ? "LOADING" : "SUBMIT";
  const company = useSelector((state) => state.companyInfo);

  // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const checkPayment = (values) => {
    let errors = {};
    if (values.selectedPaymentMethod.payMethod === "ACH") {
      return checkAchPayment(
        values.selectedPaymentMethod.pay_account_id,
        values.amountPayable
      ).then((res) => {
        if (!res.data.status) {
          errors.amountPayable = res.data.msg;
          throw errors;
        }
      });
    }
  };

  const inOrOut = !props.ismounted ? "bounceOut" : "bounceIn"; //conditionally pass the animation name into styled wrapper
  return (
    <>
      <SectionTitle variant="h1" style={style.welcome} title="Make a Payment" />
      <Grid container justifyContent="center" spacing={0}>
        <Grid item xs={12} xl={10}>
          <PaperTransition
            square={true}
            elevation={5}
            className={style.paymentCard}
            ismounted={inOrOut}
          >
            <Formik
              initialValues={{
                amountPayable: "",
                selectedPaymentMethod: "",
              }}
              validate={checkPayment}
              validationSchema={yup.object().shape({
                amountPayable: yup
                  .number()
                  .typeError("Amount payable must be a number.")
                  .positive()
                  .moreThan(0, "Payment amount must be greater than zero.")
                  .lessThan(10000, "Payment amount must be less than $10,000.")
                  .required("You must choose a payment amount."),
                selectedPaymentMethod: yup
                  .string()
                  .required("You must select a payment method."),
              })}
              // submit data
              onSubmit={(values, { setSubmitting, setStatus, props }) => {
                submitPayment(values);
                setSubmitting(true);
              }}
            >
              {(props) => {
                const { values, isSubmitting, handleChange, handleSubmit } =
                  props;
                return (
                  <form onSubmit={handleSubmit}>
                    <FetchPaymentFeeExempt>
                      {({ feeExempt, feeExemptLoading }) => {
                        return (
                          <>
                            <AmountDueSummary
                              feeExempt={feeExempt}
                              isCreditCardPayment={
                                values.selectedPaymentMethod &&
                                values.selectedPaymentMethod.payMethod &&
                                values.selectedPaymentMethod.payMethod === "CC"
                              }
                              amountDue={
                                isNaN(parseFloat(data.amountDue))
                                  ? 0
                                  : parseFloat(data.amountDue)
                              }
                              loading={loading || feeExemptLoading}
                            />
                            <TextField
                              fullWidth
                              id="amountPayable"
                              name="amountPayable"
                              variant="outlined"
                              label="Amount Payable"
                              className="payment-field"
                              value={values.amountPayable}
                              onChange={handleChange("amountPayable")}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                            />
                            <FormControl
                              fullWidth={true}
                              component="div"
                              style={{ marginTop: "0.25rem" }}
                            >
                              <InputLabel htmlFor="pay-method">
                                Payment Method
                              </InputLabel>
                              <Select
                                value={values.selectedPaymentMethod}
                                onChange={handleChange}
                                disabled={
                                  loading ||
                                  (data &&
                                    data.paymentMethods &&
                                    data.paymentMethods.length < 1)
                                }
                                inputProps={{
                                  name: "selectedPaymentMethod",
                                  id: "pay-method",
                                }}
                              >
                                {buildCards(data)}
                              </Select>
                              {!loading &&
                                data &&
                                data.paymentMethods &&
                                data.paymentMethods.length < 1 && (
                                  <FormHelperText>
                                    Please add a payment method.
                                  </FormHelperText>
                                )}
                            </FormControl>
                            {!(feeExempt || feeExemptLoading) && (
                              <PaymentFeeNote
                                paymentMethod={
                                  values.selectedPaymentMethod &&
                                  values.selectedPaymentMethod.payMethod
                                    ? values.selectedPaymentMethod.payMethod
                                    : null
                                }
                                amountDue={
                                  isNaN(parseFloat(data.amountDue))
                                    ? 0
                                    : parseFloat(data.amountDue)
                                }
                                loading={loading}
                              />
                            )}
                            {/* <div
                              style={{
                                marginTop: "0.75rem",
                                textAlign: "center",
                              }}
                            >
                              <div>
                                Credit Card payments have been temporarily
                                disabled due to technical issues.
                                <br />
                                Alternatively, payments can be made with ACH or
                                to pay via CC you can contact billing.
                                <p>Email: {company.portalEmail}</p>
                                <p>Phone #: {company.portalHelpLine}</p>
                              </div>
                            </div> */}
                          </>
                        );
                      }}
                    </FetchPaymentFeeExempt>
                    <div className={style.button}>
                      <Link to="/business/payments/add-new-card">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                          style={{ width: "100%" }}
                        >
                          Add Payment Method
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || loading}
                        style={{ width: "100%" }}
                      >
                        {buttonTxt}
                      </Button>
                    </div>
                    <FadeInError
                      name="amountPayable"
                      component="div"
                      className={style.error}
                    />
                    <FadeInError
                      name="selectedPaymentMethod"
                      component="div"
                      className={style.error}
                    />
                    {addPaymentStatus && (
                      <div className={style.success}>{addPaymentStatus}</div>
                    )}
                  </form>
                );
              }}
            </Formik>
          </PaperTransition>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentDetails;
