import React from 'react';
import PropTypes from 'prop-types';
import {generateKey} from "./utils/utils";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from './Loader';
import Loading from './Loading';
import { fadeAndFallEndlessTable } from './animated.js'
// Material Ui
import Grid from '@material-ui/core/Grid';
import {withTheme, Paper, makeStyles, Typography} from "@material-ui/core";
//SASS Styles
import style from './styles/Table.module.scss';

const AnimatedTable = fadeAndFallEndlessTable(InfiniteScroll);

const useStyles = makeStyles(theme => ({
    background: {
      background: theme.palette.primary.main
    },
    header: {
      background: theme.palette.primary.main,
      width: '100%',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    title: {
      color: theme.palette.common.white,
      fontWeight: 600,
      fontSize: '0.9em'
    },
    data: {
      color: theme.palette.grey[400]
    },
  })
);

const InvoiceTable = (props) => {
  const length = props.rows[0] ? props.rows[0].length : 0;
  const loading = props.loading;
  const classes = useStyles();

  const
    styles = {
      table: {
          height: props.tableHeight,
          overflow: 'auto',
      },
      cellWidth: {
        minWidth: `${props.minCellWidth}px`,
        width: `${1 / length * 100}%`,
      },
      headerWidth: {
        minWidth: `${props.minCellWidth * length}px`,
        textAlign: 'center',
      },
      rowWidth: {
        minWidth: `${props.minCellWidth * length}px`
      },
      headers: {
        minWidth: `${props.minCellWidth}px`,
        width: `${1 / length * 100}%`,
        backgroundColor: props.theme.palette.primary.main,
      },
      title: {
        fontWeight: 600,
        fontSize: '0.9em'
      },
    };

  return (
    <div className={style.tableContainer} style={{overflowX: 'auto', maxWidth: '100%', maxHeight: '100%'}}> {/* table container */}
      <div style={styles.rowWidth} className={classes.background}> {/* table header */}
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          {props.headers.length > 1 ? props.headers.map((title, i) => {
            return (
              <Paper
                className={` ${classes.header} ${style.cell} `}
                style={styles.cellWidth}
                square={true}
                key={generateKey(title + i)}>
                <Typography color="textSecondary" className={classes.title}>{title}</Typography>
              </Paper>
              )
            }) :
              <div
                className={style.cell}
                style={styles.headerWidth}>
                {props.headers[0]}
              </div>
            }
          </Grid>
        </div>
        <div style={styles.rowWidth}> {/* table content */}
          <Grid item xs={12}>
          {
            loading ?
            <Loader height="48px"/> :
            (
              props.rows && props.rows.length > 0 ?
              (
                <AnimatedTable
                  style={{'overflowY': 'overlay'}}
                  dataLength={props.rows.length}
                  next={props.next}
                  hasMore={props.hasMore}
                  loader={
                    <div className={style.endlessFooter}>
                      <Loading
                          loading={true}
                          loadingText="Loading"
                      />
                    </div>
                  }
                  height={`${props.tableHeight}px`}
                  endMessage= {
                    <div className={style.endlessFooter}>
                      <Typography variant="h5" color="primary" gutterBottom>No more records...</Typography>
                    </div>
                  }
                  startheight="44px"
                  endheight={props.tableHeight}
                >
                  {props.rows.map((invoice, i) => {
                    return (
                      <div className={style.row} key={generateKey(`${invoice[0]}+${i}`)}>
                        <Grid container justifyContent="space-between" alignItems="center">
                          {invoice.map((item, i) => {
                            return (
                              <div
                                className={`${style.data} ${style.cell}`}
                                style={styles.cellWidth}
                                key={generateKey(item + i)}>
                                {item}
                              </div>
                            )
                          })}
                        </Grid>
                      </div>
                    )
                  })}
                </AnimatedTable>
              ) :
              <div style={{padding: '12px 8px', 'backgroundColor': 'white'}}>
                {`No ${props.dataName} available.`}
              </div>
          )
        }
        </Grid>
      </div>
    </div>
  )
};

InvoiceTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  minCellWidth: PropTypes.number,
  next: PropTypes.func
};

export default withTheme(InvoiceTable);

