import React from "react";
import Loading from "../Loading";
import { FetchTickets } from "./fetch";
import { Error } from "../common/index.jsx";
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TableContainer,
    Paper,
    Tooltip,
    TableFooter,
    Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import styles from "../styles/Tickets/Tickets.module.scss";

const Tickets = (props) => {
    return (
        <FetchTickets>
        {
            ({ data, error, loading, loadingMore, onLoadMore }) => {
                if (loading) {
                    return (
                        <div className={`${styles.container} ${styles.loader}`}>
                            <Loading
                                loading={loading}
                                loadingText="Loading tickets"
                                submitText="SUBMIT"
                            />
                        </div>
                    );
                }
                if (error) {
                    return <Error error={error.toString()} />;
                }
                return (
                    <div className={styles.container}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="tickets-table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Ticket #</TableCell>
                                    <TableCell align="left">Subject</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="left">Progress Status</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.tickets.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <Tooltip title='View ticket details' placement="top" arrow={true}>
                                                    <Link className={styles.link} to={'/business/my-tickets/' + row.id}>{row.id}</Link>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">{row.subject}</TableCell>
                                            <TableCell className={styles[String(row.status).toLowerCase()]} align="left">{row.status}</TableCell>
                                            <TableCell align="left">
                                                {(row.customFields && row.customFields['Customer Portal Update'] && row.status === 'open') ? row.customFields['Customer Portal Update'] : '-'}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                {!(data.tickets.length) && (
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <div className={styles.flex_center}>
                                                No tickets found.
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {data.hasMore && data.tickets.length > 0 && <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            {loadingMore ?
                                                <div className={styles.flex_center}>
                                                    <Loading
                                                        loading={loadingMore}
                                                        loadingText="Loading more tickets"
                                                        submitText="SUBMIT"
                                                    />
                                                </div>
                                                :
                                                <div className={styles.flex_start}>
                                                    <Button
                                                        className={styles.more_button}
                                                        disabled={loadingMore}
                                                        onClick={() => onLoadMore(data.tickets[data.tickets.length - 1].id)}>
                                                            More
                                                    </Button>
                                                </div>
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>}
                            </Table>
                        </TableContainer>
                    </div>
                );
            }
        }
        </FetchTickets>
    )
}

export default Tickets;
