import React, { useEffect, useMemo, useState } from "react";
import qs from "qs";
import EndlessNotificationTable from "../EndlessNotificationTable";
import { formatDate } from "../utils/utils.js";
import { catchAxios } from "../utils/utils";
import { axiosWrapper } from "../../utils/axiosWrapper.js";
import { useSelector } from "react-redux";

const EXCLUDED_NOTIFICATION_IDS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
];

const NotificationsDataContainer = (props) => {
  const accountInfo = useSelector((state) => state.accountInfo);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    success: '',
    error: '',
    data: [],
    limit: 0,
  });

  useEffect(() => {
    if (props.limit) {
      setState((prevState) => ({
        ...prevState,
        limit: props.limit,
      }));
    }
  }, [props]);

  const handleSubmit = async (file) => {
    const data = {
      fileName: file,
    };

    const authToken = localStorage.getItem("token");
    if (!authToken) return;
    const hostname = window.location.hostname;
    axiosWrapper(hostname, "POST", "/notifications/document",
      {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${authToken}`,
      },
      qs.stringify(data),
      true,
      "blob"
    ).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }).catch((err) => {
      catchAxios(err);
    });
  };

  const sortData = (data) => {
    data.sort(function (a, b) {
      return (
        new Date(formatDate(b.create_date)) -
        new Date(formatDate(a.create_date))
      );
    });
  };

  const getNotificationData = async () => {
    setLoading(true);
    setState((prevState) => ({
      ...prevState,
      success: "",
      error: "",
    }));
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const hostname = window.location.hostname;
      axiosWrapper( hostname, "GET", `/notifications/archived/my/${accountInfo.activeBillingAccount}`,
        { Authorization: `Bearer ${authToken}` },
        { limit: state.limit }
      ).then((response) => {
        setState((prevState) => ({
          ...prevState,
          success: response.data.msg,
          data: response.data.data,
        }));
        setLoading(false);
      }).catch((err) => {
        setState((prevState) => ({
          ...prevState,
          error: "There was a problem retrieving your notifications data.",
        }));
        setLoading(false);
        catchAxios(err);
      });
    }
  };

  const buildData = (data) => {
    if (!data) {
      return [];
    }
    if (data.length === 0) {
      return data;
    }

    return data.map((e) => {
      let readStatus = e.readAt ? "Read" : "Unread";
      if (EXCLUDED_NOTIFICATION_IDS.includes(e.id)) {
        if (readStatus === "Unread") {
          readStatus = "NO_STATUS";
        }
      }
      return [
        e.name,
        e.file,
        new Date(e.createDate).toLocaleDateString(),
        readStatus,
      ];
    });
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  const data = useMemo(() => buildData(state.data), [state]);

  return (
    <>
      <EndlessNotificationTable
        length={data.length}
        loading={loading}
        hasMore={false}
        next={() => null}
        headers={["TITLE", "PDF LINK", "DATE", "STATUS"]}
        rows={data}
        refreshData={async () => { await getNotificationData(); }}
        dataFull={state.data}
        tableHeight="65vh"
        minCellWidth={130}
        dataName="notification data"
        style={{ maxWidth: "95%", margin: "0", width: "100%" }}
      />
    </>
  );
};

export default NotificationsDataContainer;
