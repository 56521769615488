import React from "react";
import './../styles/Particles.css';

const Particles = (props) => {
    const numPoints = 26; // Random number of points between 30 and 50
    const initialSpeed = 0.2; // Adjust this value to control the initial movement speed
    const circlePoints = [];

    for (let i = 0; i < numPoints; i++) {
        const x = Math.random() * 100;
        const y = Math.random() * 100;
        const dx = (Math.random() - 0.5) * initialSpeed;
        const dy = (Math.random() - 0.5) * initialSpeed;

        circlePoints.push({ x, y, dx, dy });
    }

    const generateCirclePoints = () => {
        const lines = [];

        for (let i = 0; i < numPoints; i++) {
            const startPoint = circlePoints[i];

            for (let j = i + 1; j < numPoints; j++) {
                const endPoint = circlePoints[j];

                const distance = Math.sqrt(
                    Math.pow(endPoint.x - startPoint.x, 2) +
                    Math.pow(endPoint.y - startPoint.y, 2)
                );

                if (distance < 30) {
                    lines.push(
                        <line
                            key={`line-${i}-${j}`}
                            x1={`${startPoint.x}%`}
                            y1={`${startPoint.y}%`}
                            x2={`${endPoint.x}%`}
                            y2={`${endPoint.y}%`}
                            stroke="rgba(255, 255, 255, 0.10)"
                            className="moving-line"
                        />
                    );
                }
            }
        }

        return lines;
    };

    return (
        <div>
            <div className="background"></div>
            <svg className="web" xmlns="http://www.w3.org/2000/svg">
                <g className="lines">{generateCirclePoints()}</g>
                {circlePoints.map((point, index) => (
                    <circle
                        key={index}
                        cx={`${point.x}%`}
                        cy={`${point.y}%`}
                        r={3}
                        fill="rgba(255, 255, 255, 0.45)"
                        className="moving-point"
                    />
                ))}
            </svg>
        </div>
    );
};

export default Particles;
