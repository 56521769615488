import * as stepTrackerType from './stepTrackerType';

export const setStepData = (key, value) => ({
    type: stepTrackerType.STEP_TRACKER_SET_DATA,
    payload: { key, value },
  });
  
  export const incrementStep = (key) => ({
    type: stepTrackerType.STEP_TRACKER_INCREMENT,
    key,
  });
  
  export const decrementStep = (key) => ({
    type: stepTrackerType.STEP_TRACKER_DECREMENT,
    key,
  });